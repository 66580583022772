import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  useWindowWidth,
} from '@react-hook/window-size';
import {
  filter,
  pickBy,
  keys,
  includes,
  isObject,
  uniq,
  reduce,
  values,
  size,
  isNull,
} from 'lodash';
import {
  Checkbox, FormControlLabel, Accordion, AccordionDetails, AccordionSummary, styled,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useForm, Controller } from 'react-hook-form';
import CustomPropTypes from '../constants/customPropTypes';

import Button from './button';

import './untsFilter.scss';

// Determine the state of the checkbox (checked, unchecked, or indeterminate)
const getCheckValueState = (checkboxValues) => {
  // Find the unique values for each section (options: true/false)
  const sectionValues = uniq(values(checkboxValues));

  if (sectionValues.length === 0) {
    // On load, there are no values.
    // TO DO: We should pull from initial values instead
    return {
      checked: false,
      indeterminate: false,
    };
  } if (sectionValues.length === 2) {
    // If there are 2 values (true & false), return indeterminate
    return {
      checked: false,
      indeterminate: true,
    };
  }

  // If there is only one value, it's either true or false
  return {
    checked: sectionValues[0],
    indeterminate: false,
  };
};

const getMenuValues = (menuData, setTrue) => {
  const formData = reduce(menuData, (acc, attributes, key) => ({
    ...acc,
    [key]: reduce(attributes.options, (attributeAcc, _attribute, attributeKey) => ({
      ...attributeAcc,
      [attributeKey]: attributeKey === setTrue,
    }), {}),
  }), {});

  return formData;
};

const AccordionStyled = styled((props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Accordion elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none',
  },
}));

const UntsFilter = ({
  seasonData, onUpdateFilters, onCancel, currentTag, menuData,
}) => {
  const windowWidth = useWindowWidth();
  const {
    handleSubmit, control, watch, getValues, reset, setValue,
  } = useForm({
    defaultValues: getMenuValues(menuData),
  });

  const onSubmit = (formData) => {
    // Filter the results based on each rule
    const filteredUnts = reduce(formData, (remainingUnts, formValues, attributeType) => {
      const checkedValues = keys(pickBy(formValues, (value) => value === true));
      // If there are no checked values, return everything
      if (checkedValues.length === 0) {
        return remainingUnts;
      }

      const key = menuData[attributeType].attribute;

      const filteredSet = filter(remainingUnts, (unt) => {
        const attribute = unt[key];

        if (isObject(attribute)) {
          // Check to see if any of the attributes match any of the checked values.
          // Ex: ['Octopus', 'Cat'], ['Cat', 'dog'] = true
          // Rather than checking every attribute against every other attribute,
          // Just merge the arrays and see if the uniq length is the same as their
          // individual lengths.
          // ['Octopus', 'Cat'] + ['Cat', 'Dog'] = ['Octopus', 'Cat', 'Dog']
          // 2 + 2 > 3
          const combinedUniqueLength = uniq([...attribute, ...checkedValues]).length;
          return combinedUniqueLength < attribute.length + checkedValues.length;
        }

        // the the attribute is a string, see if it's one of the checked values.
        // Ex: ['Octopus', 'Cat'], 'Cat' = true
        if (includes(checkedValues, attribute)) {
          return true;
        }

        return false;
      });

      return filteredSet;
    }, seasonData);

    onUpdateFilters(filteredUnts);
  };

  const formValues = watch();

  const onSelectAll = (attribute, checkedStatus, currentValues) => {
    const newFormData = {
      ...currentValues,
      [attribute]: reduce(currentValues[attribute], (acc, _item, key) => ({
        ...acc,
        [key]: !checkedStatus.checked && !checkedStatus.indeterminate,
      }), {}),
    };

    reset(newFormData);
    // On mobile, don't update until they click apply
    if (windowWidth >= 768) {
      handleSubmit(onSubmit)();
    }
  };

  const resetForm = (setTrue) => {
    reset(getMenuValues(menuData, setTrue));
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    if (!isNull(currentTag)) {
      resetForm(currentTag);
    }
  }, [currentTag]);

  getMenuValues(menuData);

  // When they switch seasons, reset the form
  useEffect(() => {
    resetForm();
  }, [menuData]);

  return (
    <form className="unts-filter" onSubmit={handleSubmit(onSubmit)}>
      <div className="unts-filter__filters">
        {
          Object.keys(menuData).map((key) => {
            const menuItem = menuData[key];
            const checkedStatus = getCheckValueState(formValues[key]);
            return (
              <AccordionStyled
                key={menuItem.label}
                defaultExpanded={menuItem.expanded}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${menuItem.label} filters`}
                >
                  <div className="unts-filter__accordion-summary">
                    <h2>{menuItem.label}</h2>
                    {` (${size(menuItem.options)})`}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <fieldset className="unts-filter__fieldset">
                    <div>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={checkedStatus.checked}
                            indeterminate={checkedStatus.indeterminate}
                            onChange={() => onSelectAll(key, checkedStatus, getValues())}
                          />
                        )}
                        label={(
                          <div className="unts-filter__checkbox-label">
                            {!checkedStatus.checked && !checkedStatus.indeterminate ? 'Select all' : 'Deselect all'}
                          </div>
                          )}
                      />

                    </div>
                    {
                      Object.keys(menuItem.options).map((optionKey) => (
                        <Controller
                          key={optionKey}
                          name={`${key}.${optionKey}`}
                          control={control}
                          defaultValue={false}
                          label={(optionKey)}
                          render={({ field }) => (
                            <FormControlLabel
                              label={(
                                <div className="unts-filter__checkbox-label">
                                  <img src={`https://cheeky-unts.s3.ap-southeast-2.amazonaws.com/filters/${menuItem.options[optionKey].icon}`} alt="" />
                                  {optionKey}
                                </div>
                              )}
                              control={(
                                <Checkbox
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...field}
                                  checked={field.value}
                                  onChange={(e) => {
                                    setValue(`${key}.${optionKey}`, e.target.checked);

                                    // On mobile, don't update until they click apply
                                    if (windowWidth >= 768) {
                                      handleSubmit(onSubmit)();
                                    }
                                  }}
                                />
                              )}
                            />
                          )}
                        />
                      ))
                    }
                  </fieldset>
                </AccordionDetails>
              </AccordionStyled>
            );
          })
        }
      </div>

      <div className="unts-filter__footer">
        <div className="unts-filter__submit">
          <Button isSubmit label="Apply" />
        </div>
        <div className="unts-filter__reset">
          <Button label="Reset" onButtonClick={() => resetForm()} secondary />
        </div>
        <div className="unts-filter__cancel">
          <Button
            onButtonClick={onCancel}
            label="Cancel"
            secondary
          />
        </div>
      </div>
    </form>
  );
};

UntsFilter.defaultProps = {
  currentTag: null,
};

UntsFilter.propTypes = {
  onUpdateFilters: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  seasonData: CustomPropTypes.seasonData.isRequired,
  currentTag: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  menuData: PropTypes.object.isRequired,
};

export default UntsFilter;
