const seasonXmasData = [
  {
    id: 1,
    type: 'Penguin',
    traits: ['1', 'Sledding Hill', 'Beanie Winter Red', 'Winter Jacket Purple', 'Glass Milk', 'Present'],
  },
  {
    id: 2,
    type: 'Octopus',
    traits: ['2', 'Snowman Valley', 'Santas Hat', 'Gloves Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 3,
    type: 'Drop Bear',
    traits: ['3', 'Snowball Fight Arena', 'Elf Hat', 'Winter Jacket Red', 'Gloves Red'],
  },
  {
    id: 4,
    type: 'Crocodile',
    traits: ['4', 'Cozy Hut'],
  },
  {
    id: 5,
    type: 'Dingo',
    traits: ['5', 'Christmas Markets', 'Ear Warmers White', 'Sled', 'Snowball'],
  },
  {
    id: 6,
    type: 'Wombat',
    traits: ['6', 'Santas Sled', 'Antlers', 'Present', 'Santas Beard'],
  },
  {
    id: 7,
    type: 'Tasmanian Devil',
    traits: ['7', 'Santas Sack n Stable', 'Elf Outfit', 'Glass Milk'],
  },
  {
    id: 8,
    type: 'Shark',
    traits: ['8', 'Pile of Presents', 'Beanie Winter Blue', 'Ski Pole'],
  },
  {
    id: 9,
    type: 'Shark',
    traits: ['9', 'Toy Factory', 'Beanie Winter White', 'Winter Jacket Blue', 'Candy Cane'],
  },
  {
    id: 10,
    type: 'Tasmanian Tiger',
    traits: ['10', 'Cozy Fireplace', 'Winter Jacket Red', 'Present'],
  },
  {
    id: 11,
    type: 'Wombat',
    traits: ['11', 'Christmas Tree', 'Winter Jacket Blue', 'Gloves Red', 'Present'],
  },
  {
    id: 12,
    type: 'Shark',
    traits: ['12', 'Bondi Beach', 'Sunnies', 'Chocolate Cookie'],
  },
  {
    id: 13,
    type: 'Dingo',
    traits: ['1', 'Sledding Hill', 'Rosy Cheeks', 'Sled'],
  },
  {
    id: 14,
    type: 'Wombat',
    traits: ['2', 'Snowman Valley', 'Ear Warmers Pink', 'Ski Pole'],
  },
  {
    id: 15,
    type: 'Koala',
    traits: ['3', 'Snowball Fight Arena', 'Glass Milk', 'Cup Hot Chocolate'],
  },
  {
    id: 16,
    type: 'Koala',
    traits: ['4', 'Cozy Hut', 'Rosy Cheeks', 'Beanie Winter White', 'Scarf Blue', 'Snow Shovel', 'Present'],
  },
  {
    id: 17,
    type: 'Tasmanian Tiger',
    traits: ['5', 'Christmas Markets', 'Rosy Cheeks', 'Present'],
  },
  {
    id: 18,
    type: 'Tasmanian Tiger',
    traits: ['6', 'Santas Sled', 'Beanie Winter White', 'Winter Jacket Blue', 'Candy Cane'],
  },
  {
    id: 19,
    type: 'Tasmanian Tiger',
    traits: ['7', 'Santas Sack n Stable', 'Santas Hat', 'Skis'],
  },
  {
    id: 20,
    type: 'Penguin',
    traits: ['8', 'Pile of Presents', 'Winter Jacket Blue', 'Sled'],
  },
  {
    id: 21,
    type: 'Tasmanian Devil',
    traits: ['Pipe', '9', 'Toy Factory', 'Beanie Winter White', 'Scarf Blue', 'Candy Cane'],
  },
  {
    id: 22,
    type: 'Crocodile',
    traits: ['10', 'Cozy Fireplace', 'Ear Warmers Red', 'Glass Egg Nog'],
  },
  {
    id: 23,
    type: 'Tasmanian Devil',
    traits: ['11', 'Christmas Tree', 'Ear Warmers White', 'Scarf Red', 'Gloves Blue'],
  },
  {
    id: 24,
    type: 'Octopus',
    traits: ['12', 'Bondi Beach', 'Glass Milk', 'Chocolate Cookie'],
  },
  {
    id: 25,
    type: 'Kangaroo',
    traits: ['1', 'Sledding Hill', 'Rosy Cheeks', 'Antlers', 'Scarf Blue', 'Skis', 'Cup Hot Chocolate'],
  },
  {
    id: 26,
    type: 'Kangaroo',
    traits: ['Pipe', '2', 'Snowman Valley', 'Ear Warmers Red', 'Christmas Bell'],
  },
  {
    id: 27,
    type: 'Quokka',
    traits: ['3', 'Snowball Fight Arena', 'Rosy Cheeks', 'Beanie Winter Red', 'Elf Outfit', 'Gloves Red', 'Ski Pole'],
  },
  {
    id: 28,
    type: 'Crocodile',
    traits: ['4', 'Cozy Hut', 'Rosy Cheeks', 'Ear Warmers Pink', 'Scarf Red', 'Gloves Red', 'Cup Hot Chocolate'],
  },
  {
    id: 29,
    type: 'Platypus',
    traits: ['5', 'Christmas Markets'],
  },
  {
    id: 30,
    type: 'Wombat',
    traits: ['6', 'Santas Sled', 'Snow Shovel', 'Ski Pole'],
  },
  {
    id: 31,
    type: 'Tasmanian Tiger',
    traits: ['7', 'Santas Sack n Stable', 'Rosy Cheeks', 'Ear Warmers Red', 'Snow Shovel'],
  },
  {
    id: 32,
    type: 'Crocodile',
    traits: ['8', 'Pile of Presents', 'Ear Warmers Red', 'Santa Outfit', 'Snow Shovel', 'Christmas Bell'],
  },
  {
    id: 33,
    type: 'Tasmanian Devil',
    traits: ['9', 'Toy Factory', 'Beanie Winter Blue', 'Candy Cane'],
  },
  {
    id: 34,
    type: 'Kangaroo',
    traits: ['10', 'Cozy Fireplace', 'Rosy Cheeks', 'Scarf Blue', 'Glass Egg Nog', 'Present'],
  },
  {
    id: 35,
    type: 'Wombat',
    traits: ['Pipe', '11', 'Christmas Tree', 'Ear Warmers Pink', 'Gloves Red', 'Candy Cane'],
  },
  {
    id: 36,
    type: 'Octopus',
    traits: ['12', 'Bondi Beach', 'Glass Egg Nog', 'Chocolate Cookie'],
  },
  {
    id: 37,
    type: 'Crocodile',
    traits: ['1', 'Sledding Hill', 'Ski Goggles', 'Ear Warmers Red', 'Scarf Red', 'Glass Egg Nog', 'Candle'],
  },
  {
    id: 38,
    type: 'Octopus',
    traits: ['2', 'Snowman Valley', 'Ear Warmers Red', 'Snowball'],
  },
  {
    id: 39,
    type: 'Kangaroo',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter White', 'Scarf Red', 'Sled', 'Ski Pole'],
  },
  {
    id: 40,
    type: 'Tasmanian Devil',
    traits: ['4', 'Cozy Hut', 'Winter Jacket Blue', 'Gloves Red', 'Present'],
  },
  {
    id: 41,
    type: 'Tasmanian Tiger',
    traits: ['5', 'Christmas Markets', 'Rosy Cheeks', 'Beanie Winter Red', 'Elf Outfit', 'Glass Egg Nog', 'Snowball'],
  },
  {
    id: 42,
    type: 'Quokka',
    traits: ['6', 'Santas Sled', 'Scarf Blue'],
  },
  {
    id: 43,
    type: 'Platypus',
    traits: ['7', 'Santas Sack n Stable', 'Antlers', 'Scarf Red', 'Gloves Red', 'Present'],
  },
  {
    id: 44,
    type: 'Shark',
    traits: ['8', 'Pile of Presents', 'Beanie Winter Blue'],
  },
  {
    id: 45,
    type: 'Penguin',
    traits: ['9', 'Toy Factory', 'Rosy Cheeks', 'Winter Jacket Blue'],
  },
  {
    id: 46,
    type: 'Wombat',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter White', 'Gloves Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 47,
    type: 'Shark',
    traits: ['11', 'Christmas Tree', 'Antlers', 'Present'],
  },
  {
    id: 48,
    type: 'Penguin',
    traits: ['12', 'Bondi Beach', 'Sled', 'Chocolate Cookie'],
  },
  {
    id: 49,
    type: 'Quokka',
    traits: ['1', 'Sledding Hill', 'Antlers', 'Cup Hot Chocolate'],
  },
  {
    id: 50,
    type: 'Platypus',
    traits: ['2', 'Snowman Valley', 'Elf Outfit', 'Glass Egg Nog', 'Snowball'],
  },
  {
    id: 51,
    type: 'Platypus',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter Red', 'Winter Jacket Red', 'Snowball'],
  },
  {
    id: 52,
    type: 'Crocodile',
    traits: ['4', 'Cozy Hut', 'Elf Hat', 'Scarf Red', 'Glass Milk', 'Christmas Bell'],
  },
  {
    id: 53,
    type: 'Shark',
    traits: ['5', 'Christmas Markets', 'Antlers', 'Winter Jacket Purple'],
  },
  {
    id: 54,
    type: 'Kangaroo',
    traits: ['Pipe', '6', 'Santas Sled', 'Rosy Cheeks', 'Ski Goggles', 'Scarf Red'],
  },
  {
    id: 55,
    type: 'Kangaroo',
    traits: ['7', 'Santas Sack n Stable', 'Winter Jacket Red', 'Cup Hot Chocolate'],
  },
  {
    id: 56,
    type: 'Dingo',
    traits: ['8', 'Pile of Presents', 'Ear Warmers Red', 'Scarf Blue', 'Candy Cane'],
  },
  {
    id: 57,
    type: 'Tasmanian Devil',
    traits: ['Pipe', '9', 'Toy Factory', 'Glass Egg Nog', 'Present'],
  },
  {
    id: 58,
    type: 'Tasmanian Devil',
    traits: ['10', 'Cozy Fireplace', 'Winter Jacket Purple', 'Gloves Red'],
  },
  {
    id: 59,
    type: 'Tasmanian Tiger',
    traits: ['11', 'Christmas Tree', 'Beanie Winter White', 'Winter Jacket Red', 'Skis', 'Present'],
  },
  {
    id: 60,
    type: 'Crocodile',
    traits: ['12', 'Bondi Beach', 'Snow Shovel'],
  },
  {
    id: 61,
    type: 'Kangaroo',
    traits: ['1', 'Sledding Hill', 'Beanie Winter White', 'Scarf Red'],
  },
  {
    id: 62,
    type: 'Drop Bear',
    traits: ['2', 'Snowman Valley', 'Rosy Cheeks', 'Beanie Winter Red', 'Scarf Blue', 'Snow Shovel'],
  },
  {
    id: 63,
    type: 'Shark',
    traits: ['3', 'Snowball Fight Arena', 'Winter Jacket Red', 'Candy Cane'],
  },
  {
    id: 64,
    type: 'Quokka',
    traits: ['4', 'Cozy Hut', 'Beanie Winter White', 'Scarf Blue', 'Snow Shovel', 'Chocolate Cookie'],
  },
  {
    id: 65,
    type: 'Dingo',
    traits: ['5', 'Christmas Markets', 'Rosy Cheeks', 'Winter Jacket Blue', 'Gloves Blue'],
  },
  {
    id: 66,
    type: 'Seal',
    traits: ['6', 'Santas Sled', 'Ski Goggles', 'Scarf Blue', 'Snowball'],
  },
  {
    id: 67,
    type: 'Seal',
    traits: ['7', 'Santas Sack n Stable', 'Antlers', 'Scarf Blue', 'Glass Egg Nog', 'Present'],
  },
  {
    id: 68,
    type: 'Platypus',
    traits: ['8', 'Pile of Presents', 'Beanie Winter Blue', 'Glass Milk', 'Cup Hot Chocolate'],
  },
  {
    id: 69,
    type: 'Penguin',
    traits: ['9', 'Toy Factory', 'Ski Goggles', 'Elf Outfit', 'Candy Cane'],
  },
  {
    id: 70,
    type: 'Quokka',
    traits: ['10', 'Cozy Fireplace', 'Elf Hat', 'Scarf Blue', 'Candy Cane'],
  },
  {
    id: 71,
    type: 'Octopus',
    traits: ['Pipe', '11', 'Christmas Tree', 'Beanie Winter Red'],
  },
  {
    id: 72,
    type: 'Tasmanian Devil',
    traits: ['12', 'Bondi Beach', 'Rosy Cheeks', 'Glass Egg Nog'],
  },
  {
    id: 73,
    type: 'Tasmanian Devil',
    traits: ['1', 'Sledding Hill', 'Rosy Cheeks', 'Beanie Winter Blue', 'Gloves Red', 'Chocolate Cookie'],
  },
  {
    id: 74,
    type: 'Crocodile',
    traits: ['2', 'Snowman Valley', 'Santa Outfit', 'Gloves Red', 'Ski Pole'],
  },
  {
    id: 75,
    type: 'Wombat',
    traits: ['3', 'Snowball Fight Arena', 'Elf Outfit', 'Gloves Red', 'Christmas Bell'],
  },
  {
    id: 76,
    type: 'Kangaroo',
    traits: ['4', 'Cozy Hut', 'Gloves Blue', 'Christmas Bell'],
  },
  {
    id: 77,
    type: 'Shark',
    traits: ['5', 'Christmas Markets', 'Winter Jacket Blue', 'Sled'],
  },
  {
    id: 78,
    type: 'Platypus',
    traits: ['6', 'Santas Sled', 'Winter Jacket Blue', 'Glass Egg Nog'],
  },
  {
    id: 79,
    type: 'Dingo',
    traits: ['7', 'Santas Sack n Stable', 'Ear Warmers Red', 'Glass Milk', 'Cup Hot Chocolate'],
  },
  {
    id: 80,
    type: 'Penguin',
    traits: ['8', 'Pile of Presents', 'Winter Jacket Red', 'Gloves Blue'],
  },
  {
    id: 81,
    type: 'Crocodile',
    traits: ['9', 'Toy Factory', 'Gloves Red', 'Cup Hot Chocolate'],
  },
  {
    id: 82,
    type: 'Platypus',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter Red', 'Elf Outfit', 'Glass Milk', 'Christmas Bell'],
  },
  {
    id: 83,
    type: 'Koala',
    traits: ['Pipe', '11', 'Christmas Tree', 'Beanie Winter Red', 'Winter Jacket Blue', 'Snow Shovel', 'Candy Cane'],
  },
  {
    id: 84,
    type: 'Kangaroo',
    traits: ['12', 'Bondi Beach', 'Skis'],
  },
  {
    id: 85,
    type: 'Octopus',
    traits: ['1', 'Sledding Hill', 'Rosy Cheeks', 'Ear Warmers White', 'Scarf Red', 'Gloves Blue', 'Candy Cane'],
  },
  {
    id: 86,
    type: 'Dingo',
    traits: ['2', 'Snowman Valley', 'Beanie Winter Blue', 'Elf Outfit', 'Sled'],
  },
  {
    id: 87,
    type: 'Kangaroo',
    traits: ['3', 'Snowball Fight Arena', 'Elf Hat', 'Cup Hot Chocolate', 'Santas Beard'],
  },
  {
    id: 88,
    type: 'Platypus',
    traits: ['4', 'Cozy Hut', 'Gloves Red', 'Candle'],
  },
  {
    id: 89,
    type: 'Koala',
    traits: ['5', 'Christmas Markets', 'Sled', 'Christmas Bell'],
  },
  {
    id: 90,
    type: 'Seal',
    traits: ['6', 'Santas Sled', 'Beanie Winter Red', 'Cup Hot Chocolate'],
  },
  {
    id: 91,
    type: 'Shark',
    traits: ['7', 'Santas Sack n Stable', 'Antlers', 'Skis', 'Cup Hot Chocolate'],
  },
  {
    id: 92,
    type: 'Octopus',
    traits: ['8', 'Pile of Presents', 'Ski Goggles', 'Antlers', 'Glass Egg Nog'],
  },
  {
    id: 93,
    type: 'Tasmanian Tiger',
    traits: ['9', 'Toy Factory', 'Rosy Cheeks', 'Ear Warmers White', 'Scarf Red', 'Chocolate Cookie'],
  },
  {
    id: 94,
    type: 'Bunyip',
    traits: ['10', 'Cozy Fireplace', 'Santas Hat', 'Elf Outfit', 'Sled'],
  },
  {
    id: 95,
    type: 'Penguin',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Red', 'Sled', 'Chocolate Cookie'],
  },
  {
    id: 96,
    type: 'Platypus',
    traits: ['12', 'Bondi Beach', 'Christmas Bell'],
  },
  {
    id: 97,
    type: 'Shark',
    traits: ['1', 'Sledding Hill', 'Winter Jacket Red', 'Glass Milk', 'Present'],
  },
  {
    id: 98,
    type: 'Crocodile',
    traits: ['2', 'Snowman Valley', 'Winter Jacket Purple', 'Christmas Bell'],
  },
  {
    id: 99,
    type: 'Tasmanian Tiger',
    traits: ['3', 'Snowball Fight Arena', 'Antlers', 'Scarf Blue'],
  },
  {
    id: 100,
    type: 'Platypus',
    traits: ['4', 'Cozy Hut', 'Antlers'],
  },
  {
    id: 101,
    type: 'Octopus',
    traits: ['5', 'Christmas Markets', 'Ear Warmers White', 'Snow Shovel', 'Chocolate Cookie'],
  },
  {
    id: 102,
    type: 'Tasmanian Tiger',
    traits: ['6', 'Santas Sled', 'Beanie Winter Red', 'Winter Jacket Purple', 'Glass Egg Nog', 'Snowball'],
  },
  {
    id: 103,
    type: 'Quokka',
    traits: ['7', 'Santas Sack n Stable', 'Ski Goggles', 'Winter Jacket Purple', 'Cup Hot Chocolate'],
  },
  {
    id: 104,
    type: 'Crocodile',
    traits: ['8', 'Pile of Presents', 'Winter Jacket Red', 'Snow Shovel'],
  },
  {
    id: 105,
    type: 'Koala',
    traits: ['Xmas Laser Eyes', '9', 'Toy Factory', 'Santas Hat', 'Elf Outfit', 'Glass Milk', 'Cup Hot Chocolate'],
  },
  {
    id: 106,
    type: 'Kangaroo',
    traits: ['10', 'Cozy Fireplace', 'Rosy Cheeks', 'Elf Hat', 'Winter Jacket Purple', 'Glass Egg Nog', 'Candy Cane'],
  },
  {
    id: 107,
    type: 'Dingo',
    traits: ['11', 'Christmas Tree', 'Ear Warmers Pink', 'Scarf Blue', 'Sled'],
  },
  {
    id: 108,
    type: 'Penguin',
    traits: ['12', 'Bondi Beach', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 109,
    type: 'Quokka',
    traits: ['1', 'Sledding Hill', 'Ski Goggles', 'Gloves Red', 'Chocolate Cookie'],
  },
  {
    id: 110,
    type: 'Tasmanian Devil',
    traits: ['2', 'Snowman Valley', 'Scarf Red', 'Glass Milk'],
  },
  {
    id: 111,
    type: 'Shark',
    traits: ['3', 'Snowball Fight Arena', 'Ski Goggles', 'Ear Warmers White', 'Gloves Red', 'Present'],
  },
  {
    id: 112,
    type: 'Penguin',
    traits: ['4', 'Cozy Hut', 'Scarf Red', 'Skis'],
  },
  {
    id: 113,
    type: 'Kangaroo',
    traits: ['5', 'Christmas Markets', 'Beanie Winter Red'],
  },
  {
    id: 114,
    type: 'Tasmanian Devil',
    traits: ['6', 'Santas Sled', 'Elf Hat', 'Santa Outfit', 'Sled', 'Christmas Bell'],
  },
  {
    id: 115,
    type: 'Kangaroo',
    traits: ['7', 'Santas Sack n Stable', 'Winter Jacket Blue', 'Glass Milk', 'Christmas Bell'],
  },
  {
    id: 116,
    type: 'Penguin',
    traits: ['8', 'Pile of Presents', 'Antlers', 'Glass Milk'],
  },
  {
    id: 117,
    type: 'Wombat',
    traits: ['9', 'Toy Factory', 'Gloves Blue', 'Chocolate Cookie'],
  },
  {
    id: 118,
    type: 'Shark',
    traits: ['10', 'Cozy Fireplace', 'Elf Hat', 'Winter Jacket Red', 'Gloves Red'],
  },
  {
    id: 119,
    type: 'Tasmanian Tiger',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Blue'],
  },
  {
    id: 120,
    type: 'Drop Bear',
    traits: ['Rudolf Nose', '12', 'Bondi Beach', 'Snow Shovel', 'Present'],
  },
  {
    id: 121,
    type: 'Platypus',
    traits: ['Pipe', '1', 'Sledding Hill', 'Elf Hat', 'Scarf Blue', 'Glass Milk'],
  },
  {
    id: 122,
    type: 'Crocodile',
    traits: ['2', 'Snowman Valley', 'Scarf Blue', 'Gloves Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 123,
    type: 'Seal',
    traits: ['3', 'Snowball Fight Arena', 'Rosy Cheeks', 'Beanie Winter White', 'Glass Egg Nog', 'Cup Hot Chocolate'],
  },
  {
    id: 124,
    type: 'Crocodile',
    traits: ['4', 'Cozy Hut', 'Ear Warmers White', 'Scarf Red', 'Gloves Red'],
  },
  {
    id: 125,
    type: 'Shark',
    traits: ['5', 'Christmas Markets', 'Rosy Cheeks', 'Ski Goggles', 'Ear Warmers Pink'],
  },
  {
    id: 126,
    type: 'Crocodile',
    traits: ['6', 'Santas Sled', 'Ski Goggles', 'Ear Warmers Pink', 'Elf Outfit', 'Ski Pole'],
  },
  {
    id: 127,
    type: 'Wombat',
    traits: ['7', 'Santas Sack n Stable', 'Winter Jacket Purple', 'Skis'],
  },
  {
    id: 128,
    type: 'Seal',
    traits: ['8', 'Pile of Presents', 'Beanie Winter Blue', 'Scarf Blue', 'Gloves Blue'],
  },
  {
    id: 129,
    type: 'Shark',
    traits: ['9', 'Toy Factory', 'Antlers', 'Scarf Blue', 'Glass Egg Nog', 'Snowball'],
  },
  {
    id: 130,
    type: 'Kangaroo',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter Blue', 'Gloves Red'],
  },
  {
    id: 131,
    type: 'Kangaroo',
    traits: ['11', 'Christmas Tree', 'Antlers', 'Gloves Blue', 'Present'],
  },
  {
    id: 132,
    type: 'Tasmanian Tiger',
    traits: ['12', 'Bondi Beach', 'Skis', 'Chocolate Cookie'],
  },
  {
    id: 133,
    type: 'Wombat',
    traits: ['1', 'Sledding Hill', 'Santas Hat', 'Scarf Blue', 'Glass Egg Nog', 'Chocolate Cookie'],
  },
  {
    id: 134,
    type: 'Shark',
    traits: ['2', 'Snowman Valley', 'Beanie Winter Red', 'Scarf Blue'],
  },
  {
    id: 135,
    type: 'Penguin',
    traits: ['3', 'Snowball Fight Arena', 'Rosy Cheeks', 'Bradbury Helmet', 'Winter Jacket Purple', 'Gloves Blue', 'Candy Cane'],
  },
  {
    id: 136,
    type: 'Quokka',
    traits: ['4', 'Cozy Hut', 'Ear Warmers Red'],
  },
  {
    id: 137,
    type: 'Crocodile',
    traits: ['5', 'Christmas Markets', 'Ear Warmers Red', 'Glass Milk', 'Cup Hot Chocolate'],
  },
  {
    id: 138,
    type: 'Wombat',
    traits: ['Pipe', '6', 'Santas Sled', 'Gloves Blue'],
  },
  {
    id: 139,
    type: 'Kangaroo',
    traits: ['Xmas Laser Eyes', '7', 'Santas Sack n Stable', 'Ear Warmers Pink', 'Scarf Red', 'Skis'],
  },
  {
    id: 140,
    type: 'Quokka',
    traits: ['8', 'Pile of Presents', 'Elf Hat', 'Cup Hot Chocolate', 'Santas Beard'],
  },
  {
    id: 141,
    type: 'Wombat',
    traits: ['9', 'Toy Factory', 'Rosy Cheeks', 'Ear Warmers Red', 'Elf Outfit', 'Sled', 'Christmas Bell'],
  },
  {
    id: 142,
    type: 'Tasmanian Devil',
    traits: ['10', 'Cozy Fireplace'],
  },
  {
    id: 143,
    type: 'Tasmanian Tiger',
    traits: ['11', 'Christmas Tree', 'Ski Goggles', 'Santa Outfit', 'Gloves Blue', 'Snowball'],
  },
  {
    id: 144,
    type: 'Octopus',
    traits: ['12', 'Bondi Beach', 'Sunnies', 'Sled'],
  },
  {
    id: 145,
    type: 'Seal',
    traits: ['1', 'Sledding Hill', 'Rosy Cheeks', 'Ear Warmers Red', 'Scarf Red', 'Present'],
  },
  {
    id: 146,
    type: 'Wombat',
    traits: ['2', 'Snowman Valley', 'Elf Outfit', 'Snowball'],
  },
  {
    id: 147,
    type: 'Shark',
    traits: ['3', 'Snowball Fight Arena', 'Winter Jacket Red'],
  },
  {
    id: 148,
    type: 'Octopus',
    traits: ['4', 'Cozy Hut', 'Snow Shovel', 'Present'],
  },
  {
    id: 149,
    type: 'Octopus',
    traits: ['5', 'Christmas Markets', 'Rosy Cheeks', 'Beanie Winter White', 'Glass Milk', 'Cup Hot Chocolate'],
  },
  {
    id: 150,
    type: 'Wombat',
    traits: ['6', 'Santas Sled', 'Rosy Cheeks', 'Beanie Winter Blue', 'Scarf Blue'],
  },
  {
    id: 151,
    type: 'Tasmanian Tiger',
    traits: ['7', 'Santas Sack n Stable', 'Glass Egg Nog', 'Chocolate Cookie'],
  },
  {
    id: 152,
    type: 'Penguin',
    traits: ['8', 'Pile of Presents', 'Beanie Winter Red', 'Glass Egg Nog', 'Candy Cane'],
  },
  {
    id: 153,
    type: 'Tasmanian Devil',
    traits: ['9', 'Toy Factory', 'Beanie Winter Red', 'Snow Shovel', 'Ski Pole'],
  },
  {
    id: 154,
    type: 'Tasmanian Tiger',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter Blue', 'Gloves Red', 'Candle'],
  },
  {
    id: 155,
    type: 'Platypus',
    traits: ['11', 'Christmas Tree', 'Ski Goggles', 'Chocolate Cookie'],
  },
  {
    id: 156,
    type: 'Tasmanian Tiger',
    traits: ['12', 'Bondi Beach', 'Cup Hot Chocolate', 'Santas Beard'],
  },
  {
    id: 157,
    type: 'Seal',
    traits: ['1', 'Sledding Hill', 'Rosy Cheeks', 'Santas Hat', 'Glass Milk'],
  },
  {
    id: 158,
    type: 'Crocodile',
    traits: ['2', 'Snowman Valley', 'Rosy Cheeks', 'Winter Jacket Red', 'Gloves Red', 'Snowball'],
  },
  {
    id: 159,
    type: 'Tasmanian Tiger',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter Red', 'Winter Jacket Blue', 'Snow Shovel', 'Present'],
  },
  {
    id: 160,
    type: 'Koala',
    traits: ['4', 'Cozy Hut', 'Rosy Cheeks', 'Ear Warmers Pink', 'Snowball'],
  },
  {
    id: 161,
    type: 'Tasmanian Devil',
    traits: ['5', 'Christmas Markets', 'Beanie Winter Blue', 'Winter Jacket Purple'],
  },
  {
    id: 162,
    type: 'Seal',
    traits: ['6', 'Santas Sled', 'Winter Jacket Blue', 'Gloves Blue'],
  },
  {
    id: 163,
    type: 'Quokka',
    traits: ['7', 'Santas Sack n Stable', 'Christmas Bell'],
  },
  {
    id: 164,
    type: 'Shark',
    traits: ['8', 'Pile of Presents', 'Gloves Blue', 'Candle'],
  },
  {
    id: 165,
    type: 'Koala',
    traits: ['9', 'Toy Factory', 'Beanie Winter Red', 'Elf Outfit', 'Gloves Red', 'Cup Hot Chocolate'],
  },
  {
    id: 166,
    type: 'Seal',
    traits: ['10', 'Cozy Fireplace', 'Ski Goggles', 'Antlers', 'Winter Jacket Blue', 'Gloves Red'],
  },
  {
    id: 167,
    type: 'Koala',
    traits: ['Pipe', '11', 'Christmas Tree', 'Ear Warmers White', 'Glass Milk', 'Christmas Bell'],
  },
  {
    id: 168,
    type: 'Quokka',
    traits: ['12', 'Bondi Beach', 'Glass Egg Nog', 'Cup Hot Chocolate'],
  },
  {
    id: 169,
    type: 'Octopus',
    traits: ['1', 'Sledding Hill', 'Rosy Cheeks', 'Ear Warmers Red', 'Sled', 'Candy Cane'],
  },
  {
    id: 170,
    type: 'Kangaroo',
    traits: ['2', 'Snowman Valley', 'Winter Jacket Blue', 'Sled', 'Cup Hot Chocolate'],
  },
  {
    id: 171,
    type: 'Dingo',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter White', 'Winter Jacket Red', 'Cup Hot Chocolate', 'Santas Beard'],
  },
  {
    id: 172,
    type: 'Kangaroo',
    traits: ['4', 'Cozy Hut', 'Glass Milk', 'Chocolate Cookie'],
  },
  {
    id: 173,
    type: 'Quokka',
    traits: ['Pipe', '5', 'Christmas Markets', 'Beanie Winter Red', 'Gloves Blue', 'Candy Cane'],
  },
  {
    id: 174,
    type: 'Crocodile',
    traits: ['6', 'Santas Sled', 'Elf Outfit', 'Snowball'],
  },
  {
    id: 175,
    type: 'Quokka',
    traits: ['Xmas Laser Eyes', '7', 'Santas Sack n Stable', 'Beanie Winter White', 'Elf Outfit', 'Cup Hot Chocolate'],
  },
  {
    id: 176,
    type: 'Quokka',
    traits: ['8', 'Pile of Presents', 'Rosy Cheeks', 'Snow Shovel', 'Ski Pole'],
  },
  {
    id: 177,
    type: 'Koala',
    traits: ['9', 'Toy Factory', 'Rosy Cheeks', 'Scarf Red', 'Glass Milk'],
  },
  {
    id: 178,
    type: 'Platypus',
    traits: ['10', 'Cozy Fireplace', 'Winter Jacket Blue', 'Gloves Blue', 'Candle'],
  },
  {
    id: 179,
    type: 'Tasmanian Devil',
    traits: ['Pipe', '11', 'Christmas Tree', 'Beanie Winter White', 'Scarf Red'],
  },
  {
    id: 180,
    type: 'Shark',
    traits: ['12', 'Bondi Beach', 'Sunnies', 'Sled', 'Chocolate Cookie'],
  },
  {
    id: 181,
    type: 'Wombat',
    traits: ['1', 'Sledding Hill', 'Ski Goggles', 'Ear Warmers White', 'Santa Outfit', 'Gloves Blue'],
  },
  {
    id: 182,
    type: 'Shark',
    traits: ['2', 'Snowman Valley', 'Scarf Blue', 'Gloves Blue', 'Ski Pole'],
  },
  {
    id: 183,
    type: 'Crocodile',
    traits: ['Rudolf Nose', '3', 'Snowball Fight Arena', 'Snowball'],
  },
  {
    id: 184,
    type: 'Quokka',
    traits: ['4', 'Cozy Hut', 'Scarf Blue', 'Glass Egg Nog', 'Cup Hot Chocolate'],
  },
  {
    id: 185,
    type: 'Quokka',
    traits: ['5', 'Christmas Markets', 'Ski Goggles', 'Ear Warmers Red', 'Santa Outfit', 'Snow Shovel'],
  },
  {
    id: 186,
    type: 'Wombat',
    traits: ['6', 'Santas Sled', 'Elf Hat', 'Gloves Blue'],
  },
  {
    id: 187,
    type: 'Platypus',
    traits: ['7', 'Santas Sack n Stable', 'Scarf Red', 'Gloves Red', 'Cup Hot Chocolate'],
  },
  {
    id: 188,
    type: 'Platypus',
    traits: ['8', 'Pile of Presents', 'Beanie Winter White', 'Elf Outfit'],
  },
  {
    id: 189,
    type: 'Wombat',
    traits: ['9', 'Toy Factory', 'Winter Jacket Purple', 'Skis', 'Cup Hot Chocolate'],
  },
  {
    id: 190,
    type: 'Dingo',
    traits: ['Xmas Laser Eyes', '10', 'Cozy Fireplace', 'Scarf Blue', 'Chocolate Cookie'],
  },
  {
    id: 191,
    type: 'Crocodile',
    traits: ['11', 'Christmas Tree', 'Ear Warmers Red', 'Present'],
  },
  {
    id: 192,
    type: 'Wombat',
    traits: ['12', 'Bondi Beach'],
  },
  {
    id: 193,
    type: 'Kangaroo',
    traits: ['1', 'Sledding Hill', 'Ski Goggles', 'Winter Jacket Blue', 'Glass Egg Nog'],
  },
  {
    id: 194,
    type: 'Shark',
    traits: ['2', 'Snowman Valley', 'Beanie Winter Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 195,
    type: 'Kangaroo',
    traits: ['3', 'Snowball Fight Arena', 'Ear Warmers Red', 'Snow Shovel', 'Snowball'],
  },
  {
    id: 196,
    type: 'Kangaroo',
    traits: ['4', 'Cozy Hut', 'Winter Jacket Red', 'Gloves Blue', 'Ski Pole'],
  },
  {
    id: 197,
    type: 'Koala',
    traits: ['5', 'Christmas Markets', 'Scarf Red', 'Glass Milk', 'Present'],
  },
  {
    id: 198,
    type: 'Koala',
    traits: ['6', 'Santas Sled', 'Beanie Winter Blue', 'Winter Jacket Blue', 'Gloves Blue', 'Candy Cane'],
  },
  {
    id: 199,
    type: 'Quokka',
    traits: ['7', 'Santas Sack n Stable', 'Winter Jacket Purple'],
  },
  {
    id: 200,
    type: 'Seal',
    traits: ['8', 'Pile of Presents', 'Beanie Winter Blue', 'Winter Jacket Purple', 'Snowball'],
  },
  {
    id: 201,
    type: 'Tasmanian Devil',
    traits: ['Pipe', '9', 'Toy Factory', 'Ear Warmers Red', 'Elf Outfit', 'Gloves Blue', 'Christmas Bell'],
  },
  {
    id: 202,
    type: 'Kangaroo',
    traits: ['10', 'Cozy Fireplace', 'Winter Jacket Purple', 'Gloves Red', 'Chocolate Cookie'],
  },
  {
    id: 203,
    type: 'Drop Bear',
    traits: ['11', 'Christmas Tree', 'Beanie Winter White', 'Winter Jacket Red', 'Present'],
  },
  {
    id: 204,
    type: 'Kangaroo',
    traits: ['12', 'Bondi Beach', 'Rosy Cheeks', 'Sunnies', 'Glass Milk'],
  },
  {
    id: 205,
    type: 'Tasmanian Tiger',
    traits: ['1', 'Sledding Hill', 'Beanie Winter White', 'Elf Outfit', 'Cup Hot Chocolate'],
  },
  {
    id: 206,
    type: 'Seal',
    traits: ['Rudolf Nose', '2', 'Snowman Valley', 'Gloves Blue', 'Snowball'],
  },
  {
    id: 207,
    type: 'Crocodile',
    traits: ['3', 'Snowball Fight Arena', 'Rosy Cheeks', 'Beanie Winter Red', 'Elf Outfit', 'Gloves Red', 'Present'],
  },
  {
    id: 208,
    type: 'Tasmanian Devil',
    traits: ['4', 'Cozy Hut', 'Winter Jacket Purple', 'Gloves Red', 'Chocolate Cookie'],
  },
  {
    id: 209,
    type: 'Bunyip',
    traits: ['5', 'Christmas Markets', 'Beanie Winter Blue', 'Gloves Red'],
  },
  {
    id: 210,
    type: 'Octopus',
    traits: ['6', 'Santas Sled', 'Ear Warmers Pink'],
  },
  {
    id: 211,
    type: 'Wombat',
    traits: ['7', 'Santas Sack n Stable', 'Scarf Red', 'Christmas Bell'],
  },
  {
    id: 212,
    type: 'Wombat',
    traits: ['8', 'Pile of Presents', 'Beanie Winter Red'],
  },
  {
    id: 213,
    type: 'Seal',
    traits: ['9', 'Toy Factory', 'Beanie Winter White', 'Winter Jacket Blue', 'Skis', 'Chocolate Cookie'],
  },
  {
    id: 214,
    type: 'Penguin',
    traits: ['10', 'Cozy Fireplace', 'Antlers', 'Scarf Red'],
  },
  {
    id: 215,
    type: 'Wombat',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Blue', 'Winter Jacket Blue', 'Glass Egg Nog'],
  },
  {
    id: 216,
    type: 'Kangaroo',
    traits: ['12', 'Bondi Beach', 'Candle', 'Santas Beard'],
  },
  {
    id: 217,
    type: 'Koala',
    traits: ['1', 'Sledding Hill', 'Rosy Cheeks', 'Elf Hat', 'Scarf Red'],
  },
  {
    id: 218,
    type: 'Wombat',
    traits: ['2', 'Snowman Valley', 'Ski Goggles', 'Scarf Red', 'Gloves Red', 'Candle'],
  },
  {
    id: 219,
    type: 'Kangaroo',
    traits: ['3', 'Snowball Fight Arena', 'Rosy Cheeks', 'Ski Goggles', 'Winter Jacket Purple', 'Chocolate Cookie'],
  },
  {
    id: 220,
    type: 'Koala',
    traits: ['4', 'Cozy Hut', 'Rosy Cheeks', 'Beanie Winter Red', 'Winter Jacket Red', 'Gloves Red'],
  },
  {
    id: 221,
    type: 'Shark',
    traits: ['5', 'Christmas Markets', 'Beanie Winter White', 'Snow Shovel', 'Snowball'],
  },
  {
    id: 222,
    type: 'Quokka',
    traits: ['6', 'Santas Sled', 'Santas Hat', 'Winter Jacket Purple', 'Sled', 'Ski Pole'],
  },
  {
    id: 223,
    type: 'Crocodile',
    traits: ['7', 'Santas Sack n Stable', 'Antlers', 'Winter Jacket Blue', 'Candy Cane'],
  },
  {
    id: 224,
    type: 'Wombat',
    traits: ['8', 'Pile of Presents', 'Rosy Cheeks', 'Beanie Winter White', 'Winter Jacket Blue', 'Sled'],
  },
  {
    id: 225,
    type: 'Tasmanian Devil',
    traits: ['9', 'Toy Factory', 'Rosy Cheeks', 'Winter Jacket Purple', 'Glass Milk'],
  },
  {
    id: 226,
    type: 'Shark',
    traits: ['10', 'Cozy Fireplace', 'Scarf Blue', 'Glass Egg Nog'],
  },
  {
    id: 227,
    type: 'Platypus',
    traits: ['11', 'Christmas Tree', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 228,
    type: 'Quokka',
    traits: ['12', 'Bondi Beach', 'Rosy Cheeks', 'Sunnies', 'Snow Shovel', 'Candy Cane'],
  },
  {
    id: 229,
    type: 'Penguin',
    traits: ['1', 'Sledding Hill', 'Beanie Winter Blue', 'Scarf Red'],
  },
  {
    id: 230,
    type: 'Seal',
    traits: ['2', 'Snowman Valley', 'Beanie Winter White', 'Gloves Red', 'Christmas Bell'],
  },
  {
    id: 231,
    type: 'Tasmanian Devil',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter White', 'Elf Outfit', 'Gloves Blue'],
  },
  {
    id: 232,
    type: 'Dingo',
    traits: ['4', 'Cozy Hut', 'Beanie Winter Red', 'Winter Jacket Purple', 'Sled', 'Cup Hot Chocolate'],
  },
  {
    id: 233,
    type: 'Penguin',
    traits: ['5', 'Christmas Markets', 'Candy Cane'],
  },
  {
    id: 234,
    type: 'Dingo',
    traits: ['6', 'Santas Sled', 'Elf Hat', 'Elf Outfit', 'Skis', 'Present'],
  },
  {
    id: 235,
    type: 'Penguin',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter Red', 'Winter Jacket Blue', 'Candy Cane'],
  },
  {
    id: 236,
    type: 'Koala',
    traits: ['8', 'Pile of Presents', 'Snow Shovel', 'Snowball'],
  },
  {
    id: 237,
    type: 'Tasmanian Tiger',
    traits: ['9', 'Toy Factory', 'Winter Jacket Red', 'Cup Hot Chocolate'],
  },
  {
    id: 238,
    type: 'Shark',
    traits: ['10', 'Cozy Fireplace', 'Glass Egg Nog', 'Christmas Bell'],
  },
  {
    id: 239,
    type: 'Bunyip',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Blue', 'Winter Jacket Purple'],
  },
  {
    id: 240,
    type: 'Crocodile',
    traits: ['Pipe', '12', 'Bondi Beach', 'Sunnies', 'Glass Egg Nog'],
  },
  {
    id: 241,
    type: 'Shark',
    traits: ['1', 'Sledding Hill', 'Winter Jacket Purple', 'Chocolate Cookie'],
  },
  {
    id: 242,
    type: 'Koala',
    traits: ['2', 'Snowman Valley', 'Santas Hat', 'Christmas Bell', 'Santas Beard'],
  },
  {
    id: 243,
    type: 'Koala',
    traits: ['3', 'Snowball Fight Arena', 'Rosy Cheeks', 'Elf Hat', 'Scarf Red'],
  },
  {
    id: 244,
    type: 'Tasmanian Devil',
    traits: ['4', 'Cozy Hut', 'Ski Goggles', 'Glass Milk', 'Present'],
  },
  {
    id: 245,
    type: 'Wombat',
    traits: ['5', 'Christmas Markets', 'Beanie Winter White', 'Winter Jacket Purple', 'Gloves Blue', 'Christmas Bell'],
  },
  {
    id: 246,
    type: 'Quokka',
    traits: ['6', 'Santas Sled', 'Gloves Blue', 'Snowball'],
  },
  {
    id: 247,
    type: 'Quokka',
    traits: ['7', 'Santas Sack n Stable', 'Winter Jacket Red', 'Glass Egg Nog', 'Present'],
  },
  {
    id: 248,
    type: 'Platypus',
    traits: ['8', 'Pile of Presents', 'Beanie Winter Red', 'Scarf Red', 'Gloves Red', 'Cup Hot Chocolate'],
  },
  {
    id: 249,
    type: 'Wombat',
    traits: ['9', 'Toy Factory', 'Ski Goggles', 'Winter Jacket Purple'],
  },
  {
    id: 250,
    type: 'Tasmanian Devil',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter Blue'],
  },
  {
    id: 251,
    type: 'Penguin',
    traits: ['11', 'Christmas Tree', 'Winter Jacket Purple', 'Glass Milk', 'Snowball'],
  },
  {
    id: 252,
    type: 'Quokka',
    traits: ['12', 'Bondi Beach', 'Cup Hot Chocolate'],
  },
  {
    id: 253,
    type: 'Tasmanian Devil',
    traits: ['1', 'Sledding Hill', 'Beanie Winter Red', 'Winter Jacket Red', 'Gloves Blue'],
  },
  {
    id: 254,
    type: 'Seal',
    traits: ['2', 'Snowman Valley', 'Ear Warmers White', 'Skis', 'Candle'],
  },
  {
    id: 255,
    type: 'Octopus',
    traits: ['3', 'Snowball Fight Arena', 'Ski Goggles', 'Sled', 'Ski Pole'],
  },
  {
    id: 256,
    type: 'Wombat',
    traits: ['4', 'Cozy Hut', 'Beanie Winter White', 'Scarf Red', 'Present'],
  },
  {
    id: 257,
    type: 'Tasmanian Devil',
    traits: ['5', 'Christmas Markets', 'Scarf Red', 'Gloves Red', 'Candle'],
  },
  {
    id: 258,
    type: 'Tasmanian Devil',
    traits: ['6', 'Santas Sled', 'Beanie Winter Blue'],
  },
  {
    id: 259,
    type: 'Seal',
    traits: ['7', 'Santas Sack n Stable', 'Antlers', 'Winter Jacket Purple', 'Skis', 'Christmas Bell'],
  },
  {
    id: 260,
    type: 'Crocodile',
    traits: ['8', 'Pile of Presents', 'Beanie Winter Red', 'Snow Shovel'],
  },
  {
    id: 261,
    type: 'Penguin',
    traits: ['9', 'Toy Factory', 'Beanie Winter White', 'Winter Jacket Blue', 'Sled'],
  },
  {
    id: 262,
    type: 'Wombat',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter White', 'Snow Shovel'],
  },
  {
    id: 263,
    type: 'Octopus',
    traits: ['11', 'Christmas Tree', 'Santas Hat', 'Scarf Red', 'Gloves Red'],
  },
  {
    id: 264,
    type: 'Crocodile',
    traits: ['12', 'Bondi Beach', 'Sunnies', 'Skis', 'Present'],
  },
  {
    id: 265,
    type: 'Platypus',
    traits: ['1', 'Sledding Hill', 'Elf Outfit', 'Skis', 'Cup Hot Chocolate'],
  },
  {
    id: 266,
    type: 'Platypus',
    traits: ['2', 'Snowman Valley', 'Rosy Cheeks', 'Beanie Winter Red', 'Ski Pole'],
  },
  {
    id: 267,
    type: 'Octopus',
    traits: ['3', 'Snowball Fight Arena', 'Ear Warmers Red', 'Scarf Red', 'Cup Hot Chocolate'],
  },
  {
    id: 268,
    type: 'Quokka',
    traits: ['4', 'Cozy Hut', 'Winter Jacket Blue', 'Skis', 'Cup Hot Chocolate'],
  },
  {
    id: 269,
    type: 'Kangaroo',
    traits: ['5', 'Christmas Markets', 'Ear Warmers White', 'Present'],
  },
  {
    id: 270,
    type: 'Platypus',
    traits: ['6', 'Santas Sled', 'Elf Outfit', 'Gloves Blue', 'Christmas Bell'],
  },
  {
    id: 271,
    type: 'Penguin',
    traits: ['7', 'Santas Sack n Stable', 'Ear Warmers Red', 'Scarf Blue', 'Present'],
  },
  {
    id: 272,
    type: 'Tasmanian Devil',
    traits: ['Rudolf Nose', '8', 'Pile of Presents', 'Elf Hat', 'Scarf Blue', 'Sled'],
  },
  {
    id: 273,
    type: 'Wombat',
    traits: ['9', 'Toy Factory', 'Elf Outfit', 'Gloves Red'],
  },
  {
    id: 274,
    type: 'Dingo',
    traits: ['10', 'Cozy Fireplace', 'Winter Jacket Purple', 'Glass Egg Nog', 'Ski Pole'],
  },
  {
    id: 275,
    type: 'Seal',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Red', 'Scarf Blue', 'Glass Egg Nog'],
  },
  {
    id: 276,
    type: 'Koala',
    traits: ['Pipe', '12', 'Bondi Beach', 'Glass Milk'],
  },
  {
    id: 277,
    type: 'Wombat',
    traits: ['1', 'Sledding Hill', 'Beanie Winter White', 'Elf Outfit', 'Snow Shovel', 'Snowball'],
  },
  {
    id: 278,
    type: 'Crocodile',
    traits: ['2', 'Snowman Valley', 'Ear Warmers Red', 'Snowball'],
  },
  {
    id: 279,
    type: 'Quokka',
    traits: ['3', 'Snowball Fight Arena', 'Elf Hat', 'Elf Outfit', 'Gloves Blue', 'Christmas Bell'],
  },
  {
    id: 280,
    type: 'Tasmanian Tiger',
    traits: ['4', 'Cozy Hut', 'Rosy Cheeks', 'Beanie Winter Red', 'Winter Jacket Red', 'Skis'],
  },
  {
    id: 281,
    type: 'Platypus',
    traits: ['5', 'Christmas Markets', 'Ear Warmers White', 'Glass Milk', 'Chocolate Cookie'],
  },
  {
    id: 282,
    type: 'Quokka',
    traits: ['6', 'Santas Sled', 'Elf Hat', 'Winter Jacket Red', 'Chocolate Cookie'],
  },
  {
    id: 283,
    type: 'Platypus',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter White', 'Glass Milk', 'Present'],
  },
  {
    id: 284,
    type: 'Kangaroo',
    traits: ['8', 'Pile of Presents', 'Ear Warmers White', 'Glass Egg Nog', 'Cup Hot Chocolate'],
  },
  {
    id: 285,
    type: 'Tasmanian Devil',
    traits: ['9', 'Toy Factory', 'Scarf Red', 'Chocolate Cookie'],
  },
  {
    id: 286,
    type: 'Quokka',
    traits: ['10', 'Cozy Fireplace', 'Ski Goggles', 'Ear Warmers Red', 'Elf Outfit', 'Glass Milk', 'Cup Hot Chocolate'],
  },
  {
    id: 287,
    type: 'Crocodile',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Blue', 'Winter Jacket Blue', 'Glass Milk', 'Present'],
  },
  {
    id: 288,
    type: 'Penguin',
    traits: ['12', 'Bondi Beach', 'Sunnies', 'Present'],
  },
  {
    id: 289,
    type: 'Bunyip',
    traits: ['1', 'Sledding Hill', 'Elf Hat', 'Winter Jacket Purple', 'Chocolate Cookie'],
  },
  {
    id: 290,
    type: 'Kangaroo',
    traits: ['2', 'Snowman Valley', 'Winter Jacket Blue', 'Snowball'],
  },
  {
    id: 291,
    type: 'Tasmanian Devil',
    traits: ['3', 'Snowball Fight Arena', 'Scarf Red', 'Snow Shovel', 'Candy Cane'],
  },
  {
    id: 292,
    type: 'Crocodile',
    traits: ['4', 'Cozy Hut', 'Rosy Cheeks', 'Scarf Blue'],
  },
  {
    id: 293,
    type: 'Platypus',
    traits: ['5', 'Christmas Markets', 'Winter Jacket Purple', 'Skis', 'Chocolate Cookie'],
  },
  {
    id: 294,
    type: 'Kangaroo',
    traits: ['6', 'Santas Sled', 'Winter Jacket Purple', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 295,
    type: 'Tasmanian Tiger',
    traits: ['Xmas Laser Eyes', '7', 'Santas Sack n Stable', 'Beanie Winter Blue', 'Winter Jacket Red', 'Snow Shovel', 'Candle'],
  },
  {
    id: 296,
    type: 'Crocodile',
    traits: ['8', 'Pile of Presents', 'Rosy Cheeks', 'Beanie Winter White', 'Scarf Red', 'Gloves Red'],
  },
  {
    id: 297,
    type: 'Platypus',
    traits: ['9', 'Toy Factory', 'Snow Shovel', 'Snowball'],
  },
  {
    id: 298,
    type: 'Crocodile',
    traits: ['Pipe', '10', 'Cozy Fireplace', 'Ear Warmers Red', 'Glass Egg Nog'],
  },
  {
    id: 299,
    type: 'Bunyip',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Red', 'Scarf Blue', 'Ski Pole', 'Santas Beard'],
  },
  {
    id: 300,
    type: 'Crocodile',
    traits: ['12', 'Bondi Beach'],
  },
  {
    id: 301,
    type: 'Wombat',
    traits: ['1', 'Sledding Hill', 'Ear Warmers White', 'Scarf Blue', 'Glass Egg Nog', 'Christmas Bell'],
  },
  {
    id: 302,
    type: 'Platypus',
    traits: ['2', 'Snowman Valley', 'Beanie Winter Blue'],
  },
  {
    id: 303,
    type: 'Wombat',
    traits: ['3', 'Snowball Fight Arena', 'Antlers', 'Winter Jacket Purple', 'Cup Hot Chocolate'],
  },
  {
    id: 304,
    type: 'Wombat',
    traits: ['4', 'Cozy Hut', 'Ear Warmers White', 'Scarf Red', 'Snowball'],
  },
  {
    id: 305,
    type: 'Koala',
    traits: ['5', 'Christmas Markets', 'Elf Outfit', 'Christmas Bell'],
  },
  {
    id: 306,
    type: 'Penguin',
    traits: ['6', 'Santas Sled', 'Beanie Winter Blue', 'Gloves Red', 'Chocolate Cookie'],
  },
  {
    id: 307,
    type: 'Penguin',
    traits: ['7', 'Santas Sack n Stable', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 308,
    type: 'Tasmanian Devil',
    traits: ['8', 'Pile of Presents', 'Ski Goggles', 'Winter Jacket Purple', 'Gloves Red', 'Cup Hot Chocolate'],
  },
  {
    id: 309,
    type: 'Tasmanian Devil',
    traits: ['9', 'Toy Factory', 'Chocolate Cookie', 'Santas Beard'],
  },
  {
    id: 310,
    type: 'Crocodile',
    traits: ['10', 'Cozy Fireplace', 'Winter Jacket Blue', 'Snowball'],
  },
  {
    id: 311,
    type: 'Tasmanian Tiger',
    traits: ['11', 'Christmas Tree', 'Rosy Cheeks', 'Scarf Blue', 'Christmas Bell'],
  },
  {
    id: 312,
    type: 'Quokka',
    traits: ['12', 'Bondi Beach', 'Sled', 'Ski Pole'],
  },
  {
    id: 313,
    type: 'Tasmanian Devil',
    traits: ['1', 'Sledding Hill', 'Gloves Blue'],
  },
  {
    id: 314,
    type: 'Wombat',
    traits: ['2', 'Snowman Valley', 'Elf Outfit', 'Glass Egg Nog'],
  },
  {
    id: 315,
    type: 'Wombat',
    traits: ['3', 'Snowball Fight Arena', 'Elf Hat', 'Santa Outfit', 'Cup Hot Chocolate'],
  },
  {
    id: 316,
    type: 'Quokka',
    traits: ['4', 'Cozy Hut', 'Antlers', 'Scarf Blue', 'Sled', 'Ski Pole'],
  },
  {
    id: 317,
    type: 'Platypus',
    traits: ['5', 'Christmas Markets', 'Ski Goggles', 'Antlers', 'Winter Jacket Purple', 'Skis', 'Candy Cane'],
  },
  {
    id: 318,
    type: 'Penguin',
    traits: ['6', 'Santas Sled', 'Scarf Blue', 'Gloves Red', 'Candy Cane'],
  },
  {
    id: 319,
    type: 'Drop Bear',
    traits: ['7', 'Santas Sack n Stable', 'Glass Milk'],
  },
  {
    id: 320,
    type: 'Koala',
    traits: ['Xmas Laser Eyes', '8', 'Pile of Presents', 'Elf Outfit', 'Glass Egg Nog', 'Chocolate Cookie'],
  },
  {
    id: 321,
    type: 'Quokka',
    traits: ['9', 'Toy Factory', 'Rosy Cheeks', 'Antlers', 'Santa Outfit', 'Snow Shovel', 'Christmas Bell'],
  },
  {
    id: 322,
    type: 'Koala',
    traits: ['10', 'Cozy Fireplace', 'Scarf Red', 'Gloves Red', 'Chocolate Cookie'],
  },
  {
    id: 323,
    type: 'Dingo',
    traits: ['11', 'Christmas Tree', 'Elf Hat', 'Christmas Bell'],
  },
  {
    id: 324,
    type: 'Dingo',
    traits: ['12', 'Bondi Beach', 'Christmas Bell', 'Santas Beard'],
  },
  {
    id: 325,
    type: 'Platypus',
    traits: ['1', 'Sledding Hill', 'Ear Warmers Pink', 'Elf Outfit', 'Glass Egg Nog', 'Cup Hot Chocolate'],
  },
  {
    id: 326,
    type: 'Koala',
    traits: ['2', 'Snowman Valley', 'Winter Jacket Red', 'Gloves Blue', 'Christmas Bell'],
  },
  {
    id: 327,
    type: 'Wombat',
    traits: ['Pipe', '3', 'Snowball Fight Arena', 'Beanie Winter White'],
  },
  {
    id: 328,
    type: 'Dingo',
    traits: ['4', 'Cozy Hut', 'Ear Warmers White', 'Skis', 'Cup Hot Chocolate'],
  },
  {
    id: 329,
    type: 'Dingo',
    traits: ['5', 'Christmas Markets', 'Beanie Winter Blue', 'Winter Jacket Purple', 'Present'],
  },
  {
    id: 330,
    type: 'Tasmanian Devil',
    traits: ['6', 'Santas Sled', 'Beanie Winter Blue', 'Glass Egg Nog', 'Cup Hot Chocolate'],
  },
  {
    id: 331,
    type: 'Seal',
    traits: ['7', 'Santas Sack n Stable', 'Ear Warmers White', 'Scarf Red', 'Skis'],
  },
  {
    id: 332,
    type: 'Shark',
    traits: ['8', 'Pile of Presents', 'Scarf Red', 'Glass Egg Nog', 'Candy Cane'],
  },
  {
    id: 333,
    type: 'Platypus',
    traits: ['9', 'Toy Factory', 'Beanie Winter White', 'Winter Jacket Purple'],
  },
  {
    id: 334,
    type: 'Tasmanian Tiger',
    traits: ['10', 'Cozy Fireplace', 'Ear Warmers Pink', 'Scarf Blue', 'Gloves Blue', 'Chocolate Cookie'],
  },
  {
    id: 335,
    type: 'Platypus',
    traits: ['11', 'Christmas Tree', 'Bradbury Helmet', 'Winter Jacket Purple', 'Gloves Red', 'Candy Cane'],
  },
  {
    id: 336,
    type: 'Shark',
    traits: ['12', 'Bondi Beach', 'Sunnies', 'Candle'],
  },
  {
    id: 337,
    type: 'Dingo',
    traits: ['1', 'Sledding Hill', 'Scarf Blue', 'Chocolate Cookie'],
  },
  {
    id: 338,
    type: 'Kangaroo',
    traits: ['2', 'Snowman Valley', 'Beanie Winter Blue', 'Scarf Blue', 'Glass Milk', 'Snowball'],
  },
  {
    id: 339,
    type: 'Penguin',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter White', 'Winter Jacket Blue', 'Glass Milk', 'Present'],
  },
  {
    id: 340,
    type: 'Octopus',
    traits: ['Rudolf Nose', '4', 'Cozy Hut', 'Candy Cane'],
  },
  {
    id: 341,
    type: 'Drop Bear',
    traits: ['Pipe', '5', 'Christmas Markets', 'Scarf Red', 'Glass Egg Nog', 'Christmas Bell'],
  },
  {
    id: 342,
    type: 'Koala',
    traits: ['6', 'Santas Sled', 'Beanie Winter White', 'Winter Jacket Red', 'Snowball'],
  },
  {
    id: 343,
    type: 'Shark',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter Blue', 'Scarf Red', 'Glass Milk'],
  },
  {
    id: 344,
    type: 'Seal',
    traits: ['8', 'Pile of Presents', 'Antlers', 'Winter Jacket Purple', 'Sled'],
  },
  {
    id: 345,
    type: 'Koala',
    traits: ['9', 'Toy Factory', 'Gloves Blue', 'Snowball'],
  },
  {
    id: 346,
    type: 'Kangaroo',
    traits: ['10', 'Cozy Fireplace', 'Winter Jacket Purple', 'Snowball'],
  },
  {
    id: 347,
    type: 'Koala',
    traits: ['11', 'Christmas Tree', 'Gloves Red'],
  },
  {
    id: 348,
    type: 'Octopus',
    traits: ['12', 'Bondi Beach', 'Sled'],
  },
  {
    id: 349,
    type: 'Wombat',
    traits: ['1', 'Sledding Hill', 'Ear Warmers Red', 'Scarf Red', 'Gloves Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 350,
    type: 'Dingo',
    traits: ['2', 'Snowman Valley', 'Beanie Winter Blue', 'Winter Jacket Red', 'Sled', 'Chocolate Cookie'],
  },
  {
    id: 351,
    type: 'Penguin',
    traits: ['3', 'Snowball Fight Arena', 'Winter Jacket Red', 'Gloves Blue'],
  },
  {
    id: 352,
    type: 'Koala',
    traits: ['4', 'Cozy Hut', 'Ear Warmers Red', 'Glass Milk', 'Cup Hot Chocolate'],
  },
  {
    id: 353,
    type: 'Quokka',
    traits: ['5', 'Christmas Markets', 'Beanie Winter White', 'Winter Jacket Red', 'Gloves Red'],
  },
  {
    id: 354,
    type: 'Dingo',
    traits: ['6', 'Santas Sled', 'Beanie Winter Red', 'Glass Egg Nog', 'Candy Cane'],
  },
  {
    id: 355,
    type: 'Octopus',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter Blue', 'Sled', 'Present'],
  },
  {
    id: 356,
    type: 'Wombat',
    traits: ['8', 'Pile of Presents', 'Antlers', 'Scarf Blue', 'Sled', 'Chocolate Cookie'],
  },
  {
    id: 357,
    type: 'Tasmanian Devil',
    traits: ['9', 'Toy Factory', 'Elf Outfit', 'Ski Pole'],
  },
  {
    id: 358,
    type: 'Wombat',
    traits: ['Pipe', '10', 'Cozy Fireplace', 'Scarf Red', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 359,
    type: 'Tasmanian Devil',
    traits: ['11', 'Christmas Tree', 'Antlers', 'Santa Outfit', 'Present'],
  },
  {
    id: 360,
    type: 'Octopus',
    traits: ['12', 'Bondi Beach', 'Rosy Cheeks', 'Present'],
  },
  {
    id: 361,
    type: 'Penguin',
    traits: ['1', 'Sledding Hill', 'Beanie Winter Red'],
  },
  {
    id: 362,
    type: 'Drop Bear',
    traits: ['Rudolf Nose', '2', 'Snowman Valley', 'Ear Warmers Red', 'Scarf Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 363,
    type: 'Kangaroo',
    traits: ['3', 'Snowball Fight Arena', 'Ear Warmers White', 'Skis'],
  },
  {
    id: 364,
    type: 'Crocodile',
    traits: ['4', 'Cozy Hut', 'Beanie Winter Red', 'Scarf Blue', 'Skis'],
  },
  {
    id: 365,
    type: 'Kangaroo',
    traits: ['5', 'Christmas Markets', 'Gloves Blue'],
  },
  {
    id: 366,
    type: 'Seal',
    traits: ['6', 'Santas Sled', 'Ear Warmers Pink', 'Glass Egg Nog', 'Present'],
  },
  {
    id: 367,
    type: 'Penguin',
    traits: ['7', 'Santas Sack n Stable', 'Chocolate Cookie'],
  },
  {
    id: 368,
    type: 'Shark',
    traits: ['8', 'Pile of Presents', 'Glass Milk', 'Cup Hot Chocolate'],
  },
  {
    id: 369,
    type: 'Dingo',
    traits: ['9', 'Toy Factory', 'Ear Warmers Pink'],
  },
  {
    id: 370,
    type: 'Quokka',
    traits: ['10', 'Cozy Fireplace', 'Elf Hat', 'Elf Outfit'],
  },
  {
    id: 371,
    type: 'Tasmanian Devil',
    traits: ['11', 'Christmas Tree', 'Ear Warmers Pink', 'Glass Milk'],
  },
  {
    id: 372,
    type: 'Shark',
    traits: ['12', 'Bondi Beach', 'Rosy Cheeks', 'Sunnies', 'Skis'],
  },
  {
    id: 373,
    type: 'Penguin',
    traits: ['1', 'Sledding Hill', 'Elf Hat', 'Gloves Red', 'Candy Cane'],
  },
  {
    id: 374,
    type: 'Quokka',
    traits: ['2', 'Snowman Valley', 'Snow Shovel', 'Candle'],
  },
  {
    id: 375,
    type: 'Seal',
    traits: ['3', 'Snowball Fight Arena', 'Skis'],
  },
  {
    id: 376,
    type: 'Platypus',
    traits: ['4', 'Cozy Hut', 'Bradbury Helmet', 'Winter Jacket Red', 'Glass Egg Nog', 'Cup Hot Chocolate'],
  },
  {
    id: 377,
    type: 'Crocodile',
    traits: ['5', 'Christmas Markets', 'Beanie Winter Blue', 'Gloves Red'],
  },
  {
    id: 378,
    type: 'Platypus',
    traits: ['6', 'Santas Sled', 'Snow Shovel', 'Present'],
  },
  {
    id: 379,
    type: 'Platypus',
    traits: ['7', 'Santas Sack n Stable', 'Ear Warmers Pink'],
  },
  {
    id: 380,
    type: 'Wombat',
    traits: ['8', 'Pile of Presents', 'Ski Goggles', 'Ear Warmers Red'],
  },
  {
    id: 381,
    type: 'Shark',
    traits: ['Xmas Laser Eyes', '9', 'Toy Factory', 'Beanie Winter White', 'Winter Jacket Red', 'Skis', 'Cup Hot Chocolate'],
  },
  {
    id: 382,
    type: 'Platypus',
    traits: ['10', 'Cozy Fireplace', 'Rosy Cheeks', 'Beanie Winter Blue', 'Winter Jacket Purple', 'Glass Milk', 'Chocolate Cookie'],
  },
  {
    id: 383,
    type: 'Kangaroo',
    traits: ['11', 'Christmas Tree', 'Rosy Cheeks', 'Gloves Blue'],
  },
  {
    id: 384,
    type: 'Quokka',
    traits: ['Pipe', '12', 'Bondi Beach', 'Sunnies', 'Skis'],
  },
  {
    id: 385,
    type: 'Kangaroo',
    traits: ['1', 'Sledding Hill', 'Santas Hat', 'Winter Jacket Red'],
  },
  {
    id: 386,
    type: 'Wombat',
    traits: ['2', 'Snowman Valley', 'Beanie Winter Blue', 'Winter Jacket Blue', 'Gloves Red', 'Cup Hot Chocolate'],
  },
  {
    id: 387,
    type: 'Quokka',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter Blue', 'Winter Jacket Blue', 'Gloves Red', 'Christmas Bell'],
  },
  {
    id: 388,
    type: 'Octopus',
    traits: ['4', 'Cozy Hut', 'Beanie Winter Blue', 'Scarf Red', 'Snow Shovel', 'Snowball'],
  },
  {
    id: 389,
    type: 'Quokka',
    traits: ['5', 'Christmas Markets', 'Antlers', 'Winter Jacket Blue', 'Ski Pole'],
  },
  {
    id: 390,
    type: 'Wombat',
    traits: ['6', 'Santas Sled', 'Santa Outfit', 'Sled'],
  },
  {
    id: 391,
    type: 'Seal',
    traits: ['7', 'Santas Sack n Stable', 'Antlers', 'Winter Jacket Red', 'Gloves Red', 'Snowball'],
  },
  {
    id: 392,
    type: 'Penguin',
    traits: ['8', 'Pile of Presents', 'Beanie Winter Red', 'Winter Jacket Red', 'Glass Milk'],
  },
  {
    id: 393,
    type: 'Grinch',
    traits: ['9', 'Toy Factory'],
  },
  {
    id: 394,
    type: 'Crocodile',
    traits: ['10', 'Cozy Fireplace', 'Scarf Blue', 'Gloves Blue', 'Present'],
  },
  {
    id: 395,
    type: 'Kangaroo',
    traits: ['11', 'Christmas Tree', 'Beanie Winter White', 'Glass Milk'],
  },
  {
    id: 396,
    type: 'Kangaroo',
    traits: ['12', 'Bondi Beach', 'Rosy Cheeks', 'Candy Cane'],
  },
  {
    id: 397,
    type: 'Kangaroo',
    traits: ['1', 'Sledding Hill', 'Snow Shovel', 'Ski Pole'],
  },
  {
    id: 398,
    type: 'Shark',
    traits: ['2', 'Snowman Valley', 'Rosy Cheeks', 'Winter Jacket Blue'],
  },
  {
    id: 399,
    type: 'Seal',
    traits: ['3', 'Snowball Fight Arena', 'Winter Jacket Red', 'Gloves Blue', 'Ski Pole'],
  },
  {
    id: 400,
    type: 'Crocodile',
    traits: ['Xmas Laser Eyes', '4', 'Cozy Hut', 'Ear Warmers Red', 'Scarf Red', 'Gloves Red'],
  },
  {
    id: 401,
    type: 'Shark',
    traits: ['5', 'Christmas Markets', 'Skis', 'Ski Pole'],
  },
  {
    id: 402,
    type: 'Koala',
    traits: ['6', 'Santas Sled', 'Gloves Red', 'Chocolate Cookie'],
  },
  {
    id: 403,
    type: 'Shark',
    traits: ['7', 'Santas Sack n Stable', 'Gloves Red'],
  },
  {
    id: 404,
    type: 'Crocodile',
    traits: ['8', 'Pile of Presents', 'Winter Jacket Purple', 'Candle'],
  },
  {
    id: 405,
    type: 'Shark',
    traits: ['9', 'Toy Factory', 'Santas Hat'],
  },
  {
    id: 406,
    type: 'Shark',
    traits: ['10', 'Cozy Fireplace', 'Glass Egg Nog', 'Ski Pole'],
  },
  {
    id: 407,
    type: 'Octopus',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Red', 'Christmas Bell'],
  },
  {
    id: 408,
    type: 'Crocodile',
    traits: ['12', 'Bondi Beach', 'Chocolate Cookie'],
  },
  {
    id: 409,
    type: 'Octopus',
    traits: ['1', 'Sledding Hill', 'Santas Hat'],
  },
  {
    id: 410,
    type: 'Shark',
    traits: ['2', 'Snowman Valley', 'Rosy Cheeks', 'Beanie Winter Blue', 'Winter Jacket Red', 'Snow Shovel', 'Candy Cane'],
  },
  {
    id: 411,
    type: 'Tasmanian Devil',
    traits: ['3', 'Snowball Fight Arena', 'Ski Goggles', 'Present'],
  },
  {
    id: 412,
    type: 'Crocodile',
    traits: ['4', 'Cozy Hut', 'Gloves Red'],
  },
  {
    id: 413,
    type: 'Seal',
    traits: ['5', 'Christmas Markets', 'Winter Jacket Purple', 'Candy Cane'],
  },
  {
    id: 414,
    type: 'Penguin',
    traits: ['6', 'Santas Sled', 'Beanie Winter Blue', 'Scarf Blue', 'Gloves Red', 'Cup Hot Chocolate'],
  },
  {
    id: 415,
    type: 'Koala',
    traits: ['7', 'Santas Sack n Stable', 'Winter Jacket Blue', 'Glass Milk'],
  },
  {
    id: 416,
    type: 'Kangaroo',
    traits: ['8', 'Pile of Presents', 'Beanie Winter White', 'Winter Jacket Blue', 'Christmas Bell'],
  },
  {
    id: 417,
    type: 'Crocodile',
    traits: ['9', 'Toy Factory', 'Antlers', 'Sled', 'Candy Cane'],
  },
  {
    id: 418,
    type: 'Crocodile',
    traits: ['10', 'Cozy Fireplace', 'Ear Warmers Red', 'Scarf Blue', 'Skis', 'Ski Pole'],
  },
  {
    id: 419,
    type: 'Koala',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Red', 'Glass Milk', 'Snowball'],
  },
  {
    id: 420,
    type: 'Penguin',
    traits: ['12', 'Bondi Beach'],
  },
  {
    id: 421,
    type: 'Seal',
    traits: ['1', 'Sledding Hill', 'Bradbury Helmet', 'Winter Jacket Red', 'Sled', 'Candy Cane'],
  },
  {
    id: 422,
    type: 'Penguin',
    traits: ['2', 'Snowman Valley', 'Beanie Winter Red', 'Winter Jacket Blue', 'Skis', 'Cup Hot Chocolate'],
  },
  {
    id: 423,
    type: 'Shark',
    traits: ['3', 'Snowball Fight Arena', 'Ski Goggles', 'Winter Jacket Red', 'Snow Shovel', 'Christmas Bell'],
  },
  {
    id: 424,
    type: 'Kangaroo',
    traits: ['4', 'Cozy Hut', 'Rosy Cheeks', 'Winter Jacket Red', 'Skis'],
  },
  {
    id: 425,
    type: 'Shark',
    traits: ['5', 'Christmas Markets', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 426,
    type: 'Wombat',
    traits: ['6', 'Santas Sled', 'Elf Outfit'],
  },
  {
    id: 427,
    type: 'Tasmanian Tiger',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter Red', 'Winter Jacket Blue', 'Glass Egg Nog'],
  },
  {
    id: 428,
    type: 'Tasmanian Tiger',
    traits: ['8', 'Pile of Presents', 'Sled', 'Christmas Bell'],
  },
  {
    id: 429,
    type: 'Platypus',
    traits: ['9', 'Toy Factory', 'Beanie Winter Red', 'Scarf Blue', 'Sled', 'Cup Hot Chocolate'],
  },
  {
    id: 430,
    type: 'Kangaroo',
    traits: ['10', 'Cozy Fireplace', 'Scarf Red', 'Sled', 'Christmas Bell'],
  },
  {
    id: 431,
    type: 'Dingo',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Red', 'Winter Jacket Blue', 'Gloves Blue'],
  },
  {
    id: 432,
    type: 'Tasmanian Devil',
    traits: ['12', 'Bondi Beach', 'Rosy Cheeks', 'Sunnies', 'Christmas Bell'],
  },
  {
    id: 433,
    type: 'Crocodile',
    traits: ['1', 'Sledding Hill', 'Beanie Winter Red', 'Scarf Red', 'Gloves Blue'],
  },
  {
    id: 434,
    type: 'Octopus',
    traits: ['2', 'Snowman Valley', 'Ear Warmers Pink', 'Scarf Blue', 'Snow Shovel', 'Snowball'],
  },
  {
    id: 435,
    type: 'Dingo',
    traits: ['Rudolf Nose', '3', 'Snowball Fight Arena', 'Ear Warmers Red', 'Present'],
  },
  {
    id: 436,
    type: 'Bunyip',
    traits: ['4', 'Cozy Hut', 'Gloves Red'],
  },
  {
    id: 437,
    type: 'Kangaroo',
    traits: ['5', 'Christmas Markets', 'Winter Jacket Purple'],
  },
  {
    id: 438,
    type: 'Koala',
    traits: ['6', 'Santas Sled', 'Ski Goggles', 'Antlers', 'Winter Jacket Red'],
  },
  {
    id: 439,
    type: 'Penguin',
    traits: ['7', 'Santas Sack n Stable', 'Gloves Blue', 'Christmas Bell'],
  },
  {
    id: 440,
    type: 'Penguin',
    traits: ['8', 'Pile of Presents', 'Ski Goggles', 'Glass Milk'],
  },
  {
    id: 441,
    type: 'Kangaroo',
    traits: ['9', 'Toy Factory', 'Scarf Blue', 'Candy Cane'],
  },
  {
    id: 442,
    type: 'Shark',
    traits: ['10', 'Cozy Fireplace', 'Rosy Cheeks', 'Gloves Blue', 'Present'],
  },
  {
    id: 443,
    type: 'Drop Bear',
    traits: ['11', 'Christmas Tree', 'Bradbury Helmet', 'Winter Jacket Blue', 'Present'],
  },
  {
    id: 444,
    type: 'Koala',
    traits: ['12', 'Bondi Beach', 'Glass Milk'],
  },
  {
    id: 445,
    type: 'Penguin',
    traits: ['1', 'Sledding Hill', 'Beanie Winter Blue', 'Scarf Red', 'Snow Shovel', 'Candy Cane'],
  },
  {
    id: 446,
    type: 'Platypus',
    traits: ['2', 'Snowman Valley', 'Elf Outfit', 'Gloves Red'],
  },
  {
    id: 447,
    type: 'Shark',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter White', 'Scarf Red', 'Sled'],
  },
  {
    id: 448,
    type: 'Penguin',
    traits: ['4', 'Cozy Hut', 'Ski Goggles', 'Ear Warmers Pink'],
  },
  {
    id: 449,
    type: 'Platypus',
    traits: ['5', 'Christmas Markets', 'Antlers', 'Gloves Blue', 'Present'],
  },
  {
    id: 450,
    type: 'Kangaroo',
    traits: ['6', 'Santas Sled', 'Beanie Winter Red', 'Winter Jacket Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 451,
    type: 'Grinch',
    traits: ['7', 'Santas Sack n Stable', 'Gloves Red', 'Christmas Bell'],
  },
  {
    id: 452,
    type: 'Quokka',
    traits: ['8', 'Pile of Presents', 'Rosy Cheeks', 'Sled'],
  },
  {
    id: 453,
    type: 'Quokka',
    traits: ['9', 'Toy Factory', 'Elf Hat', 'Winter Jacket Blue', 'Candy Cane'],
  },
  {
    id: 454,
    type: 'Platypus',
    traits: ['10', 'Cozy Fireplace', 'Ear Warmers Red', 'Ski Pole'],
  },
  {
    id: 455,
    type: 'Quokka',
    traits: ['Pipe', '11', 'Christmas Tree', 'Beanie Winter Red', 'Winter Jacket Red', 'Sled', 'Candy Cane'],
  },
  {
    id: 456,
    type: 'Kangaroo',
    traits: ['12', 'Bondi Beach', 'Rosy Cheeks', 'Sled', 'Chocolate Cookie'],
  },
  {
    id: 457,
    type: 'Koala',
    traits: ['1', 'Sledding Hill', 'Beanie Winter Red', 'Skis', 'Present'],
  },
  {
    id: 458,
    type: 'Seal',
    traits: ['2', 'Snowman Valley', 'Winter Jacket Blue', 'Sled', 'Chocolate Cookie'],
  },
  {
    id: 459,
    type: 'Penguin',
    traits: ['3', 'Snowball Fight Arena', 'Rosy Cheeks', 'Beanie Winter White'],
  },
  {
    id: 460,
    type: 'Shark',
    traits: ['4', 'Cozy Hut'],
  },
  {
    id: 461,
    type: 'Quokka',
    traits: ['Pipe', '5', 'Christmas Markets', 'Winter Jacket Red', 'Sled', 'Ski Pole'],
  },
  {
    id: 462,
    type: 'Quokka',
    traits: ['6', 'Santas Sled', 'Ear Warmers Red', 'Scarf Blue', 'Chocolate Cookie'],
  },
  {
    id: 463,
    type: 'Platypus',
    traits: ['7', 'Santas Sack n Stable', 'Ear Warmers Pink', 'Scarf Red', 'Snow Shovel'],
  },
  {
    id: 464,
    type: 'Tasmanian Tiger',
    traits: ['Xmas Laser Eyes', '8', 'Pile of Presents', 'Beanie Winter Blue', 'Winter Jacket Red', 'Gloves Red'],
  },
  {
    id: 465,
    type: 'Koala',
    traits: ['9', 'Toy Factory', 'Ear Warmers White'],
  },
  {
    id: 466,
    type: 'Shark',
    traits: ['10', 'Cozy Fireplace', 'Rosy Cheeks', 'Winter Jacket Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 467,
    type: 'Shark',
    traits: ['11', 'Christmas Tree', 'Winter Jacket Purple', 'Gloves Red', 'Christmas Bell'],
  },
  {
    id: 468,
    type: 'Grinch',
    traits: ['12', 'Bondi Beach', 'Sled'],
  },
  {
    id: 469,
    type: 'Platypus',
    traits: ['1', 'Sledding Hill', 'Beanie Winter White', 'Elf Outfit'],
  },
  {
    id: 470,
    type: 'Dingo',
    traits: ['2', 'Snowman Valley', 'Antlers', 'Gloves Blue', 'Chocolate Cookie'],
  },
  {
    id: 471,
    type: 'Wombat',
    traits: ['3', 'Snowball Fight Arena', 'Scarf Red', 'Sled', 'Christmas Bell'],
  },
  {
    id: 472,
    type: 'Dingo',
    traits: ['Xmas Laser Eyes', '4', 'Cozy Hut', 'Rosy Cheeks', 'Glass Milk', 'Cup Hot Chocolate'],
  },
  {
    id: 473,
    type: 'Crocodile',
    traits: ['5', 'Christmas Markets', 'Beanie Winter White', 'Scarf Blue', 'Glass Egg Nog', 'Cup Hot Chocolate'],
  },
  {
    id: 474,
    type: 'Quokka',
    traits: ['6', 'Santas Sled', 'Ear Warmers Red', 'Skis', 'Present'],
  },
  {
    id: 475,
    type: 'Dingo',
    traits: ['7', 'Santas Sack n Stable', 'Ear Warmers Pink', 'Snow Shovel', 'Christmas Bell'],
  },
  {
    id: 476,
    type: 'Wombat',
    traits: ['8', 'Pile of Presents', 'Cup Hot Chocolate'],
  },
  {
    id: 477,
    type: 'Wombat',
    traits: ['9', 'Toy Factory', 'Beanie Winter Red', 'Winter Jacket Red', 'Snow Shovel', 'Chocolate Cookie'],
  },
  {
    id: 478,
    type: 'Dingo',
    traits: ['10', 'Cozy Fireplace', 'Rosy Cheeks', 'Beanie Winter White', 'Skis', 'Present'],
  },
  {
    id: 479,
    type: 'Platypus',
    traits: ['11', 'Christmas Tree', 'Santas Hat', 'Glass Milk', 'Present'],
  },
  {
    id: 480,
    type: 'Crocodile',
    traits: ['12', 'Bondi Beach', 'Glass Milk'],
  },
  {
    id: 481,
    type: 'Tasmanian Tiger',
    traits: ['1', 'Sledding Hill', 'Rosy Cheeks', 'Antlers', 'Winter Jacket Red', 'Snow Shovel', 'Chocolate Cookie'],
  },
  {
    id: 482,
    type: 'Wombat',
    traits: ['2', 'Snowman Valley', 'Ski Goggles', 'Antlers', 'Gloves Red', 'Christmas Bell'],
  },
  {
    id: 483,
    type: 'Tasmanian Devil',
    traits: ['3', 'Snowball Fight Arena', 'Ear Warmers Pink', 'Elf Outfit'],
  },
  {
    id: 484,
    type: 'Koala',
    traits: ['4', 'Cozy Hut', 'Antlers', 'Scarf Red', 'Sled', 'Christmas Bell'],
  },
  {
    id: 485,
    type: 'Dingo',
    traits: ['5', 'Christmas Markets', 'Scarf Red', 'Sled', 'Cup Hot Chocolate'],
  },
  {
    id: 486,
    type: 'Tasmanian Devil',
    traits: ['6', 'Santas Sled', 'Antlers', 'Ski Pole'],
  },
  {
    id: 487,
    type: 'Koala',
    traits: ['7', 'Santas Sack n Stable', 'Rosy Cheeks', 'Scarf Red', 'Snowball'],
  },
  {
    id: 488,
    type: 'Crocodile',
    traits: ['Pipe', '8', 'Pile of Presents', 'Ear Warmers White', 'Gloves Blue'],
  },
  {
    id: 489,
    type: 'Crocodile',
    traits: ['9', 'Toy Factory', 'Ear Warmers Red', 'Elf Outfit', 'Cup Hot Chocolate'],
  },
  {
    id: 490,
    type: 'Tasmanian Tiger',
    traits: ['10', 'Cozy Fireplace'],
  },
  {
    id: 491,
    type: 'Wombat',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Red', 'Skis', 'Snowball'],
  },
  {
    id: 492,
    type: 'Koala',
    traits: ['12', 'Bondi Beach'],
  },
  {
    id: 493,
    type: 'Tasmanian Tiger',
    traits: ['1', 'Sledding Hill', 'Santas Hat', 'Candy Cane'],
  },
  {
    id: 494,
    type: 'Koala',
    traits: ['2', 'Snowman Valley', 'Winter Jacket Purple'],
  },
  {
    id: 495,
    type: 'Platypus',
    traits: ['3', 'Snowball Fight Arena', 'Elf Hat', 'Winter Jacket Blue', 'Sled', 'Cup Hot Chocolate'],
  },
  {
    id: 496,
    type: 'Crocodile',
    traits: ['4', 'Cozy Hut', 'Elf Outfit'],
  },
  {
    id: 497,
    type: 'Platypus',
    traits: ['Pipe', '5', 'Christmas Markets', 'Elf Outfit', 'Snow Shovel', 'Candy Cane'],
  },
  {
    id: 498,
    type: 'Shark',
    traits: ['6', 'Santas Sled', 'Winter Jacket Purple', 'Gloves Blue'],
  },
  {
    id: 499,
    type: 'Wombat',
    traits: ['7', 'Santas Sack n Stable', 'Ski Goggles', 'Elf Outfit'],
  },
  {
    id: 500,
    type: 'Wombat',
    traits: ['Xmas Laser Eyes', '8', 'Pile of Presents', 'Rosy Cheeks', 'Elf Hat', 'Winter Jacket Red', 'Snow Shovel', 'Present'],
  },
  {
    id: 501,
    type: 'Crocodile',
    traits: ['9', 'Toy Factory', 'Ski Goggles', 'Ear Warmers Red', 'Scarf Blue', 'Skis', 'Cup Hot Chocolate'],
  },
  {
    id: 502,
    type: 'Tasmanian Tiger',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter White', 'Winter Jacket Blue', 'Gloves Red'],
  },
  {
    id: 503,
    type: 'Wombat',
    traits: ['Xmas Laser Eyes', '11', 'Christmas Tree', 'Ear Warmers Red', 'Elf Outfit'],
  },
  {
    id: 504,
    type: 'Tasmanian Devil',
    traits: ['12', 'Bondi Beach', 'Cup Hot Chocolate'],
  },
  {
    id: 505,
    type: 'Drop Bear',
    traits: ['1', 'Sledding Hill', 'Beanie Winter Red', 'Scarf Red', 'Chocolate Cookie'],
  },
  {
    id: 506,
    type: 'Koala',
    traits: ['2', 'Snowman Valley', 'Rosy Cheeks', 'Antlers', 'Elf Outfit'],
  },
  {
    id: 507,
    type: 'Octopus',
    traits: ['3', 'Snowball Fight Arena', 'Ear Warmers Red', 'Gloves Red'],
  },
  {
    id: 508,
    type: 'Koala',
    traits: ['4', 'Cozy Hut', 'Winter Jacket Red'],
  },
  {
    id: 509,
    type: 'Dingo',
    traits: ['5', 'Christmas Markets', 'Beanie Winter White', 'Scarf Blue', 'Gloves Red', 'Ski Pole'],
  },
  {
    id: 510,
    type: 'Tasmanian Devil',
    traits: ['6', 'Santas Sled', 'Winter Jacket Red', 'Glass Milk', 'Christmas Bell'],
  },
  {
    id: 511,
    type: 'Octopus',
    traits: ['Pipe', '7', 'Santas Sack n Stable', 'Beanie Winter White', 'Scarf Blue', 'Candy Cane'],
  },
  {
    id: 512,
    type: 'Quokka',
    traits: ['8', 'Pile of Presents', 'Ear Warmers Red', 'Elf Outfit', 'Sled', 'Candle'],
  },
  {
    id: 513,
    type: 'Crocodile',
    traits: ['9', 'Toy Factory', 'Ear Warmers Pink', 'Present'],
  },
  {
    id: 514,
    type: 'Penguin',
    traits: ['10', 'Cozy Fireplace', 'Santas Hat', 'Elf Outfit', 'Gloves Red', 'Ski Pole'],
  },
  {
    id: 515,
    type: 'Tasmanian Devil',
    traits: ['11', 'Christmas Tree', 'Winter Jacket Purple', 'Ski Pole', 'Santas Beard'],
  },
  {
    id: 516,
    type: 'Tasmanian Devil',
    traits: ['12', 'Bondi Beach', 'Rosy Cheeks', 'Present'],
  },
  {
    id: 517,
    type: 'Dingo',
    traits: ['1', 'Sledding Hill', 'Gloves Red', 'Ski Pole'],
  },
  {
    id: 518,
    type: 'Wombat',
    traits: ['2', 'Snowman Valley', 'Beanie Winter Blue', 'Winter Jacket Purple'],
  },
  {
    id: 519,
    type: 'Seal',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter Red', 'Winter Jacket Red', 'Sled'],
  },
  {
    id: 520,
    type: 'Koala',
    traits: ['4', 'Cozy Hut', 'Elf Outfit', 'Chocolate Cookie'],
  },
  {
    id: 521,
    type: 'Kangaroo',
    traits: ['Pipe', '5', 'Christmas Markets', 'Antlers', 'Winter Jacket Blue', 'Glass Egg Nog'],
  },
  {
    id: 522,
    type: 'Tasmanian Devil',
    traits: ['6', 'Santas Sled', 'Rosy Cheeks', 'Beanie Winter Red', 'Scarf Red'],
  },
  {
    id: 523,
    type: 'Koala',
    traits: ['7', 'Santas Sack n Stable', 'Elf Outfit', 'Glass Milk', 'Ski Pole'],
  },
  {
    id: 524,
    type: 'Quokka',
    traits: ['8', 'Pile of Presents', 'Ear Warmers Red', 'Glass Egg Nog', 'Candy Cane'],
  },
  {
    id: 525,
    type: 'Wombat',
    traits: ['Xmas Laser Eyes', '9', 'Toy Factory', 'Rosy Cheeks', 'Beanie Winter Red', 'Scarf Red', 'Glass Egg Nog', 'Chocolate Cookie'],
  },
  {
    id: 526,
    type: 'Octopus',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter Red', 'Scarf Red', 'Santas Beard'],
  },
  {
    id: 527,
    type: 'Crocodile',
    traits: ['11', 'Christmas Tree', 'Beanie Winter White', 'Gloves Red', 'Chocolate Cookie'],
  },
  {
    id: 528,
    type: 'Kangaroo',
    traits: ['12', 'Bondi Beach', 'Glass Egg Nog', 'Cup Hot Chocolate'],
  },
  {
    id: 529,
    type: 'Bunyip',
    traits: ['1', 'Sledding Hill', 'Beanie Winter White', 'Gloves Red', 'Ski Pole'],
  },
  {
    id: 530,
    type: 'Penguin',
    traits: ['2', 'Snowman Valley', 'Beanie Winter White'],
  },
  {
    id: 531,
    type: 'Quokka',
    traits: ['Rudolf Nose', '3', 'Snowball Fight Arena', 'Sled'],
  },
  {
    id: 532,
    type: 'Tasmanian Tiger',
    traits: ['Pipe', '4', 'Cozy Hut', 'Beanie Winter Blue', 'Elf Outfit', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 533,
    type: 'Quokka',
    traits: ['5', 'Christmas Markets', 'Rosy Cheeks', 'Antlers', 'Elf Outfit', 'Candle'],
  },
  {
    id: 534,
    type: 'Dingo',
    traits: ['6', 'Santas Sled', 'Antlers', 'Winter Jacket Blue', 'Gloves Red'],
  },
  {
    id: 535,
    type: 'Platypus',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter Red', 'Winter Jacket Red', 'Glass Milk', 'Present'],
  },
  {
    id: 536,
    type: 'Tasmanian Tiger',
    traits: ['8', 'Pile of Presents', 'Rosy Cheeks', 'Winter Jacket Red', 'Christmas Bell'],
  },
  {
    id: 537,
    type: 'Quokka',
    traits: ['9', 'Toy Factory', 'Beanie Winter Red'],
  },
  {
    id: 538,
    type: 'Tasmanian Tiger',
    traits: ['10', 'Cozy Fireplace', 'Elf Outfit', 'Gloves Red', 'Candy Cane'],
  },
  {
    id: 539,
    type: 'Penguin',
    traits: ['11', 'Christmas Tree', 'Elf Hat', 'Winter Jacket Red', 'Glass Egg Nog', 'Candy Cane'],
  },
  {
    id: 540,
    type: 'Platypus',
    traits: ['12', 'Bondi Beach', 'Snow Shovel', 'Candy Cane'],
  },
  {
    id: 541,
    type: 'Tasmanian Devil',
    traits: ['Xmas Laser Eyes', '1', 'Sledding Hill', 'Rosy Cheeks', 'Ear Warmers Red', 'Gloves Red', 'Chocolate Cookie'],
  },
  {
    id: 542,
    type: 'Tasmanian Tiger',
    traits: ['2', 'Snowman Valley', 'Beanie Winter White', 'Winter Jacket Red', 'Cup Hot Chocolate'],
  },
  {
    id: 543,
    type: 'Penguin',
    traits: ['3', 'Snowball Fight Arena'],
  },
  {
    id: 544,
    type: 'Tasmanian Tiger',
    traits: ['4', 'Cozy Hut', 'Beanie Winter Red', 'Elf Outfit', 'Present'],
  },
  {
    id: 545,
    type: 'Penguin',
    traits: ['5', 'Christmas Markets', 'Glass Egg Nog', 'Christmas Bell'],
  },
  {
    id: 546,
    type: 'Penguin',
    traits: ['6', 'Santas Sled', 'Rosy Cheeks', 'Santas Hat', 'Winter Jacket Purple', 'Gloves Blue', 'Present'],
  },
  {
    id: 547,
    type: 'Quokka',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter White', 'Winter Jacket Red', 'Gloves Red', 'Chocolate Cookie'],
  },
  {
    id: 548,
    type: 'Seal',
    traits: ['8', 'Pile of Presents', 'Beanie Winter Red', 'Glass Egg Nog'],
  },
  {
    id: 549,
    type: 'Tasmanian Devil',
    traits: ['9', 'Toy Factory', 'Ski Goggles', 'Scarf Red', 'Gloves Blue', 'Christmas Bell'],
  },
  {
    id: 550,
    type: 'Platypus',
    traits: ['10', 'Cozy Fireplace', 'Winter Jacket Red', 'Sled', 'Chocolate Cookie'],
  },
  {
    id: 551,
    type: 'Koala',
    traits: ['11', 'Christmas Tree', 'Antlers', 'Skis', 'Christmas Bell'],
  },
  {
    id: 552,
    type: 'Platypus',
    traits: ['12', 'Bondi Beach'],
  },
  {
    id: 553,
    type: 'Quokka',
    traits: ['1', 'Sledding Hill', 'Ear Warmers Pink', 'Ski Pole'],
  },
  {
    id: 554,
    type: 'Wombat',
    traits: ['2', 'Snowman Valley', 'Rosy Cheeks', 'Beanie Winter Blue', 'Santa Outfit', 'Gloves Blue', 'Chocolate Cookie'],
  },
  {
    id: 555,
    type: 'Seal',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter White', 'Winter Jacket Red', 'Snow Shovel'],
  },
  {
    id: 556,
    type: 'Crocodile',
    traits: ['4', 'Cozy Hut', 'Beanie Winter Blue', 'Winter Jacket Purple', 'Skis'],
  },
  {
    id: 557,
    type: 'Octopus',
    traits: ['5', 'Christmas Markets', 'Antlers'],
  },
  {
    id: 558,
    type: 'Crocodile',
    traits: ['6', 'Santas Sled', 'Antlers', 'Winter Jacket Red', 'Cup Hot Chocolate'],
  },
  {
    id: 559,
    type: 'Crocodile',
    traits: ['7', 'Santas Sack n Stable', 'Rosy Cheeks', 'Ear Warmers White', 'Gloves Red', 'Cup Hot Chocolate'],
  },
  {
    id: 560,
    type: 'Platypus',
    traits: ['Pipe', '8', 'Pile of Presents', 'Ear Warmers Pink', 'Scarf Red', 'Gloves Red'],
  },
  {
    id: 561,
    type: 'Crocodile',
    traits: ['9', 'Toy Factory', 'Beanie Winter Blue', 'Chocolate Cookie'],
  },
  {
    id: 562,
    type: 'Quokka',
    traits: ['Pipe', '10', 'Cozy Fireplace', 'Ear Warmers White', 'Glass Egg Nog', 'Chocolate Cookie'],
  },
  {
    id: 563,
    type: 'Quokka',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Blue', 'Winter Jacket Blue', 'Glass Milk'],
  },
  {
    id: 564,
    type: 'Koala',
    traits: ['12', 'Bondi Beach', 'Sunnies', 'Glass Egg Nog'],
  },
  {
    id: 565,
    type: 'Dingo',
    traits: ['1', 'Sledding Hill', 'Elf Hat', 'Cup Hot Chocolate'],
  },
  {
    id: 566,
    type: 'Octopus',
    traits: ['2', 'Snowman Valley', 'Rosy Cheeks', 'Antlers', 'Skis', 'Chocolate Cookie'],
  },
  {
    id: 567,
    type: 'Tasmanian Devil',
    traits: ['3', 'Snowball Fight Arena', 'Antlers', 'Winter Jacket Red', 'Skis', 'Snowball'],
  },
  {
    id: 568,
    type: 'Crocodile',
    traits: ['4', 'Cozy Hut', 'Ski Goggles', 'Gloves Blue', 'Ski Pole'],
  },
  {
    id: 569,
    type: 'Platypus',
    traits: ['5', 'Christmas Markets', 'Elf Outfit', 'Gloves Red', 'Chocolate Cookie'],
  },
  {
    id: 570,
    type: 'Wombat',
    traits: ['6', 'Santas Sled', 'Santa Outfit', 'Snow Shovel'],
  },
  {
    id: 571,
    type: 'Crocodile',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter White', 'Scarf Blue', 'Chocolate Cookie', 'Santas Beard'],
  },
  {
    id: 572,
    type: 'Dingo',
    traits: ['8', 'Pile of Presents', 'Winter Jacket Blue', 'Sled', 'Ski Pole'],
  },
  {
    id: 573,
    type: 'Dingo',
    traits: ['9', 'Toy Factory', 'Beanie Winter White', 'Scarf Red', 'Glass Egg Nog'],
  },
  {
    id: 574,
    type: 'Koala',
    traits: ['Pipe', '10', 'Cozy Fireplace', 'Elf Outfit', 'Chocolate Cookie'],
  },
  {
    id: 575,
    type: 'Seal',
    traits: ['Xmas Laser Eyes', '11', 'Christmas Tree', 'Snow Shovel', 'Christmas Bell'],
  },
  {
    id: 576,
    type: 'Octopus',
    traits: ['12', 'Bondi Beach', 'Sunnies', 'Snow Shovel', 'Chocolate Cookie'],
  },
  {
    id: 577,
    type: 'Dingo',
    traits: ['1', 'Sledding Hill', 'Antlers', 'Scarf Red', 'Snowball'],
  },
  {
    id: 578,
    type: 'Tasmanian Devil',
    traits: ['2', 'Snowman Valley', 'Winter Jacket Red', 'Ski Pole', 'Santas Beard'],
  },
  {
    id: 579,
    type: 'Koala',
    traits: ['3', 'Snowball Fight Arena', 'Rosy Cheeks', 'Antlers'],
  },
  {
    id: 580,
    type: 'Wombat',
    traits: ['Rudolf Nose', '4', 'Cozy Hut', 'Ear Warmers Pink', 'Elf Outfit', 'Snow Shovel', 'Snowball'],
  },
  {
    id: 581,
    type: 'Penguin',
    traits: ['5', 'Christmas Markets', 'Winter Jacket Red', 'Glass Milk', 'Candy Cane'],
  },
  {
    id: 582,
    type: 'Tasmanian Tiger',
    traits: ['6', 'Santas Sled', 'Ski Goggles', 'Ear Warmers Pink', 'Skis'],
  },
  {
    id: 583,
    type: 'Shark',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter Blue', 'Sled', 'Ski Pole'],
  },
  {
    id: 584,
    type: 'Seal',
    traits: ['8', 'Pile of Presents', 'Rosy Cheeks', 'Ear Warmers Red', 'Gloves Red', 'Cup Hot Chocolate'],
  },
  {
    id: 585,
    type: 'Wombat',
    traits: ['9', 'Toy Factory', 'Beanie Winter Red', 'Santa Outfit', 'Candy Cane'],
  },
  {
    id: 586,
    type: 'Dingo',
    traits: ['10', 'Cozy Fireplace', 'Santas Hat', 'Winter Jacket Purple', 'Christmas Bell', 'Santas Beard'],
  },
  {
    id: 587,
    type: 'Crocodile',
    traits: ['11', 'Christmas Tree', 'Elf Outfit', 'Gloves Red'],
  },
  {
    id: 588,
    type: 'Octopus',
    traits: ['12', 'Bondi Beach', 'Rosy Cheeks', 'Glass Milk', 'Chocolate Cookie'],
  },
  {
    id: 589,
    type: 'Dingo',
    traits: ['1', 'Sledding Hill', 'Santas Hat', 'Elf Outfit', 'Christmas Bell'],
  },
  {
    id: 590,
    type: 'Seal',
    traits: ['2', 'Snowman Valley', 'Sled', 'Ski Pole'],
  },
  {
    id: 591,
    type: 'Kangaroo',
    traits: ['3', 'Snowball Fight Arena', 'Scarf Blue', 'Glass Milk'],
  },
  {
    id: 592,
    type: 'Koala',
    traits: ['4', 'Cozy Hut', 'Winter Jacket Red', 'Glass Milk'],
  },
  {
    id: 593,
    type: 'Tasmanian Devil',
    traits: ['5', 'Christmas Markets', 'Beanie Winter Red', 'Winter Jacket Blue', 'Chocolate Cookie'],
  },
  {
    id: 594,
    type: 'Penguin',
    traits: ['6', 'Santas Sled', 'Santas Hat', 'Winter Jacket Blue'],
  },
  {
    id: 595,
    type: 'Platypus',
    traits: ['7', 'Santas Sack n Stable', 'Scarf Red', 'Chocolate Cookie'],
  },
  {
    id: 596,
    type: 'Penguin',
    traits: ['8', 'Pile of Presents', 'Ski Goggles'],
  },
  {
    id: 597,
    type: 'Platypus',
    traits: ['9', 'Toy Factory', 'Beanie Winter White'],
  },
  {
    id: 598,
    type: 'Seal',
    traits: ['10', 'Cozy Fireplace', 'Ski Goggles', 'Winter Jacket Purple', 'Sled'],
  },
  {
    id: 599,
    type: 'Crocodile',
    traits: ['11', 'Christmas Tree', 'Beanie Winter White', 'Gloves Blue', 'Present'],
  },
  {
    id: 600,
    type: 'Tasmanian Tiger',
    traits: ['12', 'Bondi Beach', 'Snow Shovel'],
  },
  {
    id: 601,
    type: 'Koala',
    traits: ['1', 'Sledding Hill', 'Ear Warmers Pink', 'Glass Milk'],
  },
  {
    id: 602,
    type: 'Quokka',
    traits: ['2', 'Snowman Valley', 'Winter Jacket Red', 'Skis'],
  },
  {
    id: 603,
    type: 'Penguin',
    traits: ['Pipe', '3', 'Snowball Fight Arena', 'Bradbury Helmet', 'Winter Jacket Red', 'Snow Shovel', 'Chocolate Cookie'],
  },
  {
    id: 604,
    type: 'Quokka',
    traits: ['4', 'Cozy Hut', 'Sled', 'Christmas Bell'],
  },
  {
    id: 605,
    type: 'Tasmanian Devil',
    traits: ['5', 'Christmas Markets', 'Winter Jacket Blue'],
  },
  {
    id: 606,
    type: 'Wombat',
    traits: ['6', 'Santas Sled', 'Ear Warmers White', 'Glass Egg Nog', 'Snowball'],
  },
  {
    id: 607,
    type: 'Kangaroo',
    traits: ['Rudolf Nose', '7', 'Santas Sack n Stable', 'Santas Hat', 'Gloves Red', 'Ski Pole'],
  },
  {
    id: 608,
    type: 'Quokka',
    traits: ['8', 'Pile of Presents', 'Winter Jacket Blue'],
  },
  {
    id: 609,
    type: 'Koala',
    traits: ['9', 'Toy Factory', 'Rosy Cheeks', 'Elf Outfit', 'Sled', 'Candy Cane'],
  },
  {
    id: 610,
    type: 'Koala',
    traits: ['10', 'Cozy Fireplace', 'Elf Hat', 'Gloves Red', 'Present'],
  },
  {
    id: 611,
    type: 'Tasmanian Tiger',
    traits: ['Pipe', '11', 'Christmas Tree', 'Scarf Blue', 'Glass Milk', 'Snowball'],
  },
  {
    id: 612,
    type: 'Tasmanian Devil',
    traits: ['12', 'Bondi Beach', 'Glass Egg Nog', 'Present'],
  },
  {
    id: 613,
    type: 'Wombat',
    traits: ['Xmas Laser Eyes', '1', 'Sledding Hill', 'Skis'],
  },
  {
    id: 614,
    type: 'Kangaroo',
    traits: ['2', 'Snowman Valley', 'Scarf Blue'],
  },
  {
    id: 615,
    type: 'Kangaroo',
    traits: ['3', 'Snowball Fight Arena', 'Rosy Cheeks', 'Winter Jacket Purple', 'Candle'],
  },
  {
    id: 616,
    type: 'Grinch',
    traits: ['4', 'Cozy Hut', 'Gloves Red', 'Chocolate Cookie'],
  },
  {
    id: 617,
    type: 'Tasmanian Tiger',
    traits: ['5', 'Christmas Markets', 'Antlers', 'Glass Egg Nog', 'Chocolate Cookie'],
  },
  {
    id: 618,
    type: 'Koala',
    traits: ['6', 'Santas Sled', 'Antlers', 'Winter Jacket Blue'],
  },
  {
    id: 619,
    type: 'Platypus',
    traits: ['Pipe', '7', 'Santas Sack n Stable', 'Cup Hot Chocolate'],
  },
  {
    id: 620,
    type: 'Crocodile',
    traits: ['8', 'Pile of Presents', 'Beanie Winter White', 'Skis'],
  },
  {
    id: 621,
    type: 'Quokka',
    traits: ['9', 'Toy Factory', 'Elf Outfit', 'Snow Shovel', 'Chocolate Cookie'],
  },
  {
    id: 622,
    type: 'Tasmanian Tiger',
    traits: ['10', 'Cozy Fireplace', 'Elf Hat', 'Scarf Red', 'Sled', 'Ski Pole'],
  },
  {
    id: 623,
    type: 'Platypus',
    traits: ['Pipe', '11', 'Christmas Tree', 'Winter Jacket Purple', 'Skis'],
  },
  {
    id: 624,
    type: 'Bunyip',
    traits: ['12', 'Bondi Beach', 'Sunnies', 'Skis', 'Present'],
  },
  {
    id: 625,
    type: 'Seal',
    traits: ['1', 'Sledding Hill', 'Winter Jacket Blue', 'Gloves Blue', 'Ski Pole'],
  },
  {
    id: 626,
    type: 'Crocodile',
    traits: ['2', 'Snowman Valley', 'Rosy Cheeks', 'Elf Hat', 'Winter Jacket Purple', 'Cup Hot Chocolate'],
  },
  {
    id: 627,
    type: 'Seal',
    traits: ['3', 'Snowball Fight Arena', 'Rosy Cheeks', 'Scarf Red', 'Gloves Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 628,
    type: 'Crocodile',
    traits: ['4', 'Cozy Hut', 'Santa Outfit', 'Glass Milk', 'Snowball'],
  },
  {
    id: 629,
    type: 'Penguin',
    traits: ['5', 'Christmas Markets', 'Beanie Winter White', 'Elf Outfit', 'Snow Shovel'],
  },
  {
    id: 630,
    type: 'Crocodile',
    traits: ['6', 'Santas Sled', 'Antlers', 'Scarf Red', 'Candy Cane'],
  },
  {
    id: 631,
    type: 'Penguin',
    traits: ['Pipe', '7', 'Santas Sack n Stable', 'Snow Shovel', 'Snowball'],
  },
  {
    id: 632,
    type: 'Penguin',
    traits: ['8', 'Pile of Presents', 'Ear Warmers White', 'Cup Hot Chocolate'],
  },
  {
    id: 633,
    type: 'Tasmanian Devil',
    traits: ['9', 'Toy Factory', 'Winter Jacket Blue', 'Ski Pole'],
  },
  {
    id: 634,
    type: 'Shark',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter Red', 'Winter Jacket Red', 'Glass Milk'],
  },
  {
    id: 635,
    type: 'Penguin',
    traits: ['11', 'Christmas Tree', 'Ski Goggles', 'Scarf Red', 'Gloves Blue', 'Present'],
  },
  {
    id: 636,
    type: 'Kangaroo',
    traits: ['12', 'Bondi Beach', 'Sunnies', 'Glass Milk', 'Candy Cane'],
  },
  {
    id: 637,
    type: 'Tasmanian Devil',
    traits: ['1', 'Sledding Hill', 'Ear Warmers Pink', 'Elf Outfit', 'Gloves Red'],
  },
  {
    id: 638,
    type: 'Quokka',
    traits: ['Pipe', '2', 'Snowman Valley', 'Beanie Winter White', 'Gloves Blue'],
  },
  {
    id: 639,
    type: 'Shark',
    traits: ['3', 'Snowball Fight Arena', 'Santas Hat', 'Sled', 'Candle'],
  },
  {
    id: 640,
    type: 'Koala',
    traits: ['4', 'Cozy Hut', 'Gloves Blue', 'Ski Pole'],
  },
  {
    id: 641,
    type: 'Wombat',
    traits: ['5', 'Christmas Markets', 'Antlers', 'Winter Jacket Blue', 'Snowball'],
  },
  {
    id: 642,
    type: 'Seal',
    traits: ['6', 'Santas Sled', 'Chocolate Cookie'],
  },
  {
    id: 643,
    type: 'Platypus',
    traits: ['7', 'Santas Sack n Stable', 'Rosy Cheeks', 'Gloves Red'],
  },
  {
    id: 644,
    type: 'Koala',
    traits: ['8', 'Pile of Presents', 'Elf Hat', 'Glass Milk', 'Chocolate Cookie'],
  },
  {
    id: 645,
    type: 'Crocodile',
    traits: ['9', 'Toy Factory', 'Sled', 'Chocolate Cookie'],
  },
  {
    id: 646,
    type: 'Tasmanian Devil',
    traits: ['10', 'Cozy Fireplace', 'Elf Hat', 'Winter Jacket Red', 'Gloves Red', 'Ski Pole'],
  },
  {
    id: 647,
    type: 'Kangaroo',
    traits: ['11', 'Christmas Tree', 'Bradbury Helmet', 'Winter Jacket Red', 'Glass Milk', 'Chocolate Cookie'],
  },
  {
    id: 648,
    type: 'Tasmanian Tiger',
    traits: ['12', 'Bondi Beach'],
  },
  {
    id: 649,
    type: 'Penguin',
    traits: ['1', 'Sledding Hill', 'Rosy Cheeks', 'Snowball'],
  },
  {
    id: 650,
    type: 'Quokka',
    traits: ['2', 'Snowman Valley', 'Winter Jacket Red', 'Christmas Bell'],
  },
  {
    id: 651,
    type: 'Kangaroo',
    traits: ['3', 'Snowball Fight Arena', 'Ear Warmers Pink', 'Christmas Bell'],
  },
  {
    id: 652,
    type: 'Tasmanian Tiger',
    traits: ['4', 'Cozy Hut', 'Beanie Winter Blue', 'Scarf Red', 'Snow Shovel', 'Present'],
  },
  {
    id: 653,
    type: 'Quokka',
    traits: ['5', 'Christmas Markets', 'Ear Warmers Red', 'Scarf Blue', 'Gloves Red'],
  },
  {
    id: 654,
    type: 'Tasmanian Devil',
    traits: ['6', 'Santas Sled', 'Winter Jacket Purple', 'Skis'],
  },
  {
    id: 655,
    type: 'Dingo',
    traits: ['7', 'Santas Sack n Stable', 'Winter Jacket Purple', 'Sled', 'Christmas Bell'],
  },
  {
    id: 656,
    type: 'Platypus',
    traits: ['8', 'Pile of Presents', 'Rosy Cheeks', 'Gloves Red', 'Christmas Bell'],
  },
  {
    id: 657,
    type: 'Penguin',
    traits: ['9', 'Toy Factory', 'Ear Warmers Red', 'Elf Outfit'],
  },
  {
    id: 658,
    type: 'Kangaroo',
    traits: ['10', 'Cozy Fireplace', 'Scarf Red', 'Glass Milk'],
  },
  {
    id: 659,
    type: 'Koala',
    traits: ['Xmas Laser Eyes', '11', 'Christmas Tree', 'Beanie Winter White', 'Gloves Blue'],
  },
  {
    id: 660,
    type: 'Platypus',
    traits: ['12', 'Bondi Beach', 'Skis'],
  },
  {
    id: 661,
    type: 'Tasmanian Devil',
    traits: ['1', 'Sledding Hill', 'Scarf Red'],
  },
  {
    id: 662,
    type: 'Platypus',
    traits: ['2', 'Snowman Valley', 'Beanie Winter Red', 'Winter Jacket Purple', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 663,
    type: 'Dingo',
    traits: ['3', 'Snowball Fight Arena', 'Ear Warmers Pink', 'Glass Egg Nog'],
  },
  {
    id: 664,
    type: 'Quokka',
    traits: ['4', 'Cozy Hut', 'Santas Hat', 'Scarf Red', 'Present'],
  },
  {
    id: 665,
    type: 'Quokka',
    traits: ['Pipe', '5', 'Christmas Markets', 'Santas Hat', 'Winter Jacket Purple', 'Cup Hot Chocolate'],
  },
  {
    id: 666,
    type: 'Kangaroo',
    traits: ['6', 'Santas Sled', 'Snow Shovel', 'Snowball'],
  },
  {
    id: 667,
    type: 'Wombat',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter White', 'Winter Jacket Red', 'Gloves Blue', 'Christmas Bell'],
  },
  {
    id: 668,
    type: 'Seal',
    traits: ['8', 'Pile of Presents', 'Ski Goggles', 'Ear Warmers Red', 'Glass Milk', 'Chocolate Cookie'],
  },
  {
    id: 669,
    type: 'Wombat',
    traits: ['9', 'Toy Factory', 'Elf Outfit'],
  },
  {
    id: 670,
    type: 'Seal',
    traits: ['10', 'Cozy Fireplace', 'Ear Warmers White', 'Scarf Blue', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 671,
    type: 'Kangaroo',
    traits: ['11', 'Christmas Tree', 'Rosy Cheeks', 'Scarf Blue', 'Gloves Blue', 'Candy Cane'],
  },
  {
    id: 672,
    type: 'Kangaroo',
    traits: ['12', 'Bondi Beach', 'Snow Shovel', 'Candy Cane'],
  },
  {
    id: 673,
    type: 'Shark',
    traits: ['1', 'Sledding Hill', 'Beanie Winter White', 'Gloves Red', 'Snowball'],
  },
  {
    id: 674,
    type: 'Seal',
    traits: ['2', 'Snowman Valley', 'Beanie Winter Blue', 'Candy Cane'],
  },
  {
    id: 675,
    type: 'Tasmanian Devil',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter Red', 'Elf Outfit', 'Snow Shovel', 'Ski Pole'],
  },
  {
    id: 676,
    type: 'Octopus',
    traits: ['4', 'Cozy Hut', 'Santas Hat', 'Candy Cane'],
  },
  {
    id: 677,
    type: 'Quokka',
    traits: ['5', 'Christmas Markets', 'Scarf Blue', 'Gloves Red'],
  },
  {
    id: 678,
    type: 'Koala',
    traits: ['6', 'Santas Sled', 'Winter Jacket Blue', 'Gloves Blue'],
  },
  {
    id: 679,
    type: 'Kangaroo',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter Blue', 'Winter Jacket Purple', 'Glass Egg Nog', 'Cup Hot Chocolate'],
  },
  {
    id: 680,
    type: 'Platypus',
    traits: ['8', 'Pile of Presents', 'Winter Jacket Purple', 'Candle'],
  },
  {
    id: 681,
    type: 'Dingo',
    traits: ['9', 'Toy Factory', 'Bradbury Helmet', 'Winter Jacket Red', 'Snow Shovel', 'Snowball'],
  },
  {
    id: 682,
    type: 'Dingo',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter Blue', 'Glass Milk'],
  },
  {
    id: 683,
    type: 'Wombat',
    traits: ['11', 'Christmas Tree', 'Winter Jacket Purple', 'Snowball'],
  },
  {
    id: 684,
    type: 'Kangaroo',
    traits: ['12', 'Bondi Beach', 'Sunnies', 'Glass Egg Nog', 'Chocolate Cookie'],
  },
  {
    id: 685,
    type: 'Quokka',
    traits: ['1', 'Sledding Hill', 'Beanie Winter Blue', 'Gloves Red'],
  },
  {
    id: 686,
    type: 'Quokka',
    traits: ['2', 'Snowman Valley', 'Antlers', 'Elf Outfit', 'Gloves Blue'],
  },
  {
    id: 687,
    type: 'Platypus',
    traits: ['3', 'Snowball Fight Arena', 'Ear Warmers White'],
  },
  {
    id: 688,
    type: 'Quokka',
    traits: ['4', 'Cozy Hut', 'Ski Goggles', 'Antlers', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 689,
    type: 'Kangaroo',
    traits: ['5', 'Christmas Markets', 'Scarf Red', 'Cup Hot Chocolate'],
  },
  {
    id: 690,
    type: 'Bunyip',
    traits: ['6', 'Santas Sled', 'Rosy Cheeks', 'Antlers', 'Winter Jacket Blue', 'Sled', 'Cup Hot Chocolate'],
  },
  {
    id: 691,
    type: 'Tasmanian Tiger',
    traits: ['7', 'Santas Sack n Stable', 'Antlers'],
  },
  {
    id: 692,
    type: 'Wombat',
    traits: ['8', 'Pile of Presents', 'Ear Warmers Red', 'Present'],
  },
  {
    id: 693,
    type: 'Tasmanian Devil',
    traits: ['9', 'Toy Factory', 'Glass Egg Nog', 'Christmas Bell'],
  },
  {
    id: 694,
    type: 'Wombat',
    traits: ['10', 'Cozy Fireplace', 'Winter Jacket Purple', 'Gloves Red', 'Cup Hot Chocolate'],
  },
  {
    id: 695,
    type: 'Crocodile',
    traits: ['11', 'Christmas Tree', 'Scarf Blue', 'Christmas Bell'],
  },
  {
    id: 696,
    type: 'Platypus',
    traits: ['12', 'Bondi Beach', 'Sled'],
  },
  {
    id: 697,
    type: 'Kangaroo',
    traits: ['1', 'Sledding Hill', 'Santas Hat', 'Scarf Red', 'Cup Hot Chocolate'],
  },
  {
    id: 698,
    type: 'Tasmanian Devil',
    traits: ['2', 'Snowman Valley', 'Ear Warmers Red', 'Glass Egg Nog', 'Present'],
  },
  {
    id: 699,
    type: 'Penguin',
    traits: ['3', 'Snowball Fight Arena', 'Rosy Cheeks', 'Elf Hat', 'Scarf Red', 'Glass Egg Nog', 'Present'],
  },
  {
    id: 700,
    type: 'Crocodile',
    traits: ['4', 'Cozy Hut', 'Rosy Cheeks', 'Beanie Winter White', 'Winter Jacket Purple'],
  },
  {
    id: 701,
    type: 'Shark',
    traits: ['5', 'Christmas Markets', 'Beanie Winter Red', 'Present'],
  },
  {
    id: 702,
    type: 'Penguin',
    traits: ['6', 'Santas Sled', 'Rosy Cheeks', 'Antlers', 'Scarf Red', 'Candle'],
  },
  {
    id: 703,
    type: 'Quokka',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter Blue', 'Gloves Red'],
  },
  {
    id: 704,
    type: 'Seal',
    traits: ['8', 'Pile of Presents', 'Ski Goggles', 'Scarf Blue', 'Glass Egg Nog', 'Cup Hot Chocolate'],
  },
  {
    id: 705,
    type: 'Shark',
    traits: ['9', 'Toy Factory', 'Beanie Winter Red', 'Winter Jacket Purple', 'Ski Pole', 'Santas Beard'],
  },
  {
    id: 706,
    type: 'Penguin',
    traits: ['10', 'Cozy Fireplace', 'Rosy Cheeks', 'Ear Warmers Pink', 'Scarf Red', 'Skis', 'Christmas Bell'],
  },
  {
    id: 707,
    type: 'Dingo',
    traits: ['11', 'Christmas Tree', 'Scarf Blue', 'Glass Milk', 'Chocolate Cookie'],
  },
  {
    id: 708,
    type: 'Bunyip',
    traits: ['12', 'Bondi Beach', 'Glass Egg Nog', 'Present'],
  },
  {
    id: 709,
    type: 'Penguin',
    traits: ['1', 'Sledding Hill', 'Ski Goggles', 'Winter Jacket Red', 'Snow Shovel', 'Ski Pole'],
  },
  {
    id: 710,
    type: 'Platypus',
    traits: ['2', 'Snowman Valley', 'Scarf Red', 'Gloves Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 711,
    type: 'Koala',
    traits: ['3', 'Snowball Fight Arena', 'Rosy Cheeks', 'Scarf Blue', 'Snow Shovel', 'Chocolate Cookie'],
  },
  {
    id: 712,
    type: 'Wombat',
    traits: ['4', 'Cozy Hut', 'Rosy Cheeks', 'Ski Goggles', 'Ear Warmers Red', 'Elf Outfit', 'Gloves Blue', 'Ski Pole'],
  },
  {
    id: 713,
    type: 'Tasmanian Devil',
    traits: ['Pipe', '5', 'Christmas Markets', 'Ear Warmers Pink', 'Skis', 'Ski Pole'],
  },
  {
    id: 714,
    type: 'Dingo',
    traits: ['6', 'Santas Sled', 'Rosy Cheeks', 'Ear Warmers White', 'Ski Pole'],
  },
  {
    id: 715,
    type: 'Tasmanian Devil',
    traits: ['7', 'Santas Sack n Stable', 'Sled', 'Christmas Bell'],
  },
  {
    id: 716,
    type: 'Shark',
    traits: ['8', 'Pile of Presents', 'Rosy Cheeks', 'Beanie Winter White', 'Scarf Blue', 'Sled', 'Candy Cane'],
  },
  {
    id: 717,
    type: 'Quokka',
    traits: ['9', 'Toy Factory', 'Ear Warmers Red', 'Elf Outfit', 'Gloves Blue', 'Candy Cane'],
  },
  {
    id: 718,
    type: 'Kangaroo',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter White', 'Sled', 'Chocolate Cookie'],
  },
  {
    id: 719,
    type: 'Platypus',
    traits: ['11', 'Christmas Tree', 'Scarf Blue', 'Glass Egg Nog'],
  },
  {
    id: 720,
    type: 'Dingo',
    traits: ['12', 'Bondi Beach', 'Cup Hot Chocolate'],
  },
  {
    id: 721,
    type: 'Shark',
    traits: ['1', 'Sledding Hill', 'Beanie Winter Blue', 'Winter Jacket Purple', 'Glass Egg Nog'],
  },
  {
    id: 722,
    type: 'Koala',
    traits: ['2', 'Snowman Valley', 'Antlers', 'Scarf Red', 'Glass Egg Nog', 'Snowball'],
  },
  {
    id: 723,
    type: 'Shark',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter Blue', 'Scarf Blue', 'Glass Milk', 'Chocolate Cookie'],
  },
  {
    id: 724,
    type: 'Crocodile',
    traits: ['4', 'Cozy Hut', 'Rosy Cheeks'],
  },
  {
    id: 725,
    type: 'Shark',
    traits: ['5', 'Christmas Markets', 'Scarf Blue', 'Gloves Red', 'Cup Hot Chocolate'],
  },
  {
    id: 726,
    type: 'Wombat',
    traits: ['6', 'Santas Sled', 'Beanie Winter Blue', 'Winter Jacket Purple', 'Sled', 'Snowball'],
  },
  {
    id: 727,
    type: 'Quokka',
    traits: ['7', 'Santas Sack n Stable', 'Ski Goggles', 'Winter Jacket Purple', 'Gloves Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 728,
    type: 'Crocodile',
    traits: ['8', 'Pile of Presents', 'Ear Warmers White', 'Elf Outfit', 'Snow Shovel', 'Christmas Bell'],
  },
  {
    id: 729,
    type: 'Crocodile',
    traits: ['9', 'Toy Factory', 'Beanie Winter White', 'Winter Jacket Blue', 'Skis', 'Candle'],
  },
  {
    id: 730,
    type: 'Penguin',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter White', 'Gloves Red'],
  },
  {
    id: 731,
    type: 'Seal',
    traits: ['11', 'Christmas Tree', 'Skis', 'Cup Hot Chocolate'],
  },
  {
    id: 732,
    type: 'Drop Bear',
    traits: ['12', 'Bondi Beach', 'Cup Hot Chocolate'],
  },
  {
    id: 733,
    type: 'Platypus',
    traits: ['1', 'Sledding Hill', 'Bradbury Helmet', 'Scarf Blue', 'Gloves Red'],
  },
  {
    id: 734,
    type: 'Tasmanian Devil',
    traits: ['2', 'Snowman Valley', 'Sled'],
  },
  {
    id: 735,
    type: 'Seal',
    traits: ['3', 'Snowball Fight Arena', 'Scarf Blue', 'Christmas Bell', 'Santas Beard'],
  },
  {
    id: 736,
    type: 'Tasmanian Tiger',
    traits: ['4', 'Cozy Hut', 'Scarf Blue', 'Gloves Red', 'Snowball'],
  },
  {
    id: 737,
    type: 'Drop Bear',
    traits: ['5', 'Christmas Markets', 'Ear Warmers White', 'Sled', 'Christmas Bell'],
  },
  {
    id: 738,
    type: 'Tasmanian Devil',
    traits: ['6', 'Santas Sled', 'Winter Jacket Red', 'Snow Shovel'],
  },
  {
    id: 739,
    type: 'Penguin',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter Blue', 'Santa Outfit', 'Snow Shovel', 'Candle'],
  },
  {
    id: 740,
    type: 'Penguin',
    traits: ['8', 'Pile of Presents', 'Rosy Cheeks', 'Beanie Winter White', 'Ski Pole'],
  },
  {
    id: 741,
    type: 'Tasmanian Devil',
    traits: ['9', 'Toy Factory', 'Rosy Cheeks', 'Snow Shovel', 'Christmas Bell'],
  },
  {
    id: 742,
    type: 'Dingo',
    traits: ['Xmas Laser Eyes', '10', 'Cozy Fireplace', 'Antlers', 'Scarf Red', 'Sled'],
  },
  {
    id: 743,
    type: 'Tasmanian Tiger',
    traits: ['11', 'Christmas Tree', 'Ski Pole'],
  },
  {
    id: 744,
    type: 'Koala',
    traits: ['12', 'Bondi Beach', 'Sunnies', 'Christmas Bell'],
  },
  {
    id: 745,
    type: 'Crocodile',
    traits: ['1', 'Sledding Hill', 'Bradbury Helmet', 'Gloves Blue', 'Ski Pole'],
  },
  {
    id: 746,
    type: 'Crocodile',
    traits: ['2', 'Snowman Valley', 'Gloves Red'],
  },
  {
    id: 747,
    type: 'Platypus',
    traits: ['3', 'Snowball Fight Arena', 'Rosy Cheeks', 'Beanie Winter White', 'Scarf Red', 'Glass Egg Nog'],
  },
  {
    id: 748,
    type: 'Penguin',
    traits: ['4', 'Cozy Hut', 'Ear Warmers White', 'Scarf Red', 'Gloves Red', 'Cup Hot Chocolate'],
  },
  {
    id: 749,
    type: 'Koala',
    traits: ['5', 'Christmas Markets', 'Ear Warmers Red', 'Candy Cane'],
  },
  {
    id: 750,
    type: 'Crocodile',
    traits: ['6', 'Santas Sled', 'Antlers', 'Gloves Red'],
  },
  {
    id: 751,
    type: 'Dingo',
    traits: ['7', 'Santas Sack n Stable', 'Rosy Cheeks', 'Antlers', 'Scarf Red'],
  },
  {
    id: 752,
    type: 'Wombat',
    traits: ['8', 'Pile of Presents', 'Beanie Winter Blue', 'Winter Jacket Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 753,
    type: 'Bunyip',
    traits: ['Rudolf Nose', '9', 'Toy Factory', 'Winter Jacket Purple', 'Sled', 'Candy Cane'],
  },
  {
    id: 754,
    type: 'Quokka',
    traits: ['10', 'Cozy Fireplace', 'Rosy Cheeks', 'Winter Jacket Red'],
  },
  {
    id: 755,
    type: 'Penguin',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Red', 'Winter Jacket Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 756,
    type: 'Tasmanian Devil',
    traits: ['12', 'Bondi Beach', 'Glass Milk', 'Ski Pole'],
  },
  {
    id: 757,
    type: 'Koala',
    traits: ['1', 'Sledding Hill', 'Beanie Winter White'],
  },
  {
    id: 758,
    type: 'Tasmanian Devil',
    traits: ['2', 'Snowman Valley', 'Beanie Winter Red', 'Glass Egg Nog'],
  },
  {
    id: 759,
    type: 'Seal',
    traits: ['3', 'Snowball Fight Arena', 'Winter Jacket Blue', 'Snowball'],
  },
  {
    id: 760,
    type: 'Quokka',
    traits: ['4', 'Cozy Hut', 'Beanie Winter Red', 'Glass Egg Nog', 'Ski Pole'],
  },
  {
    id: 761,
    type: 'Penguin',
    traits: ['5', 'Christmas Markets', 'Beanie Winter Blue', 'Candy Cane'],
  },
  {
    id: 762,
    type: 'Tasmanian Devil',
    traits: ['Xmas Laser Eyes', '6', 'Santas Sled', 'Beanie Winter White', 'Cup Hot Chocolate'],
  },
  {
    id: 763,
    type: 'Tasmanian Devil',
    traits: ['7', 'Santas Sack n Stable', 'Bradbury Helmet', 'Gloves Blue', 'Ski Pole'],
  },
  {
    id: 764,
    type: 'Penguin',
    traits: ['8', 'Pile of Presents', 'Rosy Cheeks', 'Elf Hat', 'Santa Outfit', 'Snowball'],
  },
  {
    id: 765,
    type: 'Octopus',
    traits: ['9', 'Toy Factory', 'Ear Warmers Pink', 'Skis', 'Present'],
  },
  {
    id: 766,
    type: 'Tasmanian Tiger',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter White', 'Candle'],
  },
  {
    id: 767,
    type: 'Platypus',
    traits: ['11', 'Christmas Tree', 'Scarf Blue', 'Glass Milk', 'Chocolate Cookie'],
  },
  {
    id: 768,
    type: 'Octopus',
    traits: ['12', 'Bondi Beach', 'Sled', 'Candy Cane'],
  },
  {
    id: 769,
    type: 'Quokka',
    traits: ['1', 'Sledding Hill', 'Scarf Blue', 'Gloves Blue'],
  },
  {
    id: 770,
    type: 'Tasmanian Tiger',
    traits: ['2', 'Snowman Valley', 'Elf Hat', 'Winter Jacket Blue', 'Gloves Red', 'Christmas Bell'],
  },
  {
    id: 771,
    type: 'Penguin',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter Blue', 'Snow Shovel'],
  },
  {
    id: 772,
    type: 'Bunyip',
    traits: ['4', 'Cozy Hut', 'Ear Warmers White', 'Glass Egg Nog', 'Candy Cane'],
  },
  {
    id: 773,
    type: 'Tasmanian Devil',
    traits: ['5', 'Christmas Markets', 'Rosy Cheeks', 'Elf Outfit'],
  },
  {
    id: 774,
    type: 'Crocodile',
    traits: ['6', 'Santas Sled', 'Beanie Winter Blue', 'Santa Outfit', 'Glass Milk', 'Candle'],
  },
  {
    id: 775,
    type: 'Tasmanian Devil',
    traits: ['7', 'Santas Sack n Stable', 'Rosy Cheeks', 'Beanie Winter Blue', 'Santa Outfit'],
  },
  {
    id: 776,
    type: 'Koala',
    traits: ['Xmas Laser Eyes', '8', 'Pile of Presents', 'Scarf Blue', 'Skis'],
  },
  {
    id: 777,
    type: 'Quokka',
    traits: ['9', 'Toy Factory', 'Rosy Cheeks', 'Beanie Winter Red', 'Gloves Blue', 'Ski Pole'],
  },
  {
    id: 778,
    type: 'Koala',
    traits: ['10', 'Cozy Fireplace', 'Ski Goggles', 'Antlers', 'Glass Egg Nog'],
  },
  {
    id: 779,
    type: 'Wombat',
    traits: ['11', 'Christmas Tree', 'Antlers', 'Snow Shovel', 'Snowball'],
  },
  {
    id: 780,
    type: 'Koala',
    traits: ['12', 'Bondi Beach', 'Chocolate Cookie'],
  },
  {
    id: 781,
    type: 'Tasmanian Devil',
    traits: ['1', 'Sledding Hill', 'Ski Goggles', 'Elf Outfit', 'Glass Egg Nog', 'Snowball'],
  },
  {
    id: 782,
    type: 'Dingo',
    traits: ['2', 'Snowman Valley', 'Ski Goggles', 'Antlers', 'Scarf Red', 'Glass Milk'],
  },
  {
    id: 783,
    type: 'Shark',
    traits: ['3', 'Snowball Fight Arena', 'Ear Warmers Pink', 'Glass Milk'],
  },
  {
    id: 784,
    type: 'Octopus',
    traits: ['4', 'Cozy Hut', 'Scarf Blue', 'Sled', 'Present'],
  },
  {
    id: 785,
    type: 'Seal',
    traits: ['5', 'Christmas Markets', 'Ear Warmers White', 'Cup Hot Chocolate'],
  },
  {
    id: 786,
    type: 'Koala',
    traits: ['6', 'Santas Sled', 'Present'],
  },
  {
    id: 787,
    type: 'Wombat',
    traits: ['Xmas Laser Eyes', '7', 'Santas Sack n Stable', 'Cup Hot Chocolate'],
  },
  {
    id: 788,
    type: 'Tasmanian Tiger',
    traits: ['8', 'Pile of Presents', 'Rosy Cheeks', 'Beanie Winter White', 'Glass Milk', 'Chocolate Cookie'],
  },
  {
    id: 789,
    type: 'Shark',
    traits: ['9', 'Toy Factory', 'Gloves Blue', 'Snowball'],
  },
  {
    id: 790,
    type: 'Kangaroo',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter Blue', 'Gloves Blue'],
  },
  {
    id: 791,
    type: 'Shark',
    traits: ['11', 'Christmas Tree', 'Beanie Winter White', 'Scarf Blue', 'Sled'],
  },
  {
    id: 792,
    type: 'Grinch',
    traits: ['12', 'Bondi Beach', 'Cup Hot Chocolate'],
  },
  {
    id: 793,
    type: 'Penguin',
    traits: ['1', 'Sledding Hill', 'Beanie Winter White', 'Scarf Red', 'Gloves Red', 'Present'],
  },
  {
    id: 794,
    type: 'Koala',
    traits: ['Pipe', '2', 'Snowman Valley', 'Beanie Winter Red', 'Winter Jacket Blue', 'Skis', 'Chocolate Cookie'],
  },
  {
    id: 795,
    type: 'Crocodile',
    traits: ['3', 'Snowball Fight Arena', 'Rosy Cheeks', 'Elf Outfit'],
  },
  {
    id: 796,
    type: 'Octopus',
    traits: ['4', 'Cozy Hut', 'Rosy Cheeks', 'Ski Goggles', 'Candle'],
  },
  {
    id: 797,
    type: 'Drop Bear',
    traits: ['5', 'Christmas Markets', 'Scarf Blue', 'Snowball'],
  },
  {
    id: 798,
    type: 'Dingo',
    traits: ['6', 'Santas Sled', 'Winter Jacket Purple', 'Christmas Bell'],
  },
  {
    id: 799,
    type: 'Platypus',
    traits: ['7', 'Santas Sack n Stable', 'Bradbury Helmet', 'Scarf Red', 'Gloves Red'],
  },
  {
    id: 800,
    type: 'Koala',
    traits: ['8', 'Pile of Presents', 'Elf Outfit', 'Skis', 'Cup Hot Chocolate'],
  },
  {
    id: 801,
    type: 'Wombat',
    traits: ['9', 'Toy Factory', 'Scarf Red'],
  },
  {
    id: 802,
    type: 'Tasmanian Tiger',
    traits: ['10', 'Cozy Fireplace', 'Elf Hat', 'Santa Outfit', 'Ski Pole'],
  },
  {
    id: 803,
    type: 'Tasmanian Tiger',
    traits: ['11', 'Christmas Tree', 'Rosy Cheeks', 'Beanie Winter Blue', 'Winter Jacket Red', 'Glass Milk', 'Snowball'],
  },
  {
    id: 804,
    type: 'Koala',
    traits: ['12', 'Bondi Beach', 'Glass Egg Nog', 'Present'],
  },
  {
    id: 805,
    type: 'Tasmanian Devil',
    traits: ['1', 'Sledding Hill', 'Ski Goggles', 'Ear Warmers White', 'Gloves Red'],
  },
  {
    id: 806,
    type: 'Wombat',
    traits: ['2', 'Snowman Valley', 'Ear Warmers Pink', 'Candy Cane'],
  },
  {
    id: 807,
    type: 'Kangaroo',
    traits: ['3', 'Snowball Fight Arena', 'Winter Jacket Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 808,
    type: 'Crocodile',
    traits: ['4', 'Cozy Hut', 'Antlers', 'Glass Egg Nog', 'Chocolate Cookie'],
  },
  {
    id: 809,
    type: 'Wombat',
    traits: ['Xmas Laser Eyes', '5', 'Christmas Markets', 'Rosy Cheeks', 'Ear Warmers White', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 810,
    type: 'Seal',
    traits: ['6', 'Santas Sled', 'Rosy Cheeks', 'Candle'],
  },
  {
    id: 811,
    type: 'Quokka',
    traits: ['7', 'Santas Sack n Stable', 'Scarf Red', 'Present'],
  },
  {
    id: 812,
    type: 'Drop Bear',
    traits: ['8', 'Pile of Presents', 'Beanie Winter Blue', 'Scarf Blue', 'Glass Milk', 'Candy Cane'],
  },
  {
    id: 813,
    type: 'Wombat',
    traits: ['9', 'Toy Factory', 'Winter Jacket Red', 'Gloves Blue', 'Snowball'],
  },
  {
    id: 814,
    type: 'Koala',
    traits: ['10', 'Cozy Fireplace', 'Elf Hat', 'Scarf Red', 'Cup Hot Chocolate'],
  },
  {
    id: 815,
    type: 'Tasmanian Devil',
    traits: ['11', 'Christmas Tree', 'Beanie Winter White', 'Scarf Red'],
  },
  {
    id: 816,
    type: 'Seal',
    traits: ['12', 'Bondi Beach', 'Sunnies', 'Sled'],
  },
  {
    id: 817,
    type: 'Penguin',
    traits: ['1', 'Sledding Hill', 'Rosy Cheeks', 'Antlers', 'Winter Jacket Red', 'Chocolate Cookie'],
  },
  {
    id: 818,
    type: 'Platypus',
    traits: ['2', 'Snowman Valley', 'Antlers', 'Winter Jacket Red', 'Snow Shovel', 'Ski Pole'],
  },
  {
    id: 819,
    type: 'Drop Bear',
    traits: ['3', 'Snowball Fight Arena', 'Rosy Cheeks', 'Elf Hat', 'Scarf Red', 'Gloves Blue'],
  },
  {
    id: 820,
    type: 'Shark',
    traits: ['4', 'Cozy Hut', 'Beanie Winter White', 'Winter Jacket Blue', 'Snow Shovel', 'Snowball'],
  },
  {
    id: 821,
    type: 'Drop Bear',
    traits: ['Pipe', '5', 'Christmas Markets', 'Glass Milk', 'Ski Pole'],
  },
  {
    id: 822,
    type: 'Shark',
    traits: ['6', 'Santas Sled', 'Ski Goggles', 'Winter Jacket Purple', 'Gloves Blue', 'Christmas Bell'],
  },
  {
    id: 823,
    type: 'Penguin',
    traits: ['7', 'Santas Sack n Stable', 'Winter Jacket Red', 'Sled'],
  },
  {
    id: 824,
    type: 'Octopus',
    traits: ['8', 'Pile of Presents', 'Glass Milk', 'Chocolate Cookie'],
  },
  {
    id: 825,
    type: 'Platypus',
    traits: ['9', 'Toy Factory', 'Scarf Red'],
  },
  {
    id: 826,
    type: 'Seal',
    traits: ['10', 'Cozy Fireplace', 'Santas Hat', 'Scarf Blue', 'Chocolate Cookie'],
  },
  {
    id: 827,
    type: 'Platypus',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Red', 'Elf Outfit', 'Gloves Red', 'Ski Pole'],
  },
  {
    id: 828,
    type: 'Octopus',
    traits: ['12', 'Bondi Beach', 'Rosy Cheeks', 'Skis', 'Cup Hot Chocolate'],
  },
  {
    id: 829,
    type: 'Shark',
    traits: ['1', 'Sledding Hill', 'Winter Jacket Red', 'Snow Shovel'],
  },
  {
    id: 830,
    type: 'Bunyip',
    traits: ['2', 'Snowman Valley', 'Rosy Cheeks', 'Santas Hat', 'Scarf Red', 'Glass Egg Nog', 'Ski Pole'],
  },
  {
    id: 831,
    type: 'Tasmanian Tiger',
    traits: ['3', 'Snowball Fight Arena', 'Rosy Cheeks', 'Sled'],
  },
  {
    id: 832,
    type: 'Kangaroo',
    traits: ['4', 'Cozy Hut', 'Rosy Cheeks', 'Ear Warmers Red', 'Scarf Red', 'Sled'],
  },
  {
    id: 833,
    type: 'Quokka',
    traits: ['5', 'Christmas Markets', 'Winter Jacket Purple', 'Skis', 'Chocolate Cookie'],
  },
  {
    id: 834,
    type: 'Quokka',
    traits: ['6', 'Santas Sled', 'Ear Warmers White', 'Scarf Blue'],
  },
  {
    id: 835,
    type: 'Tasmanian Tiger',
    traits: ['7', 'Santas Sack n Stable', 'Santas Hat', 'Scarf Red', 'Snow Shovel', 'Chocolate Cookie'],
  },
  {
    id: 836,
    type: 'Dingo',
    traits: ['8', 'Pile of Presents', 'Ear Warmers Red', 'Gloves Blue', 'Present'],
  },
  {
    id: 837,
    type: 'Quokka',
    traits: ['9', 'Toy Factory', 'Rosy Cheeks', 'Snow Shovel'],
  },
  {
    id: 838,
    type: 'Penguin',
    traits: ['10', 'Cozy Fireplace', 'Elf Outfit', 'Glass Milk'],
  },
  {
    id: 839,
    type: 'Platypus',
    traits: ['11', 'Christmas Tree', 'Ear Warmers Pink', 'Elf Outfit', 'Ski Pole'],
  },
  {
    id: 840,
    type: 'Dingo',
    traits: ['12', 'Bondi Beach', 'Present'],
  },
  {
    id: 841,
    type: 'Platypus',
    traits: ['1', 'Sledding Hill', 'Bradbury Helmet', 'Gloves Red'],
  },
  {
    id: 842,
    type: 'Penguin',
    traits: ['2', 'Snowman Valley', 'Beanie Winter White', 'Winter Jacket Red', 'Chocolate Cookie'],
  },
  {
    id: 843,
    type: 'Quokka',
    traits: ['Xmas Laser Eyes', '3', 'Snowball Fight Arena', 'Beanie Winter White', 'Glass Milk', 'Present'],
  },
  {
    id: 844,
    type: 'Shark',
    traits: ['4', 'Cozy Hut', 'Antlers', 'Cup Hot Chocolate', 'Santas Beard'],
  },
  {
    id: 845,
    type: 'Dingo',
    traits: ['5', 'Christmas Markets', 'Winter Jacket Blue', 'Christmas Bell'],
  },
  {
    id: 846,
    type: 'Quokka',
    traits: ['6', 'Santas Sled', 'Rosy Cheeks', 'Winter Jacket Red', 'Snow Shovel', 'Candy Cane'],
  },
  {
    id: 847,
    type: 'Tasmanian Devil',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter Blue', 'Scarf Red', 'Gloves Blue', 'Christmas Bell'],
  },
  {
    id: 848,
    type: 'Koala',
    traits: ['8', 'Pile of Presents', 'Scarf Red'],
  },
  {
    id: 849,
    type: 'Platypus',
    traits: ['9', 'Toy Factory', 'Beanie Winter White', 'Winter Jacket Blue', 'Candy Cane'],
  },
  {
    id: 850,
    type: 'Dingo',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter White', 'Santa Outfit', 'Glass Egg Nog'],
  },
  {
    id: 851,
    type: 'Wombat',
    traits: ['11', 'Christmas Tree', 'Winter Jacket Red', 'Cup Hot Chocolate'],
  },
  {
    id: 852,
    type: 'Grinch',
    traits: ['12', 'Bondi Beach', 'Glass Egg Nog', 'Cup Hot Chocolate'],
  },
  {
    id: 853,
    type: 'Quokka',
    traits: ['1', 'Sledding Hill', 'Winter Jacket Blue', 'Gloves Red', 'Cup Hot Chocolate'],
  },
  {
    id: 854,
    type: 'Koala',
    traits: ['2', 'Snowman Valley', 'Ski Goggles', 'Ear Warmers Red', 'Glass Milk', 'Chocolate Cookie'],
  },
  {
    id: 855,
    type: 'Penguin',
    traits: ['3', 'Snowball Fight Arena', 'Rosy Cheeks', 'Antlers', 'Winter Jacket Purple'],
  },
  {
    id: 856,
    type: 'Penguin',
    traits: ['4', 'Cozy Hut', 'Rosy Cheeks', 'Scarf Blue', 'Glass Egg Nog', 'Christmas Bell'],
  },
  {
    id: 857,
    type: 'Koala',
    traits: ['5', 'Christmas Markets', 'Ear Warmers Pink', 'Gloves Blue'],
  },
  {
    id: 858,
    type: 'Dingo',
    traits: ['6', 'Santas Sled', 'Glass Egg Nog'],
  },
  {
    id: 859,
    type: 'Koala',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter Red', 'Scarf Red', 'Glass Milk', 'Present'],
  },
  {
    id: 860,
    type: 'Octopus',
    traits: ['8', 'Pile of Presents', 'Beanie Winter Red', 'Scarf Red', 'Cup Hot Chocolate'],
  },
  {
    id: 861,
    type: 'Wombat',
    traits: ['9', 'Toy Factory', 'Santa Outfit', 'Chocolate Cookie', 'Santas Beard'],
  },
  {
    id: 862,
    type: 'Quokka',
    traits: ['10', 'Cozy Fireplace', 'Scarf Red'],
  },
  {
    id: 863,
    type: 'Dingo',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Red', 'Scarf Blue', 'Present'],
  },
  {
    id: 864,
    type: 'Crocodile',
    traits: ['12', 'Bondi Beach', 'Glass Egg Nog', 'Christmas Bell'],
  },
  {
    id: 865,
    type: 'Octopus',
    traits: ['1', 'Sledding Hill', 'Antlers', 'Skis', 'Snowball'],
  },
  {
    id: 866,
    type: 'Penguin',
    traits: ['Pipe', '2', 'Snowman Valley', 'Scarf Blue', 'Candy Cane'],
  },
  {
    id: 867,
    type: 'Drop Bear',
    traits: ['3', 'Snowball Fight Arena', 'Elf Outfit'],
  },
  {
    id: 868,
    type: 'Penguin',
    traits: ['4', 'Cozy Hut', 'Gloves Red', 'Present'],
  },
  {
    id: 869,
    type: 'Dingo',
    traits: ['5', 'Christmas Markets', 'Ear Warmers Pink', 'Gloves Blue'],
  },
  {
    id: 870,
    type: 'Kangaroo',
    traits: ['6', 'Santas Sled', 'Antlers', 'Winter Jacket Purple'],
  },
  {
    id: 871,
    type: 'Dingo',
    traits: ['7', 'Santas Sack n Stable', 'Elf Hat', 'Santa Outfit', 'Gloves Blue'],
  },
  {
    id: 872,
    type: 'Crocodile',
    traits: ['8', 'Pile of Presents', 'Beanie Winter Red', 'Winter Jacket Red', 'Glass Egg Nog', 'Candy Cane'],
  },
  {
    id: 873,
    type: 'Wombat',
    traits: ['9', 'Toy Factory', 'Santa Outfit', 'Gloves Blue', 'Present'],
  },
  {
    id: 874,
    type: 'Seal',
    traits: ['10', 'Cozy Fireplace', 'Gloves Red'],
  },
  {
    id: 875,
    type: 'Octopus',
    traits: ['11', 'Christmas Tree', 'Elf Hat', 'Glass Egg Nog'],
  },
  {
    id: 876,
    type: 'Tasmanian Devil',
    traits: ['12', 'Bondi Beach', 'Skis', 'Cup Hot Chocolate'],
  },
  {
    id: 877,
    type: 'Penguin',
    traits: ['1', 'Sledding Hill', 'Scarf Blue'],
  },
  {
    id: 878,
    type: 'Tasmanian Devil',
    traits: ['2', 'Snowman Valley', 'Ski Goggles', 'Ear Warmers White', 'Scarf Red', 'Gloves Red', 'Christmas Bell'],
  },
  {
    id: 879,
    type: 'Kangaroo',
    traits: ['Xmas Laser Eyes', '3', 'Snowball Fight Arena', 'Winter Jacket Blue', 'Glass Egg Nog', 'Ski Pole'],
  },
  {
    id: 880,
    type: 'Shark',
    traits: ['Xmas Laser Eyes', '4', 'Cozy Hut', 'Ear Warmers White', 'Chocolate Cookie'],
  },
  {
    id: 881,
    type: 'Shark',
    traits: ['5', 'Christmas Markets', 'Beanie Winter Blue', 'Winter Jacket Purple', 'Skis', 'Ski Pole'],
  },
  {
    id: 882,
    type: 'Koala',
    traits: ['6', 'Santas Sled', 'Ski Goggles', 'Winter Jacket Blue', 'Snow Shovel', 'Christmas Bell'],
  },
  {
    id: 883,
    type: 'Shark',
    traits: ['7', 'Santas Sack n Stable', 'Scarf Red', 'Skis', 'Candy Cane'],
  },
  {
    id: 884,
    type: 'Drop Bear',
    traits: ['8', 'Pile of Presents', 'Scarf Red', 'Christmas Bell', 'Santas Beard'],
  },
  {
    id: 885,
    type: 'Quokka',
    traits: ['9', 'Toy Factory', 'Ski Goggles', 'Christmas Bell'],
  },
  {
    id: 886,
    type: 'Crocodile',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter Red', 'Glass Egg Nog', 'Cup Hot Chocolate'],
  },
  {
    id: 887,
    type: 'Shark',
    traits: ['11', 'Christmas Tree', 'Skis', 'Ski Pole'],
  },
  {
    id: 888,
    type: 'Wombat',
    traits: ['12', 'Bondi Beach', 'Rosy Cheeks', 'Ski Pole'],
  },
  {
    id: 889,
    type: 'Platypus',
    traits: ['Pipe', '1', 'Sledding Hill', 'Skis'],
  },
  {
    id: 890,
    type: 'Dingo',
    traits: ['2', 'Snowman Valley', 'Ear Warmers Pink', 'Elf Outfit', 'Candle'],
  },
  {
    id: 891,
    type: 'Platypus',
    traits: ['Xmas Laser Eyes', '3', 'Snowball Fight Arena', 'Winter Jacket Purple', 'Glass Egg Nog', 'Chocolate Cookie'],
  },
  {
    id: 892,
    type: 'Shark',
    traits: ['4', 'Cozy Hut', 'Ski Goggles', 'Winter Jacket Purple', 'Skis', 'Snowball'],
  },
  {
    id: 893,
    type: 'Crocodile',
    traits: ['5', 'Christmas Markets', 'Ear Warmers Red', 'Gloves Blue'],
  },
  {
    id: 894,
    type: 'Penguin',
    traits: ['6', 'Santas Sled', 'Ski Goggles', 'Santa Outfit', 'Present'],
  },
  {
    id: 895,
    type: 'Penguin',
    traits: ['7', 'Santas Sack n Stable', 'Ear Warmers White'],
  },
  {
    id: 896,
    type: 'Quokka',
    traits: ['8', 'Pile of Presents', 'Beanie Winter Red', 'Scarf Red', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 897,
    type: 'Grinch',
    traits: ['9', 'Toy Factory', 'Ski Pole'],
  },
  {
    id: 898,
    type: 'Platypus',
    traits: ['10', 'Cozy Fireplace', 'Rosy Cheeks', 'Ear Warmers White', 'Scarf Red', 'Glass Milk', 'Ski Pole'],
  },
  {
    id: 899,
    type: 'Platypus',
    traits: ['11', 'Christmas Tree', 'Bradbury Helmet', 'Scarf Red'],
  },
  {
    id: 900,
    type: 'Platypus',
    traits: ['12', 'Bondi Beach', 'Rosy Cheeks', 'Glass Milk', 'Chocolate Cookie'],
  },
  {
    id: 901,
    type: 'Tasmanian Tiger',
    traits: ['1', 'Sledding Hill', 'Antlers', 'Scarf Red', 'Glass Egg Nog'],
  },
  {
    id: 902,
    type: 'Penguin',
    traits: ['2', 'Snowman Valley', 'Rosy Cheeks', 'Beanie Winter Red', 'Glass Egg Nog'],
  },
  {
    id: 903,
    type: 'Quokka',
    traits: ['3', 'Snowball Fight Arena', 'Scarf Blue', 'Gloves Blue', 'Chocolate Cookie'],
  },
  {
    id: 904,
    type: 'Penguin',
    traits: ['4', 'Cozy Hut', 'Ear Warmers White', 'Cup Hot Chocolate'],
  },
  {
    id: 905,
    type: 'Crocodile',
    traits: ['5', 'Christmas Markets', 'Beanie Winter Red', 'Winter Jacket Red', 'Gloves Red'],
  },
  {
    id: 906,
    type: 'Seal',
    traits: ['6', 'Santas Sled', 'Beanie Winter White', 'Ski Pole'],
  },
  {
    id: 907,
    type: 'Koala',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter White', 'Winter Jacket Purple', 'Sled', 'Christmas Bell'],
  },
  {
    id: 908,
    type: 'Seal',
    traits: ['8', 'Pile of Presents', 'Bradbury Helmet', 'Winter Jacket Purple'],
  },
  {
    id: 909,
    type: 'Octopus',
    traits: ['9', 'Toy Factory', 'Snow Shovel', 'Present'],
  },
  {
    id: 910,
    type: 'Platypus',
    traits: ['10', 'Cozy Fireplace', 'Skis', 'Ski Pole'],
  },
  {
    id: 911,
    type: 'Tasmanian Devil',
    traits: ['11', 'Christmas Tree', 'Rosy Cheeks', 'Sled', 'Present'],
  },
  {
    id: 912,
    type: 'Shark',
    traits: ['12', 'Bondi Beach'],
  },
  {
    id: 913,
    type: 'Octopus',
    traits: ['1', 'Sledding Hill', 'Ear Warmers Pink', 'Scarf Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 914,
    type: 'Dingo',
    traits: ['2', 'Snowman Valley', 'Scarf Red', 'Gloves Blue', 'Candle'],
  },
  {
    id: 915,
    type: 'Shark',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter White', 'Winter Jacket Purple', 'Gloves Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 916,
    type: 'Dingo',
    traits: ['4', 'Cozy Hut', 'Winter Jacket Red'],
  },
  {
    id: 917,
    type: 'Platypus',
    traits: ['5', 'Christmas Markets', 'Candy Cane'],
  },
  {
    id: 918,
    type: 'Crocodile',
    traits: ['Pipe', '6', 'Santas Sled', 'Elf Outfit', 'Gloves Red', 'Cup Hot Chocolate'],
  },
  {
    id: 919,
    type: 'Crocodile',
    traits: ['7', 'Santas Sack n Stable', 'Ear Warmers Red', 'Elf Outfit', 'Present'],
  },
  {
    id: 920,
    type: 'Tasmanian Tiger',
    traits: ['Pipe', '8', 'Pile of Presents', 'Beanie Winter White', 'Winter Jacket Blue'],
  },
  {
    id: 921,
    type: 'Dingo',
    traits: ['9', 'Toy Factory', 'Beanie Winter Blue', 'Winter Jacket Blue', 'Glass Egg Nog'],
  },
  {
    id: 922,
    type: 'Dingo',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter White', 'Winter Jacket Purple', 'Chocolate Cookie'],
  },
  {
    id: 923,
    type: 'Shark',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Blue', 'Skis'],
  },
  {
    id: 924,
    type: 'Grinch',
    traits: ['12', 'Bondi Beach'],
  },
  {
    id: 925,
    type: 'Platypus',
    traits: ['1', 'Sledding Hill', 'Beanie Winter Blue', 'Scarf Red', 'Cup Hot Chocolate'],
  },
  {
    id: 926,
    type: 'Crocodile',
    traits: ['2', 'Snowman Valley', 'Ear Warmers Red', 'Snow Shovel'],
  },
  {
    id: 927,
    type: 'Wombat',
    traits: ['Pipe', '3', 'Snowball Fight Arena', 'Scarf Blue', 'Sled', 'Ski Pole'],
  },
  {
    id: 928,
    type: 'Quokka',
    traits: ['4', 'Cozy Hut', 'Antlers', 'Winter Jacket Red'],
  },
  {
    id: 929,
    type: 'Penguin',
    traits: ['5', 'Christmas Markets', 'Ear Warmers White'],
  },
  {
    id: 930,
    type: 'Penguin',
    traits: ['6', 'Santas Sled', 'Ear Warmers Pink', 'Snowball'],
  },
  {
    id: 931,
    type: 'Dingo',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter Red', 'Scarf Blue', 'Skis'],
  },
  {
    id: 932,
    type: 'Shark',
    traits: ['8', 'Pile of Presents', 'Beanie Winter White', 'Scarf Blue', 'Gloves Blue', 'Ski Pole'],
  },
  {
    id: 933,
    type: 'Koala',
    traits: ['9', 'Toy Factory', 'Ear Warmers Red', 'Scarf Blue'],
  },
  {
    id: 934,
    type: 'Shark',
    traits: ['10', 'Cozy Fireplace', 'Winter Jacket Red', 'Snowball'],
  },
  {
    id: 935,
    type: 'Penguin',
    traits: ['11', 'Christmas Tree', 'Ski Goggles', 'Ear Warmers White', 'Santa Outfit', 'Sled', 'Snowball'],
  },
  {
    id: 936,
    type: 'Crocodile',
    traits: ['12', 'Bondi Beach', 'Glass Egg Nog'],
  },
  {
    id: 937,
    type: 'Seal',
    traits: ['1', 'Sledding Hill', 'Antlers', 'Scarf Red', 'Christmas Bell'],
  },
  {
    id: 938,
    type: 'Tasmanian Tiger',
    traits: ['2', 'Snowman Valley', 'Beanie Winter White', 'Elf Outfit', 'Cup Hot Chocolate'],
  },
  {
    id: 939,
    type: 'Penguin',
    traits: ['3', 'Snowball Fight Arena', 'Scarf Red', 'Sled'],
  },
  {
    id: 940,
    type: 'Kangaroo',
    traits: ['4', 'Cozy Hut', 'Antlers', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 941,
    type: 'Crocodile',
    traits: ['5', 'Christmas Markets', 'Elf Outfit', 'Sled'],
  },
  {
    id: 942,
    type: 'Tasmanian Devil',
    traits: ['6', 'Santas Sled', 'Beanie Winter White', 'Winter Jacket Purple', 'Gloves Blue'],
  },
  {
    id: 943,
    type: 'Crocodile',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter White', 'Scarf Red', 'Skis', 'Cup Hot Chocolate'],
  },
  {
    id: 944,
    type: 'Dingo',
    traits: ['8', 'Pile of Presents', 'Elf Hat', 'Scarf Blue', 'Sled', 'Chocolate Cookie'],
  },
  {
    id: 945,
    type: 'Seal',
    traits: ['9', 'Toy Factory', 'Santas Hat', 'Snow Shovel', 'Chocolate Cookie'],
  },
  {
    id: 946,
    type: 'Octopus',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter Blue', 'Scarf Red', 'Sled', 'Snowball'],
  },
  {
    id: 947,
    type: 'Tasmanian Devil',
    traits: ['11', 'Christmas Tree', 'Sled', 'Snowball'],
  },
  {
    id: 948,
    type: 'Tasmanian Tiger',
    traits: ['12', 'Bondi Beach', 'Present'],
  },
  {
    id: 949,
    type: 'Tasmanian Tiger',
    traits: ['1', 'Sledding Hill', 'Beanie Winter Blue', 'Elf Outfit', 'Cup Hot Chocolate'],
  },
  {
    id: 950,
    type: 'Tasmanian Tiger',
    traits: ['2', 'Snowman Valley', 'Ear Warmers White', 'Elf Outfit', 'Chocolate Cookie'],
  },
  {
    id: 951,
    type: 'Tasmanian Tiger',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter Red', 'Scarf Blue', 'Glass Milk'],
  },
  {
    id: 952,
    type: 'Platypus',
    traits: ['4', 'Cozy Hut', 'Ear Warmers Pink', 'Glass Milk', 'Snowball'],
  },
  {
    id: 953,
    type: 'Koala',
    traits: ['5', 'Christmas Markets', 'Rosy Cheeks', 'Ear Warmers Red', 'Elf Outfit', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 954,
    type: 'Octopus',
    traits: ['Pipe', '6', 'Santas Sled', 'Ear Warmers White'],
  },
  {
    id: 955,
    type: 'Penguin',
    traits: ['7', 'Santas Sack n Stable', 'Antlers', 'Winter Jacket Red', 'Cup Hot Chocolate'],
  },
  {
    id: 956,
    type: 'Penguin',
    traits: ['8', 'Pile of Presents', 'Glass Milk', 'Cup Hot Chocolate'],
  },
  {
    id: 957,
    type: 'Quokka',
    traits: ['9', 'Toy Factory', 'Ski Goggles', 'Winter Jacket Purple', 'Glass Egg Nog', 'Chocolate Cookie'],
  },
  {
    id: 958,
    type: 'Quokka',
    traits: ['10', 'Cozy Fireplace', 'Skis', 'Candy Cane'],
  },
  {
    id: 959,
    type: 'Kangaroo',
    traits: ['11', 'Christmas Tree', 'Ear Warmers Pink', 'Scarf Red', 'Candle'],
  },
  {
    id: 960,
    type: 'Crocodile',
    traits: ['12', 'Bondi Beach', 'Sunnies', 'Skis', 'Chocolate Cookie'],
  },
  {
    id: 961,
    type: 'Tasmanian Devil',
    traits: ['1', 'Sledding Hill', 'Ear Warmers Pink', 'Elf Outfit', 'Sled', 'Cup Hot Chocolate'],
  },
  {
    id: 962,
    type: 'Crocodile',
    traits: ['2', 'Snowman Valley', 'Rosy Cheeks', 'Antlers', 'Scarf Red', 'Sled'],
  },
  {
    id: 963,
    type: 'Quokka',
    traits: ['3', 'Snowball Fight Arena', 'Ear Warmers Red', 'Skis', 'Present'],
  },
  {
    id: 964,
    type: 'Koala',
    traits: ['4', 'Cozy Hut', 'Ear Warmers Pink', 'Scarf Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 965,
    type: 'Shark',
    traits: ['Pipe', '5', 'Christmas Markets', 'Rosy Cheeks', 'Ear Warmers White', 'Present'],
  },
  {
    id: 966,
    type: 'Seal',
    traits: ['6', 'Santas Sled', 'Beanie Winter White', 'Glass Egg Nog', 'Snowball'],
  },
  {
    id: 967,
    type: 'Bunyip',
    traits: ['Xmas Laser Eyes', '7', 'Santas Sack n Stable', 'Ear Warmers White', 'Scarf Red', 'Glass Milk', 'Present'],
  },
  {
    id: 968,
    type: 'Koala',
    traits: ['8', 'Pile of Presents', 'Scarf Blue', 'Snowball'],
  },
  {
    id: 969,
    type: 'Quokka',
    traits: ['9', 'Toy Factory', 'Beanie Winter Blue', 'Glass Milk', 'Christmas Bell'],
  },
  {
    id: 970,
    type: 'Penguin',
    traits: ['10', 'Cozy Fireplace', 'Glass Milk', 'Cup Hot Chocolate'],
  },
  {
    id: 971,
    type: 'Platypus',
    traits: ['11', 'Christmas Tree', 'Elf Hat', 'Glass Egg Nog', 'Candle'],
  },
  {
    id: 972,
    type: 'Koala',
    traits: ['12', 'Bondi Beach', 'Rosy Cheeks', 'Cup Hot Chocolate'],
  },
  {
    id: 973,
    type: 'Kangaroo',
    traits: ['1', 'Sledding Hill', 'Skis'],
  },
  {
    id: 974,
    type: 'Shark',
    traits: ['2', 'Snowman Valley', 'Ear Warmers Red', 'Scarf Red', 'Gloves Blue', 'Present'],
  },
  {
    id: 975,
    type: 'Drop Bear',
    traits: ['3', 'Snowball Fight Arena', 'Ski Goggles', 'Ear Warmers Red', 'Scarf Blue', 'Gloves Blue'],
  },
  {
    id: 976,
    type: 'Tasmanian Tiger',
    traits: ['Pipe', '4', 'Cozy Hut', 'Ear Warmers Pink', 'Scarf Red'],
  },
  {
    id: 977,
    type: 'Tasmanian Tiger',
    traits: ['5', 'Christmas Markets', 'Ear Warmers Red', 'Glass Milk', 'Cup Hot Chocolate'],
  },
  {
    id: 978,
    type: 'Tasmanian Devil',
    traits: ['Rudolf Nose', '6', 'Santas Sled', 'Scarf Red', 'Skis'],
  },
  {
    id: 979,
    type: 'Platypus',
    traits: ['7', 'Santas Sack n Stable', 'Winter Jacket Purple', 'Sled', 'Snowball'],
  },
  {
    id: 980,
    type: 'Koala',
    traits: ['8', 'Pile of Presents', 'Rosy Cheeks', 'Scarf Red', 'Gloves Blue', 'Snowball'],
  },
  {
    id: 981,
    type: 'Seal',
    traits: ['9', 'Toy Factory', 'Scarf Red', 'Candy Cane', 'Santas Beard'],
  },
  {
    id: 982,
    type: 'Dingo',
    traits: ['10', 'Cozy Fireplace', 'Elf Hat', 'Winter Jacket Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 983,
    type: 'Quokka',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Red', 'Scarf Red'],
  },
  {
    id: 984,
    type: 'Quokka',
    traits: ['12', 'Bondi Beach', 'Sunnies'],
  },
  {
    id: 985,
    type: 'Kangaroo',
    traits: ['Pipe', '1', 'Sledding Hill', 'Ski Goggles', 'Chocolate Cookie'],
  },
  {
    id: 986,
    type: 'Wombat',
    traits: ['2', 'Snowman Valley', 'Ski Goggles', 'Scarf Red', 'Gloves Blue'],
  },
  {
    id: 987,
    type: 'Wombat',
    traits: ['3', 'Snowball Fight Arena', 'Chocolate Cookie'],
  },
  {
    id: 988,
    type: 'Kangaroo',
    traits: ['4', 'Cozy Hut', 'Beanie Winter White', 'Gloves Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 989,
    type: 'Tasmanian Tiger',
    traits: ['5', 'Christmas Markets', 'Beanie Winter Blue', 'Santas Beard'],
  },
  {
    id: 990,
    type: 'Koala',
    traits: ['6', 'Santas Sled', 'Scarf Red', 'Gloves Red'],
  },
  {
    id: 991,
    type: 'Penguin',
    traits: ['7', 'Santas Sack n Stable', 'Rosy Cheeks', 'Beanie Winter White'],
  },
  {
    id: 992,
    type: 'Penguin',
    traits: ['8', 'Pile of Presents', 'Beanie Winter White', 'Elf Outfit', 'Chocolate Cookie'],
  },
  {
    id: 993,
    type: 'Wombat',
    traits: ['9', 'Toy Factory', 'Scarf Red', 'Gloves Red', 'Present'],
  },
  {
    id: 994,
    type: 'Tasmanian Tiger',
    traits: ['10', 'Cozy Fireplace', 'Scarf Red', 'Cup Hot Chocolate'],
  },
  {
    id: 995,
    type: 'Crocodile',
    traits: ['Pipe', '11', 'Christmas Tree', 'Antlers', 'Santa Outfit', 'Sled', 'Candle'],
  },
  {
    id: 996,
    type: 'Tasmanian Devil',
    traits: ['12', 'Bondi Beach', 'Sunnies', 'Snow Shovel', 'Present'],
  },
  {
    id: 997,
    type: 'Crocodile',
    traits: ['1', 'Sledding Hill', 'Beanie Winter White', 'Glass Milk'],
  },
  {
    id: 998,
    type: 'Wombat',
    traits: ['2', 'Snowman Valley', 'Beanie Winter White', 'Scarf Blue'],
  },
  {
    id: 999,
    type: 'Tasmanian Devil',
    traits: ['3', 'Snowball Fight Arena', 'Ski Goggles', 'Antlers', 'Scarf Red', 'Snow Shovel', 'Candle'],
  },
  {
    id: 1000,
    type: 'Platypus',
    traits: ['4', 'Cozy Hut', 'Beanie Winter White'],
  },
  {
    id: 1001,
    type: 'Wombat',
    traits: ['5', 'Christmas Markets', 'Rosy Cheeks', 'Beanie Winter White', 'Winter Jacket Red', 'Gloves Red'],
  },
  {
    id: 1002,
    type: 'Quokka',
    traits: ['Pipe', '6', 'Santas Sled', 'Beanie Winter White'],
  },
  {
    id: 1003,
    type: 'Dingo',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter Blue', 'Scarf Blue', 'Glass Egg Nog', 'Candle'],
  },
  {
    id: 1004,
    type: 'Crocodile',
    traits: ['8', 'Pile of Presents', 'Elf Outfit', 'Candy Cane', 'Santas Beard'],
  },
  {
    id: 1005,
    type: 'Crocodile',
    traits: ['9', 'Toy Factory', 'Winter Jacket Blue'],
  },
  {
    id: 1006,
    type: 'Quokka',
    traits: ['10', 'Cozy Fireplace', 'Rosy Cheeks', 'Ear Warmers White', 'Gloves Blue'],
  },
  {
    id: 1007,
    type: 'Koala',
    traits: ['11', 'Christmas Tree', 'Gloves Red', 'Cup Hot Chocolate'],
  },
  {
    id: 1008,
    type: 'Kangaroo',
    traits: ['12', 'Bondi Beach', 'Chocolate Cookie'],
  },
  {
    id: 1009,
    type: 'Koala',
    traits: ['1', 'Sledding Hill', 'Antlers', 'Winter Jacket Purple', 'Glass Egg Nog', 'Snowball'],
  },
  {
    id: 1010,
    type: 'Quokka',
    traits: ['Pipe', '2', 'Snowman Valley', 'Beanie Winter Red', 'Gloves Red'],
  },
  {
    id: 1011,
    type: 'Platypus',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter Red'],
  },
  {
    id: 1012,
    type: 'Tasmanian Tiger',
    traits: ['4', 'Cozy Hut', 'Rosy Cheeks', 'Glass Egg Nog', 'Christmas Bell'],
  },
  {
    id: 1013,
    type: 'Kangaroo',
    traits: ['5', 'Christmas Markets', 'Beanie Winter Blue', 'Sled'],
  },
  {
    id: 1014,
    type: 'Quokka',
    traits: ['6', 'Santas Sled', 'Beanie Winter Red', 'Scarf Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 1015,
    type: 'Seal',
    traits: ['7', 'Santas Sack n Stable', 'Glass Milk', 'Cup Hot Chocolate'],
  },
  {
    id: 1016,
    type: 'Dingo',
    traits: ['8', 'Pile of Presents', 'Elf Outfit', 'Gloves Red', 'Candle'],
  },
  {
    id: 1017,
    type: 'Platypus',
    traits: ['9', 'Toy Factory', 'Winter Jacket Red', 'Skis'],
  },
  {
    id: 1018,
    type: 'Dingo',
    traits: ['10', 'Cozy Fireplace', 'Rosy Cheeks', 'Scarf Red', 'Christmas Bell'],
  },
  {
    id: 1019,
    type: 'Octopus',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Red', 'Sled', 'Candy Cane'],
  },
  {
    id: 1020,
    type: 'Quokka',
    traits: ['12', 'Bondi Beach', 'Sunnies', 'Cup Hot Chocolate'],
  },
  {
    id: 1021,
    type: 'Kangaroo',
    traits: ['1', 'Sledding Hill', 'Rosy Cheeks', 'Winter Jacket Red', 'Glass Milk'],
  },
  {
    id: 1022,
    type: 'Tasmanian Tiger',
    traits: ['2', 'Snowman Valley'],
  },
  {
    id: 1023,
    type: 'Bunyip',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter White', 'Scarf Blue', 'Glass Milk'],
  },
  {
    id: 1024,
    type: 'Kangaroo',
    traits: ['4', 'Cozy Hut', 'Rosy Cheeks', 'Santas Hat', 'Scarf Blue'],
  },
  {
    id: 1025,
    type: 'Wombat',
    traits: ['5', 'Christmas Markets', 'Winter Jacket Purple', 'Skis'],
  },
  {
    id: 1026,
    type: 'Tasmanian Devil',
    traits: ['6', 'Santas Sled', 'Beanie Winter Blue', 'Scarf Blue', 'Sled', 'Present'],
  },
  {
    id: 1027,
    type: 'Crocodile',
    traits: ['Xmas Laser Eyes', '7', 'Santas Sack n Stable', 'Beanie Winter Blue', 'Ski Pole'],
  },
  {
    id: 1028,
    type: 'Tasmanian Devil',
    traits: ['8', 'Pile of Presents', 'Beanie Winter Blue', 'Winter Jacket Purple', 'Glass Egg Nog', 'Snowball'],
  },
  {
    id: 1029,
    type: 'Koala',
    traits: ['9', 'Toy Factory', 'Rosy Cheeks', 'Beanie Winter Red', 'Winter Jacket Blue', 'Glass Egg Nog', 'Christmas Bell'],
  },
  {
    id: 1030,
    type: 'Tasmanian Devil',
    traits: ['10', 'Cozy Fireplace', 'Rosy Cheeks', 'Elf Hat', 'Santa Outfit', 'Gloves Blue'],
  },
  {
    id: 1031,
    type: 'Koala',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Blue', 'Winter Jacket Purple'],
  },
  {
    id: 1032,
    type: 'Koala',
    traits: ['12', 'Bondi Beach', 'Present'],
  },
  {
    id: 1033,
    type: 'Quokka',
    traits: ['1', 'Sledding Hill', 'Gloves Blue'],
  },
  {
    id: 1034,
    type: 'Penguin',
    traits: ['2', 'Snowman Valley', 'Rosy Cheeks', 'Beanie Winter Red', 'Winter Jacket Purple', 'Sled', 'Present'],
  },
  {
    id: 1035,
    type: 'Tasmanian Devil',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter Blue', 'Elf Outfit', 'Skis', 'Chocolate Cookie'],
  },
  {
    id: 1036,
    type: 'Tasmanian Tiger',
    traits: ['4', 'Cozy Hut', 'Antlers', 'Winter Jacket Purple', 'Gloves Red'],
  },
  {
    id: 1037,
    type: 'Wombat',
    traits: ['5', 'Christmas Markets', 'Scarf Red', 'Candy Cane', 'Santas Beard'],
  },
  {
    id: 1038,
    type: 'Platypus',
    traits: ['6', 'Santas Sled', 'Rosy Cheeks', 'Scarf Blue', 'Glass Milk', 'Present'],
  },
  {
    id: 1039,
    type: 'Octopus',
    traits: ['Xmas Laser Eyes', '7', 'Santas Sack n Stable', 'Scarf Red', 'Snow Shovel'],
  },
  {
    id: 1040,
    type: 'Shark',
    traits: ['8', 'Pile of Presents', 'Antlers', 'Scarf Red'],
  },
  {
    id: 1041,
    type: 'Dingo',
    traits: ['9', 'Toy Factory', 'Beanie Winter White', 'Elf Outfit'],
  },
  {
    id: 1042,
    type: 'Tasmanian Tiger',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter White', 'Ski Pole'],
  },
  {
    id: 1043,
    type: 'Platypus',
    traits: ['11', 'Christmas Tree', 'Rosy Cheeks', 'Beanie Winter White', 'Elf Outfit', 'Gloves Red', 'Snowball'],
  },
  {
    id: 1044,
    type: 'Platypus',
    traits: ['Pipe', '12', 'Bondi Beach'],
  },
  {
    id: 1045,
    type: 'Wombat',
    traits: ['1', 'Sledding Hill', 'Winter Jacket Purple', 'Snow Shovel', 'Christmas Bell'],
  },
  {
    id: 1046,
    type: 'Tasmanian Tiger',
    traits: ['2', 'Snowman Valley', 'Rosy Cheeks', 'Beanie Winter White', 'Elf Outfit'],
  },
  {
    id: 1047,
    type: 'Seal',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter White', 'Scarf Red', 'Glass Egg Nog', 'Present'],
  },
  {
    id: 1048,
    type: 'Crocodile',
    traits: ['4', 'Cozy Hut', 'Beanie Winter Blue', 'Gloves Blue', 'Ski Pole'],
  },
  {
    id: 1049,
    type: 'Bunyip',
    traits: ['5', 'Christmas Markets', 'Rosy Cheeks', 'Santas Hat', 'Skis', 'Chocolate Cookie'],
  },
  {
    id: 1050,
    type: 'Tasmanian Devil',
    traits: ['6', 'Santas Sled', 'Rosy Cheeks', 'Beanie Winter White', 'Gloves Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 1051,
    type: 'Penguin',
    traits: ['7', 'Santas Sack n Stable', 'Antlers', 'Winter Jacket Blue', 'Christmas Bell'],
  },
  {
    id: 1052,
    type: 'Seal',
    traits: ['8', 'Pile of Presents', 'Antlers', 'Scarf Blue', 'Glass Milk', 'Candle'],
  },
  {
    id: 1053,
    type: 'Penguin',
    traits: ['9', 'Toy Factory', 'Rosy Cheeks', 'Scarf Red', 'Cup Hot Chocolate'],
  },
  {
    id: 1054,
    type: 'Wombat',
    traits: ['Pipe', '10', 'Cozy Fireplace', 'Winter Jacket Blue', 'Gloves Blue', 'Christmas Bell'],
  },
  {
    id: 1055,
    type: 'Dingo',
    traits: ['Pipe', '11', 'Christmas Tree', 'Beanie Winter Red', 'Winter Jacket Blue', 'Snow Shovel'],
  },
  {
    id: 1056,
    type: 'Seal',
    traits: ['12', 'Bondi Beach', 'Rosy Cheeks', 'Sunnies', 'Chocolate Cookie'],
  },
  {
    id: 1057,
    type: 'Koala',
    traits: ['1', 'Sledding Hill', 'Winter Jacket Red', 'Chocolate Cookie'],
  },
  {
    id: 1058,
    type: 'Wombat',
    traits: ['2', 'Snowman Valley', 'Ear Warmers White', 'Glass Egg Nog'],
  },
  {
    id: 1059,
    type: 'Octopus',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter White', 'Scarf Blue', 'Chocolate Cookie'],
  },
  {
    id: 1060,
    type: 'Octopus',
    traits: ['4', 'Cozy Hut', 'Beanie Winter Red', 'Scarf Red', 'Gloves Red', 'Snowball'],
  },
  {
    id: 1061,
    type: 'Kangaroo',
    traits: ['5', 'Christmas Markets', 'Antlers', 'Winter Jacket Red', 'Gloves Blue', 'Christmas Bell'],
  },
  {
    id: 1062,
    type: 'Platypus',
    traits: ['6', 'Santas Sled', 'Beanie Winter Red', 'Winter Jacket Blue'],
  },
  {
    id: 1063,
    type: 'Seal',
    traits: ['7', 'Santas Sack n Stable', 'Ear Warmers Red', 'Scarf Red', 'Glass Milk'],
  },
  {
    id: 1064,
    type: 'Quokka',
    traits: ['8', 'Pile of Presents', 'Santas Hat', 'Winter Jacket Purple', 'Glass Milk'],
  },
  {
    id: 1065,
    type: 'Tasmanian Devil',
    traits: ['9', 'Toy Factory', 'Elf Hat', 'Gloves Blue', 'Candle'],
  },
  {
    id: 1066,
    type: 'Wombat',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter White'],
  },
  {
    id: 1067,
    type: 'Tasmanian Tiger',
    traits: ['11', 'Christmas Tree', 'Ski Goggles', 'Antlers'],
  },
  {
    id: 1068,
    type: 'Octopus',
    traits: ['12', 'Bondi Beach', 'Skis', 'Christmas Bell'],
  },
  {
    id: 1069,
    type: 'Shark',
    traits: ['1', 'Sledding Hill', 'Beanie Winter Blue', 'Winter Jacket Blue', 'Glass Milk', 'Candy Cane'],
  },
  {
    id: 1070,
    type: 'Dingo',
    traits: ['Pipe', '2', 'Snowman Valley', 'Ski Goggles', 'Scarf Red', 'Glass Milk', 'Candy Cane'],
  },
  {
    id: 1071,
    type: 'Crocodile',
    traits: ['3', 'Snowball Fight Arena', 'Cup Hot Chocolate'],
  },
  {
    id: 1072,
    type: 'Octopus',
    traits: ['4', 'Cozy Hut', 'Snow Shovel', 'Ski Pole'],
  },
  {
    id: 1073,
    type: 'Tasmanian Devil',
    traits: ['5', 'Christmas Markets', 'Elf Hat', 'Scarf Red', 'Glass Egg Nog', 'Candy Cane'],
  },
  {
    id: 1074,
    type: 'Grinch',
    traits: ['6', 'Santas Sled', 'Snow Shovel', 'Candy Cane'],
  },
  {
    id: 1075,
    type: 'Platypus',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter Blue', 'Scarf Blue', 'Glass Milk', 'Cup Hot Chocolate'],
  },
  {
    id: 1076,
    type: 'Shark',
    traits: ['8', 'Pile of Presents', 'Ear Warmers Red', 'Scarf Red', 'Candle'],
  },
  {
    id: 1077,
    type: 'Crocodile',
    traits: ['9', 'Toy Factory', 'Winter Jacket Blue', 'Gloves Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 1078,
    type: 'Seal',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter Blue', 'Scarf Red', 'Sled', 'Ski Pole'],
  },
  {
    id: 1079,
    type: 'Quokka',
    traits: ['11', 'Christmas Tree', 'Ear Warmers Red', 'Present'],
  },
  {
    id: 1080,
    type: 'Shark',
    traits: ['12', 'Bondi Beach', 'Rosy Cheeks', 'Sunnies', 'Snow Shovel'],
  },
  {
    id: 1081,
    type: 'Tasmanian Tiger',
    traits: ['1', 'Sledding Hill', 'Beanie Winter Blue', 'Glass Egg Nog'],
  },
  {
    id: 1082,
    type: 'Tasmanian Tiger',
    traits: ['2', 'Snowman Valley', 'Elf Outfit', 'Present'],
  },
  {
    id: 1083,
    type: 'Platypus',
    traits: ['Xmas Laser Eyes', '3', 'Snowball Fight Arena', 'Beanie Winter White', 'Sled', 'Chocolate Cookie'],
  },
  {
    id: 1084,
    type: 'Platypus',
    traits: ['4', 'Cozy Hut', 'Ear Warmers Pink', 'Scarf Red', 'Candle'],
  },
  {
    id: 1085,
    type: 'Kangaroo',
    traits: ['5', 'Christmas Markets', 'Beanie Winter Blue', 'Gloves Red', 'Present'],
  },
  {
    id: 1086,
    type: 'Tasmanian Tiger',
    traits: ['Pipe', '6', 'Santas Sled'],
  },
  {
    id: 1087,
    type: 'Octopus',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter White', 'Gloves Red', 'Cup Hot Chocolate'],
  },
  {
    id: 1088,
    type: 'Koala',
    traits: ['8', 'Pile of Presents', 'Ski Goggles', 'Scarf Blue', 'Sled', 'Ski Pole'],
  },
  {
    id: 1089,
    type: 'Drop Bear',
    traits: ['9', 'Toy Factory', 'Scarf Red', 'Glass Milk'],
  },
  {
    id: 1090,
    type: 'Penguin',
    traits: ['Pipe', '10', 'Cozy Fireplace', 'Ski Goggles', 'Sled', 'Cup Hot Chocolate'],
  },
  {
    id: 1091,
    type: 'Seal',
    traits: ['11', 'Christmas Tree', 'Beanie Winter Red'],
  },
  {
    id: 1092,
    type: 'Wombat',
    traits: ['12', 'Bondi Beach', 'Santas Beard'],
  },
  {
    id: 1093,
    type: 'Koala',
    traits: ['1', 'Sledding Hill', 'Ski Goggles', 'Winter Jacket Blue', 'Glass Egg Nog'],
  },
  {
    id: 1094,
    type: 'Seal',
    traits: ['2', 'Snowman Valley', 'Winter Jacket Red', 'Sled', 'Candle'],
  },
  {
    id: 1095,
    type: 'Seal',
    traits: ['3', 'Snowball Fight Arena', 'Scarf Red', 'Snow Shovel', 'Ski Pole'],
  },
  {
    id: 1096,
    type: 'Wombat',
    traits: ['4', 'Cozy Hut'],
  },
  {
    id: 1097,
    type: 'Koala',
    traits: ['5', 'Christmas Markets', 'Antlers', 'Scarf Blue', 'Gloves Red', 'Present'],
  },
  {
    id: 1098,
    type: 'Shark',
    traits: ['6', 'Santas Sled', 'Ear Warmers Red', 'Gloves Red', 'Christmas Bell'],
  },
  {
    id: 1099,
    type: 'Dingo',
    traits: ['Rudolf Nose', '7', 'Santas Sack n Stable', 'Antlers', 'Snow Shovel', 'Present'],
  },
  {
    id: 1100,
    type: 'Shark',
    traits: ['8', 'Pile of Presents', 'Beanie Winter White', 'Scarf Red', 'Gloves Red', 'Cup Hot Chocolate'],
  },
  {
    id: 1101,
    type: 'Penguin',
    traits: ['9', 'Toy Factory', 'Rosy Cheeks', 'Skis', 'Candy Cane'],
  },
  {
    id: 1102,
    type: 'Shark',
    traits: ['10', 'Cozy Fireplace', 'Gloves Blue', 'Snowball'],
  },
  {
    id: 1103,
    type: 'Penguin',
    traits: ['11', 'Christmas Tree', 'Scarf Blue', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 1104,
    type: 'Octopus',
    traits: ['12', 'Bondi Beach', 'Sled', 'Present'],
  },
  {
    id: 1105,
    type: 'Crocodile',
    traits: ['1', 'Sledding Hill', 'Beanie Winter Blue'],
  },
  {
    id: 1106,
    type: 'Kangaroo',
    traits: ['2', 'Snowman Valley', 'Rosy Cheeks', 'Beanie Winter White', 'Scarf Red', 'Gloves Blue'],
  },
  {
    id: 1107,
    type: 'Koala',
    traits: ['3', 'Snowball Fight Arena', 'Winter Jacket Red', 'Snow Shovel'],
  },
  {
    id: 1108,
    type: 'Tasmanian Tiger',
    traits: ['4', 'Cozy Hut', 'Chocolate Cookie'],
  },
  {
    id: 1109,
    type: 'Wombat',
    traits: ['5', 'Christmas Markets', 'Rosy Cheeks', 'Elf Outfit', 'Snow Shovel'],
  },
  {
    id: 1110,
    type: 'Koala',
    traits: ['6', 'Santas Sled', 'Winter Jacket Red', 'Sled', 'Cup Hot Chocolate'],
  },
  {
    id: 1111,
    type: 'Octopus',
    traits: ['7', 'Santas Sack n Stable', 'Ear Warmers Red', 'Chocolate Cookie'],
  },
  {
    id: 1112,
    type: 'Tasmanian Tiger',
    traits: ['8', 'Pile of Presents', 'Elf Hat', 'Christmas Bell'],
  },
  {
    id: 1113,
    type: 'Wombat',
    traits: ['9', 'Toy Factory', 'Chocolate Cookie'],
  },
  {
    id: 1114,
    type: 'Tasmanian Tiger',
    traits: ['10', 'Cozy Fireplace', 'Rosy Cheeks', 'Elf Outfit', 'Cup Hot Chocolate'],
  },
  {
    id: 1115,
    type: 'Koala',
    traits: ['11', 'Christmas Tree', 'Christmas Bell'],
  },
  {
    id: 1116,
    type: 'Shark',
    traits: ['12', 'Bondi Beach', 'Rosy Cheeks', 'Glass Egg Nog'],
  },
  {
    id: 1117,
    type: 'Tasmanian Tiger',
    traits: ['1', 'Sledding Hill', 'Winter Jacket Purple', 'Santas Beard'],
  },
  {
    id: 1118,
    type: 'Crocodile',
    traits: ['2', 'Snowman Valley', 'Scarf Red', 'Candy Cane', 'Santas Beard'],
  },
  {
    id: 1119,
    type: 'Kangaroo',
    traits: ['3', 'Snowball Fight Arena', 'Ear Warmers Pink', 'Scarf Blue', 'Snow Shovel', 'Snowball'],
  },
  {
    id: 1120,
    type: 'Tasmanian Tiger',
    traits: ['Xmas Laser Eyes', '4', 'Cozy Hut', 'Beanie Winter Blue', 'Gloves Blue'],
  },
  {
    id: 1121,
    type: 'Wombat',
    traits: ['5', 'Christmas Markets', 'Ski Goggles', 'Glass Egg Nog', 'Cup Hot Chocolate'],
  },
  {
    id: 1122,
    type: 'Wombat',
    traits: ['6', 'Santas Sled', 'Rosy Cheeks', 'Ear Warmers Pink', 'Snow Shovel', 'Candy Cane'],
  },
  {
    id: 1123,
    type: 'Platypus',
    traits: ['7', 'Santas Sack n Stable', 'Rosy Cheeks', 'Antlers', 'Gloves Red'],
  },
  {
    id: 1124,
    type: 'Seal',
    traits: ['8', 'Pile of Presents', 'Rosy Cheeks', 'Winter Jacket Red', 'Gloves Red'],
  },
  {
    id: 1125,
    type: 'Crocodile',
    traits: ['9', 'Toy Factory', 'Ski Goggles', 'Ear Warmers Pink', 'Elf Outfit', 'Snow Shovel'],
  },
  {
    id: 1126,
    type: 'Wombat',
    traits: ['10', 'Cozy Fireplace', 'Glass Milk', 'Chocolate Cookie'],
  },
  {
    id: 1127,
    type: 'Koala',
    traits: ['11', 'Christmas Tree', 'Ear Warmers White', 'Scarf Red', 'Gloves Red', 'Chocolate Cookie'],
  },
  {
    id: 1128,
    type: 'Quokka',
    traits: ['12', 'Bondi Beach', 'Rosy Cheeks', 'Skis'],
  },
  {
    id: 1129,
    type: 'Platypus',
    traits: ['1', 'Sledding Hill', 'Ear Warmers White', 'Elf Outfit', 'Present'],
  },
  {
    id: 1130,
    type: 'Bunyip',
    traits: ['2', 'Snowman Valley', 'Beanie Winter Blue', 'Gloves Red'],
  },
  {
    id: 1131,
    type: 'Koala',
    traits: ['Pipe', '3', 'Snowball Fight Arena', 'Snow Shovel'],
  },
  {
    id: 1132,
    type: 'Penguin',
    traits: ['Pipe', '4', 'Cozy Hut', 'Ear Warmers Pink', 'Scarf Red', 'Glass Milk', 'Candy Cane'],
  },
  {
    id: 1133,
    type: 'Drop Bear',
    traits: ['5', 'Christmas Markets', 'Ear Warmers White', 'Scarf Red', 'Skis', 'Snowball'],
  },
  {
    id: 1134,
    type: 'Crocodile',
    traits: ['6', 'Santas Sled', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 1135,
    type: 'Crocodile',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter Blue', 'Winter Jacket Red', 'Glass Egg Nog', 'Present'],
  },
  {
    id: 1136,
    type: 'Dingo',
    traits: ['8', 'Pile of Presents', 'Winter Jacket Red', 'Glass Egg Nog', 'Present'],
  },
  {
    id: 1137,
    type: 'Platypus',
    traits: ['9', 'Toy Factory', 'Beanie Winter Red', 'Santa Outfit', 'Gloves Blue'],
  },
  {
    id: 1138,
    type: 'Koala',
    traits: ['10', 'Cozy Fireplace', 'Rosy Cheeks', 'Glass Milk', 'Candle'],
  },
  {
    id: 1139,
    type: 'Kangaroo',
    traits: ['11', 'Christmas Tree', 'Rosy Cheeks', 'Present'],
  },
  {
    id: 1140,
    type: 'Tasmanian Devil',
    traits: ['12', 'Bondi Beach', 'Sled'],
  },
  {
    id: 1141,
    type: 'Crocodile',
    traits: ['1', 'Sledding Hill', 'Beanie Winter White', 'Scarf Red', 'Glass Milk', 'Snowball'],
  },
  {
    id: 1142,
    type: 'Platypus',
    traits: ['2', 'Snowman Valley', 'Beanie Winter Red', 'Gloves Red', 'Candy Cane'],
  },
  {
    id: 1143,
    type: 'Drop Bear',
    traits: ['3', 'Snowball Fight Arena', 'Santas Hat', 'Santa Outfit', 'Ski Pole'],
  },
  {
    id: 1144,
    type: 'Kangaroo',
    traits: ['4', 'Cozy Hut', 'Rosy Cheeks', 'Beanie Winter White', 'Winter Jacket Red', 'Skis'],
  },
  {
    id: 1145,
    type: 'Kangaroo',
    traits: ['Xmas Laser Eyes', '5', 'Christmas Markets', 'Sled'],
  },
  {
    id: 1146,
    type: 'Shark',
    traits: ['6', 'Santas Sled', 'Beanie Winter Blue', 'Winter Jacket Blue', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 1147,
    type: 'Tasmanian Tiger',
    traits: ['7', 'Santas Sack n Stable', 'Elf Hat', 'Sled', 'Candle'],
  },
  {
    id: 1148,
    type: 'Penguin',
    traits: ['8', 'Pile of Presents', 'Santa Outfit', 'Sled', 'Candle'],
  },
  {
    id: 1149,
    type: 'Wombat',
    traits: ['9', 'Toy Factory', 'Winter Jacket Blue', 'Gloves Red'],
  },
  {
    id: 1150,
    type: 'Kangaroo',
    traits: ['10', 'Cozy Fireplace', 'Sled', 'Cup Hot Chocolate'],
  },
  {
    id: 1151,
    type: 'Wombat',
    traits: ['11', 'Christmas Tree', 'Elf Hat', 'Sled', 'Cup Hot Chocolate'],
  },
  {
    id: 1152,
    type: 'Kangaroo',
    traits: ['12', 'Bondi Beach', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 1153,
    type: 'Kangaroo',
    traits: ['1', 'Sledding Hill', 'Antlers', 'Winter Jacket Purple', 'Gloves Red'],
  },
  {
    id: 1154,
    type: 'Octopus',
    traits: ['2', 'Snowman Valley', 'Scarf Blue', 'Gloves Blue', 'Christmas Bell'],
  },
  {
    id: 1155,
    type: 'Tasmanian Tiger',
    traits: ['3', 'Snowball Fight Arena', 'Gloves Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 1156,
    type: 'Penguin',
    traits: ['4', 'Cozy Hut', 'Candy Cane'],
  },
  {
    id: 1157,
    type: 'Drop Bear',
    traits: ['5', 'Christmas Markets', 'Rosy Cheeks', 'Beanie Winter Red', 'Scarf Blue', 'Gloves Red', 'Chocolate Cookie'],
  },
  {
    id: 1158,
    type: 'Dingo',
    traits: ['6', 'Santas Sled'],
  },
  {
    id: 1159,
    type: 'Koala',
    traits: ['7', 'Santas Sack n Stable', 'Rosy Cheeks', 'Beanie Winter Red', 'Winter Jacket Blue', 'Glass Egg Nog'],
  },
  {
    id: 1160,
    type: 'Koala',
    traits: ['8', 'Pile of Presents', 'Santa Outfit'],
  },
  {
    id: 1161,
    type: 'Crocodile',
    traits: ['9', 'Toy Factory', 'Beanie Winter Red', 'Elf Outfit', 'Snow Shovel', 'Snowball'],
  },
  {
    id: 1162,
    type: 'Tasmanian Tiger',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter Red', 'Gloves Red', 'Present'],
  },
  {
    id: 1163,
    type: 'Bunyip',
    traits: ['11', 'Christmas Tree', 'Winter Jacket Red', 'Glass Milk'],
  },
  {
    id: 1164,
    type: 'Shark',
    traits: ['12', 'Bondi Beach', 'Ski Pole'],
  },
  {
    id: 1165,
    type: 'Seal',
    traits: ['1', 'Sledding Hill', 'Elf Hat', 'Gloves Red', 'Ski Pole'],
  },
  {
    id: 1166,
    type: 'Wombat',
    traits: ['2', 'Snowman Valley', 'Scarf Blue', 'Candle', 'Santas Beard'],
  },
  {
    id: 1167,
    type: 'Tasmanian Devil',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter Blue', 'Winter Jacket Red', 'Skis', 'Cup Hot Chocolate'],
  },
  {
    id: 1168,
    type: 'Seal',
    traits: ['4', 'Cozy Hut', 'Ear Warmers Red', 'Glass Egg Nog', 'Chocolate Cookie'],
  },
  {
    id: 1169,
    type: 'Dingo',
    traits: ['5', 'Christmas Markets', 'Winter Jacket Red', 'Candle'],
  },
  {
    id: 1170,
    type: 'Wombat',
    traits: ['6', 'Santas Sled', 'Rosy Cheeks', 'Winter Jacket Red', 'Snow Shovel', 'Candy Cane'],
  },
  {
    id: 1171,
    type: 'Platypus',
    traits: ['7', 'Santas Sack n Stable', 'Beanie Winter White', 'Winter Jacket Blue', 'Snow Shovel', 'Chocolate Cookie'],
  },
  {
    id: 1172,
    type: 'Tasmanian Tiger',
    traits: ['8', 'Pile of Presents', 'Winter Jacket Red', 'Gloves Blue', 'Candle'],
  },
  {
    id: 1173,
    type: 'Wombat',
    traits: ['9', 'Toy Factory', 'Glass Milk', 'Cup Hot Chocolate'],
  },
  {
    id: 1174,
    type: 'Tasmanian Tiger',
    traits: ['10', 'Cozy Fireplace', 'Beanie Winter Blue', 'Elf Outfit', 'Present'],
  },
  {
    id: 1175,
    type: 'Crocodile',
    traits: ['11', 'Christmas Tree', 'Elf Outfit', 'Glass Egg Nog', 'Christmas Bell'],
  },
  {
    id: 1176,
    type: 'Tasmanian Tiger',
    traits: ['12', 'Bondi Beach', 'Cup Hot Chocolate'],
  },
  {
    id: 1177,
    type: 'Penguin',
    traits: ['Pipe', '1', 'Sledding Hill', 'Beanie Winter White', 'Glass Egg Nog', 'Chocolate Cookie'],
  },
  {
    id: 1178,
    type: 'Wombat',
    traits: ['2', 'Snowman Valley', 'Rosy Cheeks', 'Glass Egg Nog'],
  },
  {
    id: 1179,
    type: 'Wombat',
    traits: ['3', 'Snowball Fight Arena', 'Beanie Winter Blue', 'Santa Outfit', 'Snow Shovel', 'Cup Hot Chocolate'],
  },
  {
    id: 1180,
    type: 'Crocodile',
    traits: ['4', 'Cozy Hut', 'Beanie Winter White', 'Elf Outfit', 'Gloves Blue', 'Candle'],
  },
  {
    id: 1181,
    type: 'Drop Bear',
    traits: ['5', 'Christmas Markets', 'Rosy Cheeks', 'Glass Milk'],
  },
  {
    id: 1182,
    type: 'Crocodile',
    traits: ['6', 'Santas Sled', 'Beanie Winter White', 'Winter Jacket Purple', 'Christmas Bell'],
  },
  {
    id: 1183,
    type: 'Platypus',
    traits: ['7', 'Santas Sack n Stable', 'Ear Warmers Red', 'Scarf Blue', 'Gloves Blue', 'Snowball'],
  },
  {
    id: 1184,
    type: 'Crocodile',
    traits: ['8', 'Pile of Presents', 'Beanie Winter Blue', 'Winter Jacket Blue', 'Candy Cane'],
  },
  {
    id: 1185,
    type: 'Koala',
    traits: ['9', 'Toy Factory', 'Rosy Cheeks', 'Antlers', 'Winter Jacket Purple', 'Glass Egg Nog', 'Candle'],
  },
  {
    id: 1186,
    type: 'Penguin',
    traits: ['10', 'Cozy Fireplace', 'Antlers'],
  },
  {
    id: 1187,
    type: 'Kangaroo',
    traits: ['11', 'Christmas Tree', 'Winter Jacket Purple'],
  },
  {
    id: 1188,
    type: 'Grinch',
    traits: ['12', 'Bondi Beach', 'Present'],
  },
  {
    id: 1189,
    type: 'Platypus',
    traits: ['1', 'Sledding Hill', 'Antlers', 'Scarf Red', 'Skis'],
  },
  {
    id: 1190,
    type: 'Octopus',
    traits: ['2', 'Snowman Valley', 'Gloves Blue', 'Present'],
  },
  {
    id: 1191,
    type: 'Shark',
    traits: ['3', 'Snowball Fight Arena', 'Rosy Cheeks', 'Beanie Winter White', 'Scarf Blue', 'Cup Hot Chocolate'],
  },
  {
    id: 1192,
    type: 'Kangaroo',
    traits: ['4', 'Cozy Hut', 'Santas Hat', 'Winter Jacket Red', 'Skis', 'Cup Hot Chocolate'],
  },
  {
    id: 1193,
    type: 'Tasmanian Tiger',
    traits: ['5', 'Christmas Markets', 'Antlers', 'Elf Outfit', 'Chocolate Cookie'],
  },
  {
    id: 1194,
    type: 'Crocodile',
    traits: ['6', 'Santas Sled', 'Rosy Cheeks', 'Beanie Winter White', 'Winter Jacket Red', 'Glass Milk', 'Present'],
  },
  {
    id: 1195,
    type: 'Platypus',
    traits: ['7', 'Santas Sack n Stable', 'Elf Hat', 'Winter Jacket Red', 'Gloves Blue', 'Candle'],
  },
  {
    id: 1196,
    type: 'Kangaroo',
    traits: ['8', 'Pile of Presents', 'Ski Goggles', 'Snow Shovel'],
  },
  {
    id: 1197,
    type: 'Kangaroo',
    traits: ['9', 'Toy Factory', 'Rosy Cheeks', 'Skis', 'Chocolate Cookie'],
  },
  {
    id: 1198,
    type: 'Dingo',
    traits: ['10', 'Cozy Fireplace', 'Sled', 'Candy Cane'],
  },
  {
    id: 1199,
    type: 'Koala',
    traits: ['11', 'Christmas Tree', 'Antlers', 'Sled', 'Present'],
  },
  {
    id: 1200,
    type: 'Tasmanian Tiger',
    traits: ['Rudolf Nose', '12', 'Bondi Beach'],
  },
];

export default seasonXmasData;
