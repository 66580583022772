export default {
  type: {
    label: 'Unts',
    attribute: 'type',
    options: {
      Bunyip: {
        icon: 'bunyip.png',
      },
      Crocodile: {
        icon: 'crocodile.png',
      },
      Dingo: {
        icon: 'dingo.png',
      },
      'Drop Bear': {
        icon: 'drop-bear.png',
      },
      Kangaroo: {
        icon: 'kangaroo.png',
      },
      Koala: {
        icon: 'koala.png',
      },
      Octopus: {
        icon: 'octopus.png',
      },
      Penguin: {
        icon: 'penguin.png',
      },
      Platypus: {
        icon: 'platypus.png',
      },
      Quokka: {
        icon: 'quokka.png',
      },
      Seal: {
        icon: 'seal.png',
      },
      Shark: {
        icon: 'shark.png',
      },
      'Tasmanian Devil': {
        icon: 'tasmanian-devil.png',
      },
      'Tasmanian Tiger': {
        icon: 'tasmanian-tiger.png',
      },
      Wombat: {
        icon: 'wombat.png',
      },
    },
  },
  face: {
    label: 'Suit',
    attribute: 'traits',
    options: {
      'Skeleton Suit': {
        icon: 'skeleton-suit.png',
      },
    },
  },
  eyes: {
    label: 'Eyes',
    attribute: 'traits',
    options: {
      'Pumpkin Head': {
        icon: 'pumpkin-head.png',
      },
      'Eye Patch': {
        icon: 'eye-patch.png',
      },
    },
  },
  hat: {
    label: 'Hats',
    attribute: 'traits',
    options: {
      Horns: {
        icon: 'horns.png',
      },
      'Witch Hat': {
        icon: 'witch-hat.png',
      },
    },
  },
  lefty: {
    label: 'Lefty',
    attribute: 'traits',
    options: {
      'Skull ': {
        icon: 'skull.png',
      },
      Torch: {
        icon: 'torch.png',
      },
      Wand: {
        icon: 'wand.png',
      },
      Lollipop: {
        icon: 'lollipop.png',
      },
      Cauldron: {
        icon: 'cauldron.png',
      },
      'Pumpkin ': {
        icon: 'pumpkin.png',
      },
    },
  },
  location: {
    label: 'Location',
    attribute: 'traits',
    options: {
      'Creepy Mansion': {
        icon: 'creepy-mansion.png',
      },
      Necrofire: {
        icon: 'necrofire.png',
      },
      'Bloodi Beach': {
        icon: 'bloodi-beach.png',
      },
      Cave: {
        icon: 'cave.png',
      },
      'Demonic Gate': {
        icon: 'demonic-gate.png',
      },
      Cemetary: {
        icon: 'cemetary.png',
      },
    },
  },
  righty: {
    label: 'Righty',
    attribute: 'traits',
    options: {
      'Hopium Bong': {
        icon: 'hopium-bong.png',
      },
      Pumpkin: {
        icon: 'pumpkin.png',
      },
      Scythe: {
        icon: 'scythe.png',
      },
      'Flaming Sword': {
        icon: 'flaming-sword.png',
      },
      Broom: {
        icon: 'broom.png',
      },
      Skull: {
        icon: 'skull.png',
      },
    },
  },
  special: {
    label: 'Special',
    attribute: 'traits',
    options: {
      Whisp: {
        icon: 'whisp.png',
      },
      Bat: {
        icon: 'bat.png',
      },
      Snake: {
        icon: 'snake.png',
      },
      'Laser Eyes Green': {
        icon: 'laser-eyes-green.png',
      },
    },
  },
};
