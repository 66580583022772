export default {
  type: {
    label: 'Unts',
    attribute: 'type',
    options: {
      Kangaroo: {
        icon: 'kangaroo.png',
        rarity: '10.36%',
        count: 921,
      },
      Koala: {
        icon: 'koala.png',
        rarity: '10.23%',
        count: 909,
      },
      Dingo: {
        icon: 'dingo.png',
        rarity: '10.07%',
        count: 895,
      },
      Wombat: {
        icon: 'wombat.png',
        rarity: '9.91%',
        count: 881,
      },
      Crocodile: {
        icon: 'crocodile.png',
        rarity: '9.06%',
        count: 805,
      },
      Quokka: {
        icon: 'quokka.png',
        rarity: '8.74%',
        count: 777,
      },
      Penguin: {
        icon: 'penguin.png',
        rarity: '8.06%',
        count: 716,
      },
      Platypus: {
        icon: 'platypus.png',
        rarity: '7.75%',
        count: 689,
      },
      Seal: {
        icon: 'seal.png',
        rarity: '7.47%',
        count: 664,
      },
      Shark: {
        icon: 'shark.png',
        rarity: '7.34%',
        count: 652,
      },
      Octopus: {
        icon: 'octopus.png',
        rarity: '6.48%',
        count: 576,
      },
      'Tasmanian Devil': {
        icon: 'tasmanian-devil.png',
        rarity: '2.39%',
        count: 212,
      },
      'Tasmanian Tiger': {
        icon: 'tasmanian-tiger.png',
        rarity: '1.51%',
        count: 134,
      },
      'Drop Bear': {
        icon: 'drop-bear.png',
        rarity: '0.41%',
        count: 36,
      },
      Bunyip: {
        icon: 'bunyip.png',
        rarity: '0.24%',
        count: 21,
      },
    },
  },
  face: {
    label: 'Face',
    attribute: 'traits',
    options: {
      'Rosy Cheeks': {
        icon: 'rosy-cheeks.png',
        rarity: '7.27%',
        count: 646,
      },
      'Zink On Cheeks': {
        icon: 'zink-on-cheeks.png',
        rarity: '6.46%',
        count: 574,
      },
      'Boonie Mustache': {
        icon: 'boonie-mustache.png',
        rarity: '3.33%',
        count: 296,
      },
    },
  },
  eyes: {
    label: 'Eyes',
    attribute: 'traits',
    options: {
      'Cricket Glasses': {
        icon: 'cricket-glasses.png',
        rarity: '8.44%',
        count: 750,
      },
      Eyelashes: {
        icon: 'eyelashes.png',
        rarity: '6.15%',
        count: 547,
      },
      Sunnies: {
        icon: 'sunnies.png',
        rarity: '6.01%',
        count: 534,
      },
      '3d Glasses': {
        icon: '3d-glasses.png',
        rarity: '4.26%',
        count: 379,
      },
      'Nerdy Glasses': {
        icon: 'nerdy-glasses.png',
        rarity: '3.99%',
        count: 355,
      },
      'Eye Patch': {
        icon: 'eye-patch.png',
        rarity: '2.99%',
        count: 266,
      },
      'VR Goggles': {
        icon: 'vr-goggles.png',
        rarity: '0.95%',
        count: 84,
      },
      'Scuba Mask Red': {
        icon: 'scuba-mask-red.png',
        rarity: '0.91%',
        count: 81,
      },
      'Scuba Mask Blue': {
        icon: 'scuba-mask-blue.png',
        rarity: '0.61%',
        count: 54,
      },
      Monocle: {
        icon: 'monocle.png',
        rarity: '0.25%',
        count: 22,
      },
    },
  },
  accessory: {
    label: 'Accessory',
    attribute: 'traits',
    options: {
      'Gold Chain': {
        icon: 'gold-chain.png',
        rarity: '5.64%',
        count: 501,
      },
      'Jackie Howe': {
        icon: 'jackie-howe.png',
        rarity: '4.42%',
        count: 393,
      },
      'Council Worker Vest': {
        icon: 'council-worker-vest.png',
        rarity: '4.10%',
        count: 364,
      },
      '1980s Bum Bag': {
        icon: '1980s-bum-bag.png',
        rarity: '2.77%',
        count: 246,
      },
      'Mad Max Jacket': {
        icon: 'mad-max-jacket.png',
        rarity: '0.07%',
        count: 6,
      },
    },
  },
  hat: {
    label: 'Hats',
    attribute: 'traits',
    options: {
      'Aussie Hat': {
        icon: 'aussie-hat.png',
        rarity: '5.05%',
        count: 449,
      },
      'Surfer Hair': {
        icon: 'surfer-hair.png',
        rarity: '4.50%',
        count: 400,
      },
      'Punk Hair': {
        icon: 'punk-hair.png',
        rarity: '4.10%',
        count: 364,
      },
      'Perry Hat': {
        icon: 'perry-hat.png',
        rarity: '4.06%',
        count: 361,
      },
      'Waccas Hat Red': {
        icon: 'waccas-hat-red.png',
        rarity: '3.67%',
        count: 326,
      },
      'Orange Beany': {
        icon: 'orange-beany.png',
        rarity: '3.66%',
        count: 325,
      },
      'CFA Hat': {
        icon: 'cfa-hat.png',
        rarity: '3.43%',
        count: 305,
      },
      Sweatband: {
        icon: 'sweatband.png',
        rarity: '3.31%',
        count: 294,
      },
      'Police Hat': {
        icon: 'police-hat.png',
        rarity: '2.61%',
        count: 232,
      },
      'Diggers Hat': {
        icon: 'diggers-hat.png',
        rarity: '2.17%',
        count: 193,
      },
      'Dundee Hat': {
        icon: 'dundee-hat.png',
        rarity: '1.97%',
        count: 175,
      },
      'Waccas Hat Dark': {
        icon: 'waccas-hat-dark.png',
        rarity: '1.19%',
        count: 106,
      },
      'Blonde Mullet': {
        icon: 'blonde-mullet.png',
        rarity: '1.09%',
        count: 97,
      },
      'Baggy Green': {
        icon: 'baggy-green.png',
        rarity: '0.32%',
        count: 28,
      },
      Halo: {
        icon: 'halo.png',
        rarity: '0.20%',
        count: 18,
      },
      'Devil Horns': {
        icon: 'devil-horns.png',
        rarity: '0.17%',
        count: 15,
      },
      Crown: {
        icon: 'crown.png',
        rarity: '0.07%',
        count: 6,
      },
    },
  },
  lefty: {
    label: 'Lefty',
    attribute: 'traits',
    options: {
      'VB Stubbie': {
        icon: 'vb-stubbie.png',
        rarity: '7.54%',
        count: 670,
      },
      'Vegemite Jar': {
        icon: 'vegemite-jar.png',
        rarity: '6.96%',
        count: 619,
      },
      BBQ: {
        icon: 'bbq.png',
        rarity: '6.17%',
        count: 548,
      },
      'Cricket Ball': {
        icon: 'cricket-ball.png',
        rarity: '5.24%',
        count: 466,
      },
      'KFC Bucket': {
        icon: 'kfc-bucket.png',
        rarity: '5.18%',
        count: 460,
      },
      'Golden Gaytime': {
        icon: 'golden-gaytime.png',
        rarity: '4.62%',
        count: 411,
      },
      'XXXX Can': {
        icon: 'xxxx-can.png',
        rarity: '4.11%',
        count: 365,
      },
      'French Fries': {
        icon: 'french-fries.png',
        rarity: '4.10%',
        count: 364,
      },
      'Milo Tin': {
        icon: 'milo-tin.png',
        rarity: '4.07%',
        count: 362,
      },
      'Fishing Rod': {
        icon: 'fishing-rod.png',
        rarity: '3.99%',
        count: 355,
      },
      'Anzac Biscuit': {
        icon: 'anzac-biscuit.png',
        rarity: '3.97%',
        count: 353,
      },
      'Fosters Can': {
        icon: 'fosters-can.png',
        rarity: '3.96%',
        count: 352,
      },
      'AFL Ball': {
        icon: 'afl-ball.png',
        rarity: '3.17%',
        count: 282,
      },
      'Jarate Jar': {
        icon: 'jarate-jar.png',
        rarity: '2.06%',
        count: 183,
      },
      Netball: {
        icon: 'netball.png',
        rarity: '2.01%',
        count: 179,
      },
      TimTams: {
        icon: 'timtams.png',
        rarity: '1.71%',
        count: 152,
      },
      Diamond: {
        icon: 'diamond.png',
        rarity: '0.52%',
        count: 46,
      },
      'Black Opal': {
        icon: 'black-opal.png',
        rarity: '0.19%',
        count: 17,
      },
    },
  },
  location: {
    label: 'Location',
    attribute: 'traits',
    options: {
      'Bondi Beach': {
        icon: 'bondi-beach.png',
        rarity: '7.45%',
        count: 662,
      },
      Bushfire: {
        icon: 'bushfire.png',
        rarity: '6.86%',
        count: 610,
      },
      'Melbourne Cricket Ground': {
        icon: 'melbourne-cricket-ground.png',
        rarity: '6.06%',
        count: 539,
      },
      'Melbourne Tram': {
        icon: 'melbourne-tram.png',
        rarity: '6.06%',
        count: 539,
      },
      'Southern Cross Stars': {
        icon: 'southern-cross-stars.png',
        rarity: '5.87%',
        count: 522,
      },
      Uluru: {
        icon: 'uluru.png',
        rarity: '5.64%',
        count: 501,
      },
      'Sydney Opera House': {
        icon: 'sydney-opera-house.png',
        rarity: '5.58%',
        count: 496,
      },
      'Rainbow Flag': {
        icon: 'rainbow-flag.png',
        rarity: '5.47%',
        count: 486,
      },
      'Mount Panorama': {
        icon: 'mount-panorama.png',
        rarity: '5.24%',
        count: 466,
      },
      'Australian Flag': {
        icon: 'australian-flag.png',
        rarity: '5.15%',
        count: 458,
      },
      'Aboriginal Flag': {
        icon: 'aboriginal-flag.png',
        rarity: '5.10%',
        count: 453,
      },
      'Fraser Island': {
        icon: 'fraser-island.png',
        rarity: '4.86%',
        count: 432,
      },
      'Rottnest Island': {
        icon: 'rottnest-island.png',
        rarity: '4.82%',
        count: 428,
      },
      'Three Sisters': {
        icon: 'three-sisters.png',
        rarity: '4.79%',
        count: 426,
      },
      'Waccas Drivethrough': {
        icon: 'waccas-drivethrough.png',
        rarity: '4.33%',
        count: 385,
      },
      Pokies: {
        icon: 'pokies.png',
        rarity: '4.21%',
        count: 374,
      },
      Campsite: {
        icon: 'campsite.png',
        rarity: '3.95%',
        count: 351,
      },
      'Ettamogah Pub': {
        icon: 'ettamogah-pub.png',
        rarity: '3.94%',
        count: 350,
      },
      'Outback Pub': {
        icon: 'outback-pub.png',
        rarity: '3.61%',
        count: 321,
      },
      Cardano: {
        icon: 'cardano.png',
        rarity: '0.43%',
        count: 38,
      },
      'Holden Sandman': {
        icon: 'holden-sandman.png',
        rarity: '0.33%',
        count: 29,
      },
      'Mad Max V8': {
        icon: 'mad-max-v8.png',
        rarity: '0.25%',
        count: 22,
      },
    },
  },
  mouth: {
    label: 'Mouth',
    attribute: 'traits',
    options: {
      Ciggarette: {
        icon: 'ciggarette.png',
        rarity: '8.00%',
        count: 711,
      },
      Pipe: {
        icon: 'pipe.png',
        rarity: '6.11%',
        count: 543,
      },
      Cigar: {
        icon: 'cigar.png',
        rarity: '4.13%',
        count: 367,
      },
      'Mad Max Mask': {
        icon: 'mad-max-mask.png',
        rarity: '0.17%',
        count: 15,
      },
    },
  },
  righty: {
    label: 'Righty',
    attribute: 'traits',
    options: {
      'VB Can': {
        icon: 'vb-can.png',
        rarity: '7.74%',
        count: 688,
      },
      Boomerang: {
        icon: 'boomerang.png',
        rarity: '6.78%',
        count: 603,
      },
      'Fire Extinguisher': {
        icon: 'fire-extinguisher.png',
        rarity: '6.55%',
        count: 582,
      },
      'Meat Pie': {
        icon: 'meat-pie.png',
        rarity: '6.30%',
        count: 560,
      },
      Esky: {
        icon: 'esky.png',
        rarity: '6.15%',
        count: 547,
      },
      'Sausage Bread': {
        icon: 'sausage-bread.png',
        rarity: '6.03%',
        count: 536,
      },
      Surfboard: {
        icon: 'surfboard.png',
        rarity: '5.86%',
        count: 521,
      },
      'Vegemite Toast': {
        icon: 'vegemite-toast.png',
        rarity: '4.89%',
        count: 435,
      },
      'Cricket Bat': {
        icon: 'cricket-bat.png',
        rarity: '4.48%',
        count: 398,
      },
      Spatula: {
        icon: 'spatula.png',
        rarity: '3.99%',
        count: 355,
      },
      'NRL Ball': {
        icon: 'nrl-ball.png',
        rarity: '3.93%',
        count: 349,
      },
      'Dundee Knife': {
        icon: 'dundee-knife.png',
        rarity: '3.35%',
        count: 298,
      },
      Didgeridoo: {
        icon: 'didgeridoo.png',
        rarity: '3.03%',
        count: 269,
      },
      'Hunting Rifle': {
        icon: 'hunting-rifle.png',
        rarity: '2.07%',
        count: 184,
      },
      'Goon Box': {
        icon: 'goon-box.png',
        rarity: '1.72%',
        count: 153,
      },
      Billy: {
        icon: 'billy.png',
        rarity: '0.81%',
        count: 72,
      },
      'Magic Bong': {
        icon: 'magic-bong.png',
        rarity: '0.59%',
        count: 52,
      },
      'Jousting Stick': {
        icon: 'jousting-stick.png',
        rarity: '0.54%',
        count: 48,
      },
      Diamond: {
        icon: 'diamond.png',
        rarity: '0.44%',
        count: 39,
      },
      'Black Opal': {
        icon: 'black-opal.png',
        rarity: '0.23%',
        count: 20,
      },
    },
  },
  special: {
    label: 'Special',
    attribute: 'traits',
    options: {
      'Redback Spider': {
        icon: 'redback-spider.png',
        rarity: '7.74%',
        count: 688,
      },
      Magpie: {
        icon: 'magpie.png',
        rarity: '6.53%',
        count: 580,
      },
      'Brown Snake': {
        icon: 'brown-snake.png',
        rarity: '4.17%',
        count: 371,
      },
      'Laser Eyes Red': {
        icon: 'laser-eyes-red.png',
        rarity: '0.26%',
        count: 23,
      },
      'Prison Bars': {
        icon: 'prison-bars.png',
        rarity: '0.21%',
        count: 19,
      },
      'Laser Eyes Blue': {
        icon: 'laser-eyes-blue.png',
        rarity: '0.14%',
        count: 12,
      },
      'Sheep Onesie': {
        icon: 'sheep-onesie.png',
      },
      'Master Chief Suit': {
        icon: 'master-chief-suit.png',
      },
      'Angel Wings': {
        icon: 'angel-wings.png',
      },
      'Gurren Lagann Sunnies': {
        icon: 'gurren-lagann-sunnies.png',
      },
      'Ghost Form': {
        icon: 'ghost-form.png',
      },
      'Frickin Aussie Laser Eyes': {
        icon: 'frickin-aussie-laser-eyes.png',
      },
    },
  },
};
