import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ImageFadeIn = ({
  width, height, src, alt,
}) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <img
      onLoad={() => !loaded && setLoaded(true)}
      src={src}
      alt={alt}
      width={width}
      height={height}
      style={{
        opacity: loaded ? 1 : 0,
      }}
    />
  );
};

ImageFadeIn.defaultProps = {
  alt: '',
};

ImageFadeIn.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default ImageFadeIn;
