import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { navigate } from 'gatsby';
import { TextField, Snackbar, Alert } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useForm, Controller } from 'react-hook-form';
import { find } from 'lodash';
import CustomPropTypes from '../constants/customPropTypes';

import './untSearch.scss';

const UntSearch = ({ seasonData, pageUrl }) => {
  const [open, setOpen] = useState(false);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      id: '',
    },
  });

  const onSubmit = ({ id }) => {
    const untData = id && find(seasonData, ['id', parseInt(id, 10)]);

    if (!untData) {
      setOpen(true);
    } else {
      navigate(`${pageUrl}&unt=${id}`);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <form className="unt-search" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="id"
          control={control}
          render={({ field }) => (
            <TextField
              size="small"
              variant="outlined"
              placeholder="Unt ID #"
              type="number"
              fullWidth
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
            />
          )}
        />
        <button type="submit" onClick={() => {}}>
          <SearchIcon />
        </button>
      </form>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleClose}
          severity="error"

        >
          Not a valid ID
        </Alert>
      </Snackbar>
    </>
  );
};

UntSearch.propTypes = {
  seasonData: CustomPropTypes.seasonData.isRequired,
  pageUrl: PropTypes.string.isRequired,
};

export default UntSearch;
