import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { navigate } from 'gatsby';
import classNames from 'classnames/bind';
import { findIndex } from 'lodash';

import Seo from '../components/seo';
import Header from '../components/header';
import UntsFilter from '../components/untsFilter';
import UntDetail from '../components/untDetail';
import Button from '../components/button';
import UntsList from '../components/untsList';
import UntsSearch from '../components/untSearch';
import SeasonSelector from '../components/seasonSelector';

// Import the data for the seasons
import season1Data from '../api/season1Data';
import season1MenuData from '../api/season1MenuData';
import seasonWickedData from '../api/seasonWickedData';
import seasonWickedMenuData from '../api/seasonWickedMenuData';
import seasonXmasData from '../api/seasonXmasData';
import seasonXmasMenuData from '../api/seasonXmasMenuData';

import './unts.scss';

const seasons = [{
  id: 'season-1-road-trip',
  order: 0,
  label: 'Season 1 : Road Trip',
  data: season1Data,
  menuData: season1MenuData,
  images: 'https://cheeky-unts.s3.ap-southeast-2.amazonaws.com/season-one/',
}, {
  id: 'halloween-wicked-unts',
  order: 1,
  label: 'Halloween : Wicked Unts',
  data: seasonWickedData,
  menuData: seasonWickedMenuData,
  images: 'https://cheeky-unts.s3.ap-southeast-2.amazonaws.com/season-wicked/',
}, {
  id: 'christmas-festive-unts',
  order: 2,
  label: 'Christmas : Festive Unts',
  data: seasonXmasData,
  menuData: seasonXmasMenuData,
  images: 'https://cheeky-unts.s3.ap-southeast-2.amazonaws.com/season-xmas/',
}];

// Start loading items when you're this close to the bottom
const scrollBuffer = 100;

const Unts = ({ location }) => {
  // If the URL has an unt ID, show the modal
  const params = new URLSearchParams(location.search);
  const untParam = params.get('unt');
  const seasonParamIndex = findIndex(seasons, ['id', params.get('season')]);
  const seasonIndex = seasonParamIndex === -1 ? 0 : seasonParamIndex;
  const pageUrl = seasonIndex === -1 ? '/explore' : `/explore?season=${seasons[seasonIndex].id}`;

  const [currentSeason, setCurrentSeason] = useState(seasonIndex);
  const [untsArray, setUntsArray] = useState(seasons[currentSeason].data);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadedCount, setLoadedCount] = useState(0);
  const [loadedAtATime, setLoadedAtATime] = useState(20);
  const [currentTag, setCurrentTag] = useState(null);

  const untsListRef = useRef(null);
  const scrollRef = useRef(null);

  // When the filter changes, scroll back to the top
  const scrollToTop = () => {
    scrollRef.current.scrollTop = 0;
  };

  const openFilter = () => {
    document.body.style.overflow = 'hidden';
    setFiltersOpen(true);
  };

  const closeFilter = () => {
    document.body.style.overflow = 'unset';
    setFiltersOpen(false);
  };

  const onUpdateFilters = (filteredUnts) => {
    setUntsArray(filteredUnts);
    scrollToTop();
    closeFilter();
    setHasMore(filteredUnts.length > loadedAtATime);
  };

  const loadMore = (count) => {
    const newLoadedCount = Math.min(untsArray.length, loadedCount + count);
    setLoadedCount(newLoadedCount);
    setLoading(false);

    if (newLoadedCount !== untsArray.length) {
      setHasMore(true);
    }
  };

  const changeSeason = (seasonId) => {
    navigate(`/explore?season=${seasons[seasonId].id}`);
  };

  useEffect(() => {
    setCurrentSeason(seasonIndex);
    setUntsArray(seasons[seasonIndex].data);
    closeFilter();
    setHasMore(seasons[seasonIndex].data.length > loadedAtATime);
  }, [location]);

  const onScroll = (e) => {
    if (!hasMore || loading) return;

    const distanceFromBottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;

    if (distanceFromBottom < scrollBuffer) {
      setLoading(true);
      loadMore(loadedAtATime);
    }
  };

  useEffect(() => {
    // figure out how many to show on initial load
    // overestimate & add a safety row.
    if (loadedCount === 0) {
      const { width, height } = scrollRef.current.getBoundingClientRect();
      const approxColumns = Math.floor(width / 144);
      const approxRows = Math.ceil(height / 210);
      const toLoad = Math.max(10, approxColumns * approxRows + approxColumns);
      setLoadedAtATime(toLoad);
      loadMore(toLoad);
    }
  }, []);

  return (
    // On mobile, this is the div that scrolls
    <div className="unts" onScroll={onScroll}>
      <Seo title="Cheeky Unts - Find your Unt" />
      <Header />
      <div className="unts__container">
        <div
          className={classNames('unts__sidebar', {
            '-show': filtersOpen,
          })}
        >
          <SeasonSelector
            seasons={seasons}
            currentSeason={currentSeason}
            changeSeason={changeSeason}
          />
          <UntsSearch
            pageUrl={pageUrl}
            seasonData={seasons[currentSeason].data}
          />
          <div className="unts__filters-container">
            <div className="unts__filters">
              <UntsFilter
                seasonData={seasons[currentSeason].data}
                menuData={seasons[currentSeason].menuData}
                onUpdateFilters={onUpdateFilters}
                onCancel={closeFilter}
                currentTag={currentTag}
              />
            </div>
          </div>
        </div>
        <div ref={scrollRef} onScroll={onScroll} className="unts__list-container">
          <div ref={untsListRef} className="unts__list">
            <UntsList
              pageUrl={pageUrl}
              images={seasons[currentSeason].images}
              loadedCount={loadedCount}
              untsArray={untsArray}
            />
            {
              hasMore && <div className="unts__loading">Loading!!!</div>
            }
          </div>
        </div>
      </div>

      <UntDetail
        images={seasons[currentSeason].images}
        untId={untParam}
        onClose={() => navigate(pageUrl)}
        setCurrentTag={setCurrentTag}
        seasonData={seasons[currentSeason].data}
      />
      <div className="unts__open-filters">
        <Button label="Filters" onButtonClick={openFilter} shadow outline />
      </div>
    </div>
  );
};

Unts.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Unts;
