const seasonWickedData = [
  {
    id: 1,
    type: 'Crocodile',
    traits: ['Crocodile', 'Skeleton Suit', 'Broom', 'Whisp', 'Cemetery'],
  },
  {
    id: 2,
    type: 'Platypus',
    traits: ['Platypus', 'Eye Patch', 'Skeleton Suit', 'Cemetery'],
  },
  {
    id: 3,
    type: 'Koala',
    traits: ['Koala', 'Pumpkin Head', 'Skull', 'Demonic Gate'],
  },
  {
    id: 4,
    type: 'Dingo',
    traits: ['Dingo', 'Pumpkin Head', 'Broom', 'Cave'],
  },
  {
    id: 5,
    type: 'Koala',
    traits: ['Koala', 'Cauldron', 'Flaming Sword', 'Creepy Mansion'],
  },
  {
    id: 6,
    type: 'Wombat',
    traits: ['Wombat', 'Skull', 'Bloodi Beach'],
  },
  {
    id: 7,
    type: 'Shark',
    traits: ['Shark', 'Pumpkin', 'Flaming Sword', 'Cemetery'],
  },
  {
    id: 8,
    type: 'Wombat',
    traits: ['Horns', 'Wombat', 'Cave'],
  },
  {
    id: 9,
    type: 'Crocodile',
    traits: ['Witch Hat', 'Crocodile', 'Broom', 'Bat', 'Necrofire'],
  },
  {
    id: 10,
    type: 'Seal',
    traits: ['Seal', 'Lollipop', 'Demonic Gate'],
  },
  {
    id: 11,
    type: 'Penguin',
    traits: ['Penguin', 'Skull', 'Demonic Gate'],
  },
  {
    id: 13,
    type: 'Penguin',
    traits: ['Penguin', 'Lollipop', 'Scythe', 'Bat', 'Bloodi Beach'],
  },
  {
    id: 12,
    type: 'Wombat',
    traits: ['Horns', 'Wombat', 'Pumpkin', 'Cave'],
  },
  {
    id: 14,
    type: 'Crocodile',
    traits: ['Crocodile', 'Torch', 'Scythe', 'Demonic Gate'],
  },
  {
    id: 15,
    type: 'Tasmanian Devil',
    traits: ['Tasmanian Devil', 'Cigar', 'Skull', 'Bat', 'Cave'],
  },
  {
    id: 16,
    type: 'Dingo',
    traits: ['Witch Hat', 'Dingo', 'Skeleton Suit', 'Scythe', 'Necrofire'],
  },
  {
    id: 17,
    type: 'Koala',
    traits: ['Horns', 'Koala', 'Bloodi Beach'],
  },
  {
    id: 18,
    type: 'Dingo',
    traits: ['Dingo', 'Lollipop', 'Necrofire'],
  },
  {
    id: 19,
    type: 'Penguin',
    traits: [
      'Witch Hat',
      'Penguin',
      'Skeleton Suit',
      'Lollipop',
      'Hopium Bong',
      'Snake',
      'Necrofire',
    ],
  },
  {
    id: 21,
    type: 'Penguin',
    traits: ['Penguin', 'Eye Patch', 'Scythe', 'Cave'],
  },
  {
    id: 20,
    type: 'Wombat',
    traits: ['Horns', 'Wombat', 'Lollipop', 'Scythe', 'Snake', 'Cave'],
  },
  {
    id: 22,
    type: 'Octopus',
    traits: ['Octopus', 'Scythe', 'Creepy Mansion'],
  },
  {
    id: 23,
    type: 'Penguin',
    traits: ['Penguin', 'Skeleton Suit', 'Cauldron', 'Creepy Mansion'],
  },
  {
    id: 25,
    type: 'Wombat',
    traits: ['Wombat', 'Pumpkin', 'Necrofire'],
  },
  {
    id: 24,
    type: 'Shark',
    traits: ['Shark', 'Skull Mask', 'Torch', 'Pumpkin', 'Creepy Mansion'],
  },
  {
    id: 26,
    type: 'Penguin',
    traits: ['Penguin', 'Skull', 'Ciggarette', 'Broom', 'Bloodi Beach'],
  },
  {
    id: 27,
    type: 'Dingo',
    traits: ['Horns', 'Dingo', 'Wand', 'Bloodi Beach'],
  },
  {
    id: 28,
    type: 'Dingo',
    traits: ['Dingo', 'Pumpkin Head', 'Skull', 'Whisp', 'Cemetery'],
  },
  {
    id: 29,
    type: 'Tasmanian Devil',
    traits: ['Witch Hat', 'Tasmanian Devil', 'Skull', 'Cemetery'],
  },
  {
    id: 30,
    type: 'Crocodile',
    traits: ['Crocodile', 'Skull', 'Laser Eyes Green', 'Cemetery'],
  },
  {
    id: 31,
    type: 'Quokka',
    traits: ['Quokka', 'Cauldron', 'Skull', 'Demonic Gate'],
  },
  {
    id: 32,
    type: 'Shark',
    traits: [
      'Shark',
      'Skeleton Suit',
      'Torch',
      'Broom',
      'Snake',
      'Demonic Gate',
    ],
  },
  {
    id: 33,
    type: 'Dingo',
    traits: ['Horns', 'Dingo', 'Pumpkin', 'Bloodi Beach'],
  },
  {
    id: 34,
    type: 'Penguin',
    traits: [
      'Penguin',
      'Skeleton Suit',
      'Lollipop',
      'Ciggarette',
      'Demonic Gate',
    ],
  },
  {
    id: 35,
    type: 'Drop Bear',
    traits: [
      'Witch Hat',
      'Drop Bear',
      'Lollipop',
      'Flaming Sword',
      'Cemetery',
    ],
  },
  {
    id: 36,
    type: 'Platypus',
    traits: ['Platypus', 'Pumpkin ', 'Pumpkin', 'Necrofire'],
  },
  {
    id: 37,
    type: 'Octopus',
    traits: ['Octopus', 'Eye Patch', 'Skeleton Suit', 'Pumpkin', 'Cemetery'],
  },
  {
    id: 38,
    type: 'Koala',
    traits: ['Koala', 'Pumpkin Head', 'Cauldron', 'Broom', 'Creepy Mansion'],
  },
  {
    id: 39,
    type: 'Penguin',
    traits: ['Penguin', 'Lollipop', 'Cemetery'],
  },
  {
    id: 40,
    type: 'Shark',
    traits: ['Horns', 'Shark', 'Torch', 'Cemetery'],
  },
  {
    id: 41,
    type: 'Quokka',
    traits: [
      'Witch Hat',
      'Quokka',
      'Skeleton Suit',
      'Flaming Sword',
      'Demonic Gate',
    ],
  },
  {
    id: 43,
    type: 'Koala',
    traits: ['Witch Hat', 'Koala', 'Cemetery'],
  },
  {
    id: 42,
    type: 'Dingo',
    traits: [
      'Dingo',
      'Skull Mask',
      'Lollipop',
      'Skull',
      'Snake',
      'Demonic Gate',
    ],
  },
  {
    id: 44,
    type: 'Crocodile',
    traits: ['Crocodile', 'Pumpkin', 'Bat', 'Necrofire'],
  },
  {
    id: 45,
    type: 'Seal',
    traits: ['Witch Hat', 'Seal', 'Skeleton Suit', 'Cauldron', 'Cemetery'],
  },
  {
    id: 47,
    type: 'Platypus',
    traits: ['Platypus', 'Pumpkin Head', 'Pumpkin', 'Whisp', 'Cemetery'],
  },
  {
    id: 46,
    type: 'Crocodile',
    traits: [
      'Witch Hat',
      'Crocodile',
      'Eye Patch',
      'Lollipop',
      'Broom',
      'Cemetery',
    ],
  },
  {
    id: 48,
    type: 'Crocodile',
    traits: [
      'Witch Hat',
      'Crocodile',
      'Cigar',
      'Flaming Sword',
      'Bloodi Beach',
    ],
  },
  {
    id: 51,
    type: 'Seal',
    traits: [
      'Seal',
      'Skeleton Suit',
      'Cauldron',
      'Flaming Sword',
      'Bat',
      'Cemetery',
    ],
  },
  {
    id: 49,
    type: 'Kangaroo',
    traits: ['Kangaroo', 'Cauldron', 'Scythe', 'Snake', 'Creepy Mansion'],
  },
  {
    id: 50,
    type: 'Penguin',
    traits: ['Witch Hat', 'Penguin', 'Eye Patch', 'Cemetery'],
  },
  {
    id: 52,
    type: 'Dingo',
    traits: ['Dingo', 'Skull', 'Bloodi Beach'],
  },
  {
    id: 53,
    type: 'Wombat',
    traits: [
      'Wombat',
      'Pumpkin Head',
      'Cauldron',
      'Skull',
      'Bat',
      'Demonic Gate',
    ],
  },
  {
    id: 54,
    type: 'Platypus',
    traits: ['Platypus', 'Eye Patch', 'Broom', 'Demonic Gate'],
  },
  {
    id: 55,
    type: 'Dingo',
    traits: ['Horns', 'Dingo', 'Skeleton Suit', 'Pumpkin', 'Bloodi Beach'],
  },
  {
    id: 56,
    type: 'Penguin',
    traits: ['Penguin', 'Torch', 'Ciggarette', 'Cemetery'],
  },
  {
    id: 57,
    type: 'Platypus',
    traits: ['Platypus', 'Skull', 'Bloodi Beach'],
  },
  {
    id: 58,
    type: 'Shark',
    traits: ['Shark', 'Skull', 'Broom', 'Snake', 'Demonic Gate'],
  },
  {
    id: 59,
    type: 'Wombat',
    traits: [
      'Witch Hat',
      'Wombat',
      'Eye Patch',
      'Cauldron',
      'Bat',
      'Demonic Gate',
    ],
  },
  {
    id: 60,
    type: 'Crocodile',
    traits: [
      'Crocodile',
      'Skeleton Suit',
      'Cauldron',
      'Ciggarette',
      'Pumpkin',
      'Bloodi Beach',
    ],
  },
  {
    id: 61,
    type: 'Wombat',
    traits: ['Wombat', 'Cave'],
  },
  {
    id: 62,
    type: 'Bunyip',
    traits: ['Bunyip', 'Cauldron', 'Pumpkin', 'Demonic Gate'],
  },
  {
    id: 65,
    type: 'Penguin',
    traits: ['Penguin', 'Cauldron', 'Pumpkin', 'Cave'],
  },
  {
    id: 63,
    type: 'Wombat',
    traits: [
      'Wombat',
      'Eye Patch',
      'Pumpkin',
      'Flaming Sword',
      'Whisp',
      'Creepy Mansion',
    ],
  },
  {
    id: 64,
    type: 'Wombat',
    traits: ['Wombat', 'Lollipop', 'Scythe', 'Demonic Gate'],
  },
  {
    id: 71,
    type: 'Kangaroo',
    traits: ['Witch Hat', 'Kangaroo', 'Skull', 'Broom', 'Cemetery'],
  },
  {
    id: 70,
    type: 'Wombat',
    traits: ['Wombat', 'Wand', 'Ciggarette', 'Scythe', 'Bloodi Beach'],
  },
  {
    id: 69,
    type: 'Shark',
    traits: [
      'Shark',
      'Skeleton Suit',
      'Pumpkin',
      'Skull',
      'Bat',
      'Creepy Mansion',
    ],
  },
  {
    id: 66,
    type: 'Kangaroo',
    traits: ['Kangaroo', 'Cauldron', 'Bat', 'Demonic Gate'],
  },
  {
    id: 67,
    type: 'Octopus',
    traits: ['Octopus', 'Necrofire'],
  },
  {
    id: 68,
    type: 'Dingo',
    traits: ['Dingo', 'Eye Patch', 'Cauldron', 'Pumpkin', 'Cave'],
  },
  {
    id: 72,
    type: 'Wombat',
    traits: ['Witch Hat', 'Wombat', 'Lollipop', 'Bloodi Beach'],
  },
  {
    id: 73,
    type: 'Platypus',
    traits: [
      'Platypus',
      'Skull Mask',
      'Skeleton Suit',
      'Wand',
      'Pumpkin',
      'Cave',
    ],
  },
  {
    id: 74,
    type: 'Koala',
    traits: ['Koala', 'Lollipop', 'Skull', 'Cemetery'],
  },
  {
    id: 75,
    type: 'Quokka',
    traits: ['Quokka', 'Torch', 'Flaming Sword', 'Cemetery'],
  },
  {
    id: 76,
    type: 'Octopus',
    traits: ['Octopus', 'Scythe', 'Bloodi Beach'],
  },
  {
    id: 77,
    type: 'Platypus',
    traits: [
      'Horns',
      'Platypus',
      'Skeleton Suit',
      'Pumpkin',
      'Broom',
      'Bat',
      'Bloodi Beach',
    ],
  },
  {
    id: 78,
    type: 'Crocodile',
    traits: [
      'Witch Hat',
      'Crocodile',
      'Skull',
      'Flaming Sword',
      'Whisp',
      'Necrofire',
    ],
  },
  {
    id: 79,
    type: 'Wombat',
    traits: ['Wombat', 'Broom', 'Bat', 'Cemetery'],
  },
  {
    id: 86,
    type: 'Crocodile',
    traits: ['Crocodile', 'Scythe', 'Cemetery'],
  },
  {
    id: 81,
    type: 'Octopus',
    traits: [
      'Witch Hat',
      'Octopus',
      'Eye Patch',
      'Torch',
      'Scythe',
      'Demonic Gate',
    ],
  },
  {
    id: 80,
    type: 'Dingo',
    traits: ['Dingo', 'Skull Mask', 'Bloodi Beach'],
  },
  {
    id: 82,
    type: 'Dingo',
    traits: ['Dingo', 'Torch', 'Ciggarette', 'Scythe', 'Cave'],
  },
  {
    id: 83,
    type: 'Octopus',
    traits: ['Octopus', 'Skull Mask', 'Wand', 'Scythe', 'Demonic Gate'],
  },
  {
    id: 84,
    type: 'Kangaroo',
    traits: ['Kangaroo', 'Eye Patch', 'Necrofire'],
  },
  {
    id: 85,
    type: 'Penguin',
    traits: [
      'Witch Hat',
      'Penguin',
      'Skeleton Suit',
      'Pumpkin',
      'Scythe',
      'Creepy Mansion',
    ],
  },
  {
    id: 87,
    type: 'Seal',
    traits: ['Seal', 'Cauldron', 'Cigar', 'Broom', 'Cemetery'],
  },
  {
    id: 88,
    type: 'Tasmanian Devil',
    traits: ['Tasmanian Devil', 'Skull', 'Flaming Sword', 'Bloodi Beach'],
  },
  {
    id: 89,
    type: 'Wombat',
    traits: ['Wombat', 'Pumpkin', 'Cave'],
  },
  {
    id: 90,
    type: 'Shark',
    traits: ['Shark', 'Skeleton Suit', 'Lollipop', 'Skull', 'Bloodi Beach'],
  },
  {
    id: 91,
    type: 'Quokka',
    traits: ['Quokka', 'Bloodi Beach'],
  },
  {
    id: 92,
    type: 'Wombat',
    traits: ['Horns', 'Wombat', 'Lollipop', 'Ciggarette', 'Scythe', 'Cave'],
  },
  {
    id: 93,
    type: 'Koala',
    traits: ['Witch Hat', 'Koala', 'Skull', 'Bloodi Beach'],
  },
  {
    id: 94,
    type: 'Dingo',
    traits: ['Dingo', 'Skeleton Suit', 'Cauldron', 'Cemetery'],
  },
  {
    id: 95,
    type: 'Wombat',
    traits: [
      'Witch Hat',
      'Wombat',
      'Cauldron',
      'Pumpkin',
      'Bat',
      'Demonic Gate',
    ],
  },
  {
    id: 96,
    type: 'Quokka',
    traits: ['Quokka', 'Skeleton Suit', 'Flaming Sword', 'Demonic Gate'],
  },
  {
    id: 97,
    type: 'Shark',
    traits: ['Horns', 'Shark', 'Demonic Gate'],
  },
  {
    id: 98,
    type: 'Penguin',
    traits: ['Penguin', 'Pumpkin Head', 'Broom', 'Whisp', 'Cemetery'],
  },
  {
    id: 99,
    type: 'Wombat',
    traits: [
      'Witch Hat',
      'Wombat',
      'Eye Patch',
      'Skeleton Suit',
      'Pumpkin',
      'Whisp',
      'Cemetery',
    ],
  },
  {
    id: 107,
    type: 'Platypus',
    traits: ['Platypus', 'Pumpkin Head', 'Torch', 'Creepy Mansion'],
  },
  {
    id: 102,
    type: 'Kangaroo',
    traits: ['Kangaroo', 'Cauldron', 'Cigar', 'Skull', 'Cave'],
  },
  {
    id: 100,
    type: 'Quokka',
    traits: ['Quokka', 'Skeleton Suit', 'Pumpkin', 'Cave'],
  },
  {
    id: 101,
    type: 'Koala',
    traits: ['Koala', 'Pumpkin Head', 'Scythe', 'Cave'],
  },
  {
    id: 103,
    type: 'Dingo',
    traits: ['Dingo', 'Eye Patch', 'Wand', 'Pumpkin', 'Snake', 'Bloodi Beach'],
  },
  {
    id: 104,
    type: 'Wombat',
    traits: ['Witch Hat', 'Wombat', 'Pumpkin', 'Bat', 'Cave'],
  },
  {
    id: 105,
    type: 'Seal',
    traits: ['Seal', 'Eye Patch', 'Cauldron', 'Cigar', 'Cave'],
  },
  {
    id: 106,
    type: 'Crocodile',
    traits: ['Crocodile', 'Pumpkin', 'Demonic Gate'],
  },
  {
    id: 108,
    type: 'Quokka',
    traits: ['Witch Hat', 'Quokka', 'Creepy Mansion'],
  },
  {
    id: 109,
    type: 'Quokka',
    traits: ['Witch Hat', 'Quokka', 'Skeleton Suit', 'Whisp', 'Cemetery'],
  },
  {
    id: 110,
    type: 'Dingo',
    traits: ['Dingo', 'Skeleton Suit', 'Cauldron', 'Creepy Mansion'],
  },
  {
    id: 111,
    type: 'Kangaroo',
    traits: ['Kangaroo', 'Wand', 'Broom', 'Whisp', 'Demonic Gate'],
  },
  {
    id: 112,
    type: 'Koala',
    traits: ['Koala', 'Flaming Sword', 'Demonic Gate'],
  },
  {
    id: 113,
    type: 'Quokka',
    traits: ['Quokka', 'Torch', 'Bat', 'Cemetery'],
  },
  {
    id: 114,
    type: 'Platypus',
    traits: ['Horns', 'Platypus', 'Lollipop', 'Flaming Sword', 'Cemetery'],
  },
  {
    id: 115,
    type: 'Dingo',
    traits: ['Dingo', 'Bloodi Beach'],
  },
  {
    id: 117,
    type: 'Quokka',
    traits: ['Quokka', 'Pumpkin', 'Creepy Mansion'],
  },
  {
    id: 116,
    type: 'Platypus',
    traits: ['Platypus', 'Skeleton Suit', 'Skull', 'Bat', 'Cave'],
  },
  {
    id: 118,
    type: 'Dingo',
    traits: ['Dingo', 'Eye Patch', 'Cemetery'],
  },
  {
    id: 119,
    type: 'Octopus',
    traits: ['Octopus', 'Flaming Sword', 'Bloodi Beach'],
  },
  {
    id: 121,
    type: 'Penguin',
    traits: ['Horns', 'Penguin', 'Skull', 'Cave'],
  },
  {
    id: 122,
    type: 'Penguin',
    traits: ['Penguin', 'Lollipop', 'Skull', 'Whisp', 'Bloodi Beach'],
  },
  {
    id: 120,
    type: 'Koala',
    traits: ['Koala', 'Skull Mask', 'Torch', 'Necrofire'],
  },
  {
    id: 124,
    type: 'Wombat',
    traits: ['Wombat', 'Cauldron', 'Cemetery'],
  },
  {
    id: 128,
    type: 'Platypus',
    traits: ['Platypus', 'Eye Patch', 'Whisp', 'Bloodi Beach'],
  },
  {
    id: 123,
    type: 'Dingo',
    traits: ['Horns', 'Dingo', 'Cauldron', 'Cigar', 'Scythe', 'Necrofire'],
  },
  {
    id: 125,
    type: 'Crocodile',
    traits: [
      'Crocodile',
      'Eye Patch',
      'Torch',
      'Flaming Sword',
      'Demonic Gate',
    ],
  },
  {
    id: 126,
    type: 'Dingo',
    traits: ['Witch Hat', 'Dingo', 'Scythe', 'Demonic Gate'],
  },
  {
    id: 127,
    type: 'Crocodile',
    traits: ['Crocodile', 'Cauldron', 'Skull', 'Whisp', 'Demonic Gate'],
  },
  {
    id: 129,
    type: 'Kangaroo',
    traits: ['Horns', 'Kangaroo', 'Scythe', 'Cemetery'],
  },
  {
    id: 130,
    type: 'Platypus',
    traits: ['Platypus', 'Torch', 'Broom', 'Cave'],
  },
  {
    id: 131,
    type: 'Koala',
    traits: ['Koala', 'Snake', 'Bloodi Beach'],
  },
  {
    id: 132,
    type: 'Kangaroo',
    traits: ['Kangaroo', 'Scythe', 'Cemetery'],
  },
  {
    id: 133,
    type: 'Koala',
    traits: ['Koala', 'Wand', 'Hopium Bong', 'Bloodi Beach'],
  },
  {
    id: 134,
    type: 'Seal',
    traits: ['Seal', 'Flaming Sword', 'Demonic Gate'],
  },
  {
    id: 135,
    type: 'Platypus',
    traits: ['Platypus', 'Skull Mask', 'Snake', 'Bloodi Beach'],
  },
  {
    id: 136,
    type: 'Koala',
    traits: ['Koala', 'Wand', 'Pumpkin', 'Cave'],
  },
  {
    id: 137,
    type: 'Platypus',
    traits: ['Platypus', 'Eye Patch', 'Pumpkin', 'Broom', 'Creepy Mansion'],
  },
  {
    id: 138,
    type: 'Quokka',
    traits: ['Quokka', 'Broom', 'Creepy Mansion'],
  },
  {
    id: 139,
    type: 'Dingo',
    traits: ['Dingo', 'Pumpkin Head', 'Necrofire'],
  },
  {
    id: 140,
    type: 'Octopus',
    traits: ['Octopus', 'Skeleton Suit', 'Pumpkin', 'Creepy Mansion'],
  },
  {
    id: 143,
    type: 'Drop Bear',
    traits: ['Drop Bear', 'Flaming Sword', 'Cemetery'],
  },
  {
    id: 144,
    type: 'Wombat',
    traits: ['Wombat', 'Skeleton Suit', 'Bat', 'Bloodi Beach'],
  },
  {
    id: 145,
    type: 'Kangaroo',
    traits: ['Kangaroo', 'Eye Patch', 'Wand', 'Bloodi Beach'],
  },
  {
    id: 146,
    type: 'Shark',
    traits: [
      'Witch Hat',
      'Shark',
      'Cauldron',
      'Laser Eyes Green',
      'Creepy Mansion',
    ],
  },
  {
    id: 147,
    type: 'Octopus',
    traits: [
      'Octopus',
      'Skeleton Suit',
      'Skull',
      'Ciggarette',
      'Pumpkin',
      'Demonic Gate',
    ],
  },
  {
    id: 141,
    type: 'Dingo',
    traits: ['Horns', 'Dingo', 'Cave'],
  },
  {
    id: 142,
    type: 'Quokka',
    traits: ['Horns', 'Quokka', 'Flaming Sword', 'Cemetery'],
  },
  {
    id: 149,
    type: 'Octopus',
    traits: ['Octopus', 'Demonic Gate'],
  },
  {
    id: 148,
    type: 'Penguin',
    traits: ['Penguin', 'Cauldron', 'Flaming Sword', 'Bloodi Beach'],
  },
  {
    id: 150,
    type: 'Wombat',
    traits: ['Wombat', 'Cauldron', 'Skull', 'Bat', 'Bloodi Beach'],
  },
  {
    id: 151,
    type: 'Seal',
    traits: ['Seal', 'Pumpkin Head', 'Skull ', 'Skull', 'Demonic Gate'],
  },
  {
    id: 152,
    type: 'Shark',
    traits: ['Shark', 'Eye Patch', 'Torch', 'Ciggarette', 'Skull', 'Cemetery'],
  },
  {
    id: 153,
    type: 'Wombat',
    traits: ['Wombat', 'Broom', 'Demonic Gate'],
  },
  {
    id: 154,
    type: 'Koala',
    traits: ['Koala', 'Skull', 'Broom', 'Cave'],
  },
  {
    id: 155,
    type: 'Drop Bear',
    traits: ['Drop Bear', 'Skull', 'Broom', 'Bat', 'Demonic Gate'],
  },
  {
    id: 156,
    type: 'Koala',
    traits: ['Koala', 'Eye Patch', 'Skull', 'Broom', 'Necrofire'],
  },
  {
    id: 157,
    type: 'Crocodile',
    traits: ['Witch Hat', 'Crocodile', 'Cauldron', 'Snake', 'Demonic Gate'],
  },
  {
    id: 158,
    type: 'Dingo',
    traits: ['Horns', 'Dingo', 'Pumpkin', 'Broom', 'Cemetery'],
  },
  {
    id: 159,
    type: 'Dingo',
    traits: ['Dingo', 'Pumpkin', 'Cave'],
  },
  {
    id: 160,
    type: 'Dingo',
    traits: ['Horns', 'Dingo', 'Skull', 'Scythe', 'Bat', 'Bloodi Beach'],
  },
  {
    id: 161,
    type: 'Platypus',
    traits: ['Platypus', 'Lollipop', 'Skull', 'Necrofire'],
  },
  {
    id: 162,
    type: 'Wombat',
    traits: ['Wombat', 'Skull', 'Cave'],
  },
  {
    id: 163,
    type: 'Bunyip',
    traits: ['Witch Hat', 'Bunyip', 'Cemetery'],
  },
  {
    id: 164,
    type: 'Shark',
    traits: ['Shark', 'Pumpkin', 'Necrofire'],
  },
  {
    id: 165,
    type: 'Kangaroo',
    traits: ['Horns', 'Kangaroo', 'Wand', 'Demonic Gate'],
  },
  {
    id: 166,
    type: 'Koala',
    traits: [
      'Koala',
      'Eye Patch',
      'Torch',
      'Ciggarette',
      'Hopium Bong',
      'Bat',
      'Cemetery',
    ],
  },
  {
    id: 167,
    type: 'Koala',
    traits: [
      'Witch Hat',
      'Koala',
      'Skeleton Suit',
      'Lollipop',
      'Broom',
      'Bloodi Beach',
    ],
  },
  {
    id: 168,
    type: 'Crocodile',
    traits: [
      'Crocodile',
      'Pumpkin Head',
      'Wand',
      'Flaming Sword',
      'Bloodi Beach',
    ],
  },
  {
    id: 169,
    type: 'Crocodile',
    traits: ['Crocodile', 'Broom', 'Bat', 'Creepy Mansion'],
  },
  {
    id: 170,
    type: 'Shark',
    traits: [
      'Witch Hat',
      'Shark',
      'Skeleton Suit',
      'Cauldron',
      'Pumpkin',
      'Cave',
    ],
  },
  {
    id: 171,
    type: 'Shark',
    traits: ['Shark', 'Torch', 'Flaming Sword', 'Cemetery'],
  },
  {
    id: 172,
    type: 'Dingo',
    traits: [
      'Witch Hat',
      'Dingo',
      'Eye Patch',
      'Wand',
      'Scythe',
      'Demonic Gate',
    ],
  },
  {
    id: 178,
    type: 'Penguin',
    traits: ['Witch Hat', 'Penguin', 'Lollipop', 'Flaming Sword', 'Cemetery'],
  },
  {
    id: 181,
    type: 'Kangaroo',
    traits: ['Kangaroo', 'Eye Patch', 'Scythe', 'Necrofire'],
  },
  {
    id: 183,
    type: 'Seal',
    traits: ['Horns', 'Seal', 'Broom', 'Whisp', 'Cave'],
  },
  {
    id: 175,
    type: 'Platypus',
    traits: [
      'Horns',
      'Platypus',
      'Skeleton Suit',
      'Pumpkin',
      'Scythe',
      'Bat',
      'Bloodi Beach',
    ],
  },
  {
    id: 173,
    type: 'Crocodile',
    traits: ['Witch Hat', 'Crocodile', 'Cigar', 'Necrofire'],
  },
  {
    id: 174,
    type: 'Quokka',
    traits: ['Horns', 'Quokka', 'Skeleton Suit', 'Torch', 'Demonic Gate'],
  },
  {
    id: 179,
    type: 'Tasmanian Devil',
    traits: ['Tasmanian Devil', 'Skull', 'Ciggarette', 'Bloodi Beach'],
  },
  {
    id: 180,
    type: 'Kangaroo',
    traits: ['Kangaroo', 'Pumpkin', 'Laser Eyes Green', 'Cemetery'],
  },
  {
    id: 177,
    type: 'Penguin',
    traits: ['Penguin', 'Skull', 'Hopium Bong', 'Bat', 'Cemetery'],
  },
  {
    id: 182,
    type: 'Koala',
    traits: ['Koala', 'Eye Patch', 'Wand', 'Pumpkin', 'Bat', 'Bloodi Beach'],
  },
  {
    id: 176,
    type: 'Crocodile',
    traits: ['Crocodile', 'Wand', 'Hopium Bong', 'Cemetery'],
  },
  {
    id: 184,
    type: 'Wombat',
    traits: ['Wombat', 'Bloodi Beach'],
  },
  {
    id: 185,
    type: 'Crocodile',
    traits: ['Crocodile', 'Torch', 'Ciggarette', 'Skull', 'Cemetery'],
  },
  {
    id: 186,
    type: 'Kangaroo',
    traits: ['Kangaroo', 'Skull Mask', 'Pumpkin ', 'Pumpkin', 'Cemetery'],
  },
  {
    id: 187,
    type: 'Shark',
    traits: ['Shark', 'Skeleton Suit', 'Lollipop', 'Demonic Gate'],
  },
  {
    id: 188,
    type: 'Koala',
    traits: ['Koala', 'Bat', 'Cemetery'],
  },
  {
    id: 189,
    type: 'Koala',
    traits: ['Koala', 'Wand', 'Cave'],
  },
  {
    id: 190,
    type: 'Octopus',
    traits: [
      'Octopus',
      'Eye Patch',
      'Cauldron',
      'Ciggarette',
      'Broom',
      'Necrofire',
    ],
  },
  {
    id: 191,
    type: 'Octopus',
    traits: ['Octopus', 'Eye Patch', 'Lollipop', 'Necrofire'],
  },
  {
    id: 192,
    type: 'Drop Bear',
    traits: [
      'Horns',
      'Drop Bear',
      'Skeleton Suit',
      'Scythe',
      'Creepy Mansion',
    ],
  },
  {
    id: 193,
    type: 'Dingo',
    traits: ['Dingo', 'Ciggarette', 'Bloodi Beach'],
  },
  {
    id: 194,
    type: 'Koala',
    traits: ['Koala', 'Skeleton Suit', 'Broom', 'Necrofire'],
  },
  {
    id: 195,
    type: 'Dingo',
    traits: ['Dingo', 'Skull', 'Scythe', 'Cemetery'],
  },
  {
    id: 198,
    type: 'Dingo',
    traits: ['Dingo', 'Whisp', 'Cave'],
  },
  {
    id: 196,
    type: 'Quokka',
    traits: ['Quokka', 'Cigar', 'Demonic Gate'],
  },
  {
    id: 197,
    type: 'Seal',
    traits: ['Seal', 'Torch', 'Broom', 'Necrofire'],
  },
  {
    id: 199,
    type: 'Wombat',
    traits: ['Witch Hat', 'Wombat', 'Broom', 'Laser Eyes Green', 'Necrofire'],
  },
  {
    id: 202,
    type: 'Penguin',
    traits: ['Penguin', 'Creepy Mansion'],
  },
  {
    id: 200,
    type: 'Kangaroo',
    traits: ['Kangaroo', 'Lollipop', 'Cave'],
  },
  {
    id: 201,
    type: 'Seal',
    traits: ['Seal', 'Pumpkin Head', 'Skull', 'Demonic Gate'],
  },
  {
    id: 204,
    type: 'Dingo',
    traits: ['Horns', 'Dingo', 'Lollipop', 'Bat', 'Bloodi Beach'],
  },
  {
    id: 213,
    type: 'Shark',
    traits: ['Shark', 'Flaming Sword', 'Cemetery'],
  },
  {
    id: 216,
    type: 'Dingo',
    traits: ['Dingo', 'Torch', 'Scythe', 'Snake', 'Cemetery'],
  },
  {
    id: 208,
    type: 'Shark',
    traits: ['Shark', 'Cauldron', 'Cave'],
  },
  {
    id: 203,
    type: 'Seal',
    traits: ['Seal', 'Skull', 'Necrofire'],
  },
  {
    id: 215,
    type: 'Dingo',
    traits: ['Dingo', 'Cave'],
  },
  {
    id: 222,
    type: 'Dingo',
    traits: [
      'Horns',
      'Dingo',
      'Skeleton Suit',
      'Cauldron',
      'Flaming Sword',
      'Cemetery',
    ],
  },
  {
    id: 211,
    type: 'Koala',
    traits: ['Koala', 'Skeleton Suit', 'Bat', 'Necrofire'],
  },
  {
    id: 221,
    type: 'Quokka',
    traits: ['Witch Hat', 'Quokka', 'Cauldron', 'Skull', 'Creepy Mansion'],
  },
  {
    id: 205,
    type: 'Dingo',
    traits: ['Dingo', 'Scythe', 'Demonic Gate'],
  },
  {
    id: 206,
    type: 'Drop Bear',
    traits: [
      'Drop Bear',
      'Pumpkin Head',
      'Pumpkin',
      'Broom',
      'Whisp',
      'Demonic Gate',
    ],
  },
  {
    id: 212,
    type: 'Platypus',
    traits: ['Platypus', 'Skeleton Suit', 'Pumpkin', 'Cemetery'],
  },
  {
    id: 218,
    type: 'Penguin',
    traits: ['Penguin', 'Skeleton Suit', 'Bat', 'Creepy Mansion'],
  },
  {
    id: 209,
    type: 'Seal',
    traits: ['Seal', 'Skeleton Suit', 'Skull', 'Bloodi Beach'],
  },
  {
    id: 207,
    type: 'Penguin',
    traits: ['Witch Hat', 'Penguin', 'Cauldron', 'Broom', 'Bloodi Beach'],
  },
  {
    id: 220,
    type: 'Crocodile',
    traits: ['Crocodile', 'Skeleton Suit', 'Skull', 'Pumpkin', 'Necrofire'],
  },
  {
    id: 210,
    type: 'Quokka',
    traits: [
      'Witch Hat',
      'Quokka',
      'Skeleton Suit',
      'Skull',
      'Cigar',
      'Broom',
      'Bloodi Beach',
    ],
  },
  {
    id: 214,
    type: 'Seal',
    traits: ['Seal', 'Eye Patch', 'Cauldron', 'Broom', 'Cemetery'],
  },
  {
    id: 217,
    type: 'Wombat',
    traits: ['Wombat', 'Eye Patch', 'Wand', 'Cemetery'],
  },
  {
    id: 219,
    type: 'Koala',
    traits: [
      'Koala',
      'Lollipop',
      'Ciggarette',
      'Flaming Sword',
      'Demonic Gate',
    ],
  },
];

export default seasonWickedData;
