import PropTypes from 'prop-types';

const seasonData = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    type: PropTypes.string.isRequired,
    traits: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ),
  }),
);

const CustomPropTypes = Object.freeze({
  seasonData,
});

export default CustomPropTypes;
