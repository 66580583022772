import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { navigate } from 'gatsby';

import ImageFadeIn from './imageFadeIn';

import './untsList.scss';

const UntsList = ({
  untsArray, loadedCount, images, pageUrl,
}) => (
  <div
    className="unts-list"
  >
    {untsArray.slice(0, loadedCount).map((unt) => (
      <button
        type="button"
        key={unt.id}
        className="unts-list__item"
        onClick={() => {
          navigate(`${pageUrl}&unt=${unt.id}`);
        }}
      >
        <div className="unts-list__item-image-container">
          <ImageFadeIn
            src={`${images}${unt.id}.png`}
            alt={`Cheeky Unt #${unt.id}`}
            width={128}
            height={128}
          />
        </div>
        <div className="unts-list__item-name">
          {`Cheeky Unt #${unt.id}`}
        </div>
      </button>
    ))}
  </div>
);

UntsList.propTypes = {
  untsArray: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  })).isRequired,
  loadedCount: PropTypes.number.isRequired,
  images: PropTypes.string.isRequired,
  pageUrl: PropTypes.string.isRequired,
};

// Prevent unnecessary rerenders
export default memo(UntsList);
