import React from 'react';
import PropTypes from 'prop-types';

import { Select, MenuItem } from '@mui/material';
import CustomPropTypes from '../constants/customPropTypes';

import './seasonSelector.scss';

const SeasonSelector = ({ seasons, currentSeason, changeSeason }) => (
  <div className="season-selector">
    <Select
      value={currentSeason}
      fullWidth
      onChange={(e) => changeSeason(e.target.value)}
      size="small"
    >
      {
        seasons.map((season, index) => (
          <MenuItem key={season.label} value={index}>{season.label}</MenuItem>
        ))
      }
    </Select>
  </div>
);

SeasonSelector.propTypes = {
  seasons: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    data: CustomPropTypes.seasonData.isRequired,
  })).isRequired,
  currentSeason: PropTypes.number.isRequired,
  changeSeason: PropTypes.func.isRequired,
};

export default SeasonSelector;
