import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '@mui/material';
import { find } from 'lodash';

import ImageFadeIn from './imageFadeIn';
import CustomPropTypes from '../constants/customPropTypes';

import './untDetail.scss';

const UntDetail = ({
  untId, onClose, setCurrentTag, seasonData, images,
}) => {
  if (!untId) return null;

  const untData = untId && find(seasonData, ['id', parseInt(untId, 10)]);
  const untName = `Cheeky Unt #${untData.id}`;

  return (
    <Modal
      open={!!untId}
      onClose={onClose}
      aria-labelledby={untName}
      aria-describedby={`Details for ${untName}`}
    >
      <div className="unt-detail">
        <div className="unt-detail__content">
          <button className="unt-detail__close" type="button" onClick={onClose}>x</button>
          <div className="unt-detail__image">
            <ImageFadeIn src={`${images}${untId}.gif`} alt={untId} width={256} height={256} />
          </div>
          <div className="unt-detail__details">
            <h1>{untName}</h1>

            <button
              className="unt-detail__type"
              type="button"
              onClick={() => {
                setCurrentTag(untData.type);
                onClose();
              }}
            >
              {untData.type}
            </button>
            <div className="unt-detail__traits">
              {
              untData.traits.map((trait) => (
                <button
                  type="button"
                  key={trait}
                  onClick={() => {
                    setCurrentTag(trait);
                    onClose();
                  }}
                >
                  {trait}

                </button>
              ))
            }
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

UntDetail.defaultProps = {
  untId: null,
};

UntDetail.propTypes = {
  untId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  setCurrentTag: PropTypes.func.isRequired,
  seasonData: CustomPropTypes.seasonData.isRequired,
  images: PropTypes.string.isRequired,
};

export default UntDetail;
