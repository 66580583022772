export default {
  type: {
    label: 'Unts',
    attribute: 'type',
    options: {
      Bunyip: {
        icon: 'bunyip.png',
      },
      Crocodile: {
        icon: 'crocodile.png',
      },
      Dingo: {
        icon: 'dingo.png',
      },
      'Drop Bear': {
        icon: 'drop-bear.png',
      },
      Grinch: {
        icon: 'grinch.png',
      },
      Kangaroo: {
        icon: 'kangaroo.png',
      },
      Koala: {
        icon: 'koala.png',
      },
      Octopus: {
        icon: 'octopus.png',
      },
      Penguin: {
        icon: 'penguin.png',
      },
      Platypus: {
        icon: 'platypus.png',
      },
      Quokka: {
        icon: 'quokka.png',
      },
      Seal: {
        icon: 'seal.png',
      },
      Shark: {
        icon: 'shark.png',
      },
      'Tasmanian Devil': {
        icon: 'tasmanian-devil.png',
      },
      'Tasmanian Tiger': {
        icon: 'tasmanian-tiger.png',
      },
      Wombat: {
        icon: 'wombat.png',
      },
    },
  },
  face: {
    label: 'Face',
    attribute: 'traits',
    options: {
      'Rosy Cheeks': {
        icon: 'rosy-cheeks.png',
      },
    },
  },
  eyes: {
    label: 'Eyes',
    attribute: 'traits',
    options: {
      'Ski Goggles': {
        icon: 'ski-goggles.png',
      },
      Sunnies: {
        icon: 'sunnies.png',
      },
    },
  },
  accessory: {
    label: 'Accessory',
    attribute: 'traits',
    options: {
      'Elf Outfit': {
        icon: 'elf-outfit.png',
      },
      'Santa Outfit': {
        icon: 'santa-outfit.png',
      },
      'Scarf Blue': {
        icon: 'scarf-blue.png',
      },
      'Scarf Red': {
        icon: 'scarf-red.png',
      },
      'Winter Jacket Blue': {
        icon: 'winter-jacket-blue.png',
      },
      'Winter Jacket Purple': {
        icon: 'winter-jacket-purple.png',
      },
      'Winter Jacket Red': {
        icon: 'winter-jacket-red.png',
      },
    },
  },
  hat: {
    label: 'Hats',
    attribute: 'traits',
    options: {
      Antlers: {
        icon: 'antlers.png',
      },
      'Beanie Winter Blue': {
        icon: 'beanie-winter-blue.png',
      },
      'Beanie Winter Red': {
        icon: 'beanie-winter-red.png',
      },
      'Beanie Winter White': {
        icon: 'beanie-winter-white.png',
      },
      'Bradbury Helmet': {
        icon: 'bradbury-helmet.png',
      },
      'Ear Warmers Pink': {
        icon: 'ear-warmers-pink.png',
      },
      'Ear Warmers Red': {
        icon: 'ear-warmers-red.png',
      },
      'Ear Warmers White': {
        icon: 'ear-warmers-white.png',
      },
      'Elf Hat': {
        icon: 'elf-hat.png',
      },
      'Santas Hat': {
        icon: 'santas-hat.png',
      },
    },
  },
  lefty: {
    label: 'Lefty',
    attribute: 'traits',
    options: {
      Candle: {
        icon: 'candle.png',
      },
      'Candy Cane': {
        icon: 'candy-cane.png',
      },
      'Chocolate Cookie': {
        icon: 'chocolate-cookie.png',
      },
      'Christmas Bell': {
        icon: 'christmas-bell.png',
      },
      'Cup Hot Chocolate': {
        icon: 'cup-hot-chocolate.png',
      },
      Present: {
        icon: 'present.png',
      },
      'Ski Pole': {
        icon: 'ski-pole.png',
      },
      Snowball: {
        icon: 'snowball.png',
      },
    },
  },
  location: {
    label: 'Location',
    attribute: 'traits',
    options: {
      1: {
        icon: 'christmas-pano-1.png',
      },
      2: {
        icon: 'christmas-pano-2.png',
      },
      3: {
        icon: 'christmas-pano-3.png',
      },
      4: {
        icon: 'christmas-pano-4.png',
      },
      5: {
        icon: 'christmas-pano-5.png',
      },
      6: {
        icon: 'christmas-pano-6.png',
      },
      7: {
        icon: 'christmas-pano-7.png',
      },
      8: {
        icon: 'christmas-pano-8.png',
      },
      9: {
        icon: 'christmas-pano-9.png',
      },
      10: {
        icon: 'christmas-pano-10.png',
      },
      11: {
        icon: 'christmas-pano-11.png',
      },
      12: {
        icon: 'christmas-pano-12.png',
      },
    },
  },
  mouth: {
    label: 'Mouth',
    attribute: 'traits',
    options: {
      'Santas Beard': {
        icon: 'santas-beard.png',
      },
    },
  },
  righty: {
    label: 'Righty',
    attribute: 'traits',
    options: {
      'Glass Egg Nog': {
        icon: 'glass-egg-nog.png',
      },
      'Glass Milk': {
        icon: 'glass-milk.png',
      },
      'Gloves Blue': {
        icon: 'gloves-blue.png',
      },
      'Gloves Red': {
        icon: 'gloves-red.png',
      },
      Skis: {
        icon: 'skis.png',
      },
      Sled: {
        icon: 'sled.png',
      },
      'Snow Shovel': {
        icon: 'snow-shovel.png',
      },
    },
  },
  special: {
    label: 'Special',
    attribute: 'traits',
    options: {
      Pipe: {
        icon: 'pipe.png',
      },
      'Rudolf Nose': {
        icon: 'red-nose.png',
      },
      'Xmas Laser Eyes': {
        icon: 'lazer-eyes-christmas.png',
      },
    },
  },
};
